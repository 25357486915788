import CalendarBox from '../components/CalendarBox'
import Planner from '../components/planner/Planner'
import UserInbox from '../components/user-inbox/UserInbox'
import SalesTracker from '../components/SalesTracker'
import HrForms from '../components/hr-forms/HrForms'
import FileBrowser from '../components/business-partners/BusinessPartners'
import MyTodo from '../components/MyTodo'

export const orderTabs = [
    {
        id: 1,
        title: 'Inbox',
        showOnDefault: true,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <UserInbox />
            </div>
        )
    },
    {
        id: 2,
        title: 'Calendar',
        showOnDefault: true,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <CalendarBox />
            </div>
        )
    },
    {
        id: 3,
        title: 'Planner',
        showOnDefault: true,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <Planner />
            </div>
        )
    },
    {
        id: 4,
        title: 'SalesTracker',
        showOnDefault: false,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded p-2 col-span-12"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <SalesTracker />
            </div>
        )
    },
    {
        id: 5,
        title: 'Forms',
        showOnDefault: false,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <HrForms />
            </div>
        )
    },
    {
        id: 6,
        title: 'MyTodo',
        showOnDefault: false,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <MyTodo />
            </div>
        )
    },
    {
        id: 7,
        title: 'FileBrowser',
        showOnDefault: false,
        children: (setNodeRef, attributes, listeners, style) => (
            <div
                className="bg-white rounded py-2 px-3 col-span-12 md:col-span-6"
                ref={setNodeRef}
                style={style}
                {...listeners}
                {...attributes}
            >
                <FileBrowser />
            </div>
        )
    }
]
