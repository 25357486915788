import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { SubLogoPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()
const initialState = {
    base64Img: ''
}

export const callUpdateSubLogoProperties = createAsyncThunk(
    'subLogo/callUpdateLogoProperties',
    /* eslint no-unused-vars: "off" */
    async ({ subLogo, sharePointSite, rootSiteId }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            SubLogoPropertiesKey,
            subLogo,
            sharePointSite,
            rootSiteId
        )
        return response
    }
)

export const subLogoSlice = createSlice({
    name: 'subLogo',
    initialState,
    reducers: {
        updatebase64Img: (state, action) => {
            if (action.payload.base64Img) return
            state.base64Img = action.payload.base64Img
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateSubLogoProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log('============= finished updating logo Properties ', state, action)
        })
    }
})

export const { updatebase64Img } = subLogoSlice.actions

export const getBase64Img = (state) => state.subLogo.base64Img

export default subLogoSlice.reducer
