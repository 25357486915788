import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { CalendarPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()

export const callUpdateCalendarStatusProperties = createAsyncThunk(
    'CalendarStatus/callUpdateCalendarStatusProperties',
    /* eslint no-unused-vars: "off" */
    async ({ calendarFilter, sharePointSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            CalendarPropertiesKey,
            calendarFilter,
            sharePointSite
        )
        return response
    }
)

const initialState = {
    view: '',
    calendarType: ''
}
export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        updateCalendarView: (state, action) => {
            state.view = action.payload
        },
        updateCalendarType: (state, action) => {
            console.log('update calendar:', action.payload)
            state.calendarType = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateCalendarStatusProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log(
                '============= finished updating Calendar Status Properties ',
                state,
                action
            )
        })
    }
})

export const { updateCalendarView, updateCalendarType } = calendarSlice.actions

export const getCurrentView = (state) => state.calendar.view
export const getCalendarType = (state) => state.calendar.calendarType

export default calendarSlice.reducer
