import getMsGraphService from './base/ms-graph.service'
import {
    MySiteGraphIdStorageKey,
    PropertiesColumnName,
    PropertiesListTitle,
    SettingsListIdStorageKey,
    WebPartUniqueKeyColumnName,
    // RootSiteGraphIdStorageKey,
    PropertiesRootListTitle,
    SettingsRootListIdStorageKey
} from '../common/constants'

export class PagePropertiesService {
    msGraphService = null

    properties: any | null

    constructor() {
        this.msGraphService = getMsGraphService()
        this.properties = {}
    }

    /**
     * Gets list item with previously saved properties
     */
    async getPropertiesListItem(
        webPartKey: string,
        expandFields: boolean,
        graphSiteId: string,
        isRoot: boolean
    ) {
        const listId = await this.getSettingListId(graphSiteId, isRoot)
        if (!listId) throw Error('Properties List not created')

        let expandQuery = ''
        if (expandFields) expandQuery = '&expand=fields'

        const existingItemResponse = await this.msGraphService.callGetApi({
            url: `/sites/${graphSiteId}/lists/${listId}/items`,
            // filter: `fields/${WebPartUniqueKeyColumnName} eq '${webPartKey}'`,
            selectStatement: `id${expandQuery}`
        })
        if (existingItemResponse.value?.length && expandFields) {
            // return existingItemResponse.value.at(0)
            return existingItemResponse.value
                .filter((v) => v.fields[WebPartUniqueKeyColumnName] === webPartKey)
                ?.at(-1)
        }

        return null
    }
    /**
     * Gets List
     */

    async getSettingListId(graphSiteId: string, isRoot: boolean) {
        let listId = isRoot
            ? window.localStorage.getItem(SettingsRootListIdStorageKey)
            : window.localStorage.getItem(SettingsListIdStorageKey)
        if (!listId) {
            const listsResponse = await this.msGraphService.callGetApi({
                url: `/sites/${graphSiteId}/lists`,
                filter: `displayName eq '${isRoot ? PropertiesRootListTitle : PropertiesListTitle}'`
            })

            if (listsResponse.value?.length) {
                listId = listsResponse.value[0].id
                if (isRoot) {
                    window.localStorage.setItem(SettingsRootListIdStorageKey, listId)
                } else {
                    window.localStorage.setItem(SettingsListIdStorageKey, listId)
                }
            } else {
                // creating the list if it hasn't been created before
                try {
                    const data = {
                        displayName: isRoot ? PropertiesRootListTitle : PropertiesListTitle,
                        columns: [
                            {
                                name: WebPartUniqueKeyColumnName,
                                text: {}
                            },
                            {
                                name: PropertiesColumnName,
                                text: {
                                    allowMultipleLines: true,
                                    maxLength: 1000000000,
                                    textType: 'plain'
                                }
                            }
                        ],
                        list: {
                            hidden: true,
                            template: 'genericList'
                        }
                    }
                    const { id: newListId } = await this.msGraphService.callPostApi(
                        `/sites/${graphSiteId}/lists`,
                        data
                    )
                    listId = newListId
                } catch (error) {
                    if (error.statusCode === 403 || error.accessCode === 'accessDenied') {
                        throw Error('Permissions not provisioned')
                    } else {
                        throw Error('Failed to create new private List')
                    }
                }
            }
            if (!isRoot) {
                window.localStorage.setItem(SettingsListIdStorageKey, listId)
            } else {
                window.localStorage.setItem(SettingsRootListIdStorageKey, listId)
            }
        }
        return listId
    }

    /**
     * Gets MS Graph site ID for current user's OneDrive site
     */
    async getMySiteGraphId(domainFromContext: string) {
        // we can cache the ID in the localStorage as it will never change for current user
        let graphSiteId = window.localStorage.getItem(MySiteGraphIdStorageKey)
        if (!graphSiteId) {
            const oneDriveDomain = `${domainFromContext.split('.')[0]}-my.sharepoint.com`

            const { sharepointIds } = await this.msGraphService.callGetApi({
                url: '/me/drive/root',
                selectStatement: 'sharepointIds'
            })
            graphSiteId = `${oneDriveDomain},${sharepointIds.siteId},${sharepointIds.webId}`

            window.localStorage.setItem(MySiteGraphIdStorageKey, graphSiteId)
        }

        return graphSiteId
    }

    /**
     * Gets properties for the web part base on unique key
     * (Properties OneDrive list can contain properties of multiple web parts).
     * @param webPartKey The key of the web part to get properties for.
     * @param domainUrl
     */
    async getProperties(webPartKey: string, domainUrl: string, rootSiteId = '') {
        if (!this.properties[webPartKey]) {
            const graphSiteId =
                rootSiteId !== '' ? rootSiteId : await this.getMySiteGraphId(domainUrl)
            const isRoot = rootSiteId !== ''
            // if there are no cached properties, we're getting them from the OneDrive
            const listItem = await this.getPropertiesListItem(webPartKey, true, graphSiteId, isRoot)

            // checking if there are any previously saved properties
            if (listItem) {
                const newProperties = { ...this.properties }
                newProperties[webPartKey] = JSON.parse(listItem.fields?.[PropertiesColumnName])
                this.properties = { ...newProperties }
            }
            // if (this.properties?.testForm) delete this.properties.testForm
        }
        return this.properties[webPartKey]
    }

    /**
     * Sets properties for the web part base on unique key
     * (Properties OneDrive list can contain properties of multiple web parts).
     * @param webPartKey The key of the web part to get properties for.
     * @param properties
     * @param domainUrl
     */
    async setProperties(
        webPartKey: string,
        properties: any,
        domainUrl: string,
        rootSiteId = ''
    ): Promise<void> {
        // getting graph site id (<tenant,site-id,web-id>) to work with
        const graphSiteId = rootSiteId !== '' ? rootSiteId : await this.getMySiteGraphId(domainUrl)
        const isRoot = rootSiteId !== ''
        // getting list id
        const listId = await this.getSettingListId(graphSiteId, isRoot)

        if (!listId) throw Error('Properties list not created')

        // updating internal cache
        const newProperties = { ...this.properties }
        newProperties[webPartKey] = JSON.parse(JSON.stringify(properties))
        this.properties = { ...newProperties }

        // converting properties object to a string
        const propertiesStr = JSON.stringify(properties)

        // checking if there are previously saved properties
        const existingItem = await this.getPropertiesListItem(webPartKey, true, graphSiteId, isRoot)
        if (existingItem) {
            // updating properties
            const itemId = existingItem.id
            const fields: any = {}
            fields[PropertiesColumnName] = propertiesStr
            const updateItemResponse = await this.msGraphService.callUpdateApi(
                `/sites/${graphSiteId}/lists/${listId}/items/${itemId}/fields`,
                fields
            )

            if (updateItemResponse.error) {
                throw new Error('Properties not saved')
            }
        } else {
            // saving properties for the first time
            const fields: any = {}
            fields[WebPartUniqueKeyColumnName] = webPartKey
            fields.Title = webPartKey
            fields[PropertiesColumnName] = propertiesStr
            const data = { fields }
            const createItemResponse = await this.msGraphService.callPostApi(
                `/sites/${graphSiteId}/lists/${listId}/items`,
                data
            )

            if (createItemResponse.error) {
                throw new Error('Properties not saved')
            }
        }
    }
}
