import getMsGraphService from './base/ms-graph.service'

export class SiteService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    // eslint-disable-next-line consistent-return
    async getSite(siteId = 'root') {
        let message = ''
        try {
            const site = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}`
            })
            if (!site) return null
            return site
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error:', e)
            // eslint-disable-next-line no-unused-vars
            message = e.message
        }
    }
}
