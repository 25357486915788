import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../scss/components/SlickSlide.scss'
import { CircularProgress } from '@mui/material'
import { selectSharePointSiteId } from '../../features/business-partners/businessPartnersSlice'
import TileLayout from '../basic/TileLayout'
import SliderWrapper from '../shared/SliderWrapper'
import Article from '../shared/Article'
import { useSharepointSitePages } from '../../hooks/useSharepointSitePages'

const Articles = () => {
    const sharePointSiteId = useSelector(selectSharePointSiteId)
    const { companyNews, loading, setLoading, getLatestNews } = useSharepointSitePages()

    useEffect(() => {
        if (sharePointSiteId) {
            setLoading(true)
            getLatestNews(sharePointSiteId)
        }
    }, [sharePointSiteId])

    return (
        <TileLayout>
            {loading ? (
                <div className="flex justify-center items-center">
                    <CircularProgress />
                </div>
            ) : (
                <div className="newsSlider">
                    <SliderWrapper items={companyNews} Child={Article} infinite />
                </div>
            )}
        </TileLayout>
    )
}

export default Articles
