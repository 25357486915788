import { BaseService } from './base/base.service'

export class GroupsService extends BaseService {
    /* eslint-disable no-useless-constructor */
    constructor() {
        super()
    }

    async getGroups() {
        let message = ''
        let groupsList = []
        try {
            const args = {
                url: '/groups',
                // filter: "groupTypes/any(grp: grp eq 'Unified') and planner/plans/any()",
                isBeta: true
            }
            const response = await this.msGraphService.callGetApi(args)
            groupsList = response?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, groupsList }
    }
}
