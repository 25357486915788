import { RiListCheck2 } from 'react-icons/ri'
import { Todo } from '@microsoft/mgt-react'
// import { Tasks } from '@microsoft/mgt-react'
// import { TasksSource } from '@microsoft/mgt-components'
import TileLayout from './basic/TileLayout'

const MyTodo = () => (
    <TileLayout title="My Todo" icon={<RiListCheck2 className="text-primary text-lg" />}>
        <div className="tile-height">
            {/* <Tasks className="bg-white py-0 px-1" dataSource={TasksSource.todo} /> */}
            <Todo className="bg-white py-0 px-1" />
        </div>
    </TileLayout>
)

export default MyTodo
