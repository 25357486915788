/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { RiDeleteBin6Line, RiFlag2Line, RiFlag2Fill, RiArchiveLine } from 'react-icons/ri'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import EmailService from '../../services/email.service'
import { getArchiveFolder } from '../../features/user-inbox/mailSlice'

const InboxActions = ({ id, reload, mailData, setAnimation }) => {
    const [loading, setLoading] = useState('')
    const emailService = new EmailService()
    const archiveFolder = useSelector(getArchiveFolder)
    const color = mailData?.flag?.flagStatus === 'flagged' ? 'text-rose-600' : ''
    const FlatIcon = mailData?.flag?.flagStatus === 'flagged' ? <RiFlag2Fill /> : <RiFlag2Line />
    const ArchiveIcon = archiveFolder.id === mailData.parentFolderId ? null : <RiArchiveLine />

    const handleMoveEmail = async () => {
        setLoading('remove')
        try {
            await emailService.deleteMail(id)
            setAnimation(true)
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Delete Mail:', error)
        } finally {
            setLoading('')
        }
    }
    const handleSetFlag = async () => {
        setLoading('flag')
        try {
            const status = mailData?.flag?.flagStatus === 'flagged' ? 'notFlagged' : 'flagged'
            await emailService.setMailFlag(id, { flagStatus: status })
            reload()
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Set Flag Mail:', error)
        } finally {
            setLoading('')
        }
    }
    const hanldeArchive = async () => {
        setLoading('archive')
        try {
            await emailService.setAchiveMail(id, { destinationId: archiveFolder.id })
            setAnimation(true)
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Set Archive Mail:', error)
        } finally {
            setLoading('')
        }
    }
    return (
        <div className="flex gap-x-1">
            <div className="hover:text-sky-400" onClick={hanldeArchive}>
                {loading === 'archive' ? <CircularProgress size={12} /> : ArchiveIcon}
            </div>
            <div className={`hover:text-sky-400 ${color}`} onClick={handleSetFlag}>
                {loading === 'flag' ? <CircularProgress size={12} /> : FlatIcon}
            </div>
            <div className="hover:text-sky-400" onClick={handleMoveEmail}>
                {loading === 'remove' ? <CircularProgress size={12} /> : <RiDeleteBin6Line />}
            </div>
        </div>
    )
}

InboxActions.propTypes = {
    id: PropTypes.string.isRequired,
    mailData: PropTypes.instanceOf(Object).isRequired,
    reload: PropTypes.func,
    setAnimation: PropTypes.func
}
InboxActions.defaultProps = {
    reload: null,
    setAnimation: null
}

export default InboxActions
