import { useEffect, useState } from 'react'
import { RiListCheck2 } from 'react-icons/ri'
import { FiPlus } from 'react-icons/fi'
import { Dropdown, Button, Accordion } from '@fluentui/react-northstar'
import { CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import TileLayout from '../basic/TileLayout'
import { PlannerTaskService } from '../../services/planner-task.service'
import PlannerTaskTitle from './PlannerTaskTitle'
import PlannerTaskContent from './PlannerTaskContent'
import '../../scss/mgt/Tasks.scss'
import '../../scss/components/Planner.scss'
import {
    AllTaskObj,
    DEFAULT_BUCKET_NAME,
    DEFAULT_TASK,
    PlannerPropertiesKey
} from '../../common/constants'
import {
    getPlanner,
    updatePlannerFilter,
    updateBucketFilter,
    callUpdatePlannerStatusProperties
} from '../../features/planner/plannerSlice'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'
import { PagePropertiesService } from '../../services/page-properties.service'

// const inputItems = ['Assigned to me', 'The Hub']
const taskService = new PlannerTaskService()
const pagePropertiesService = new PagePropertiesService()

const Planner = () => {
    const [taskList, setTaskList] = useState([])
    const [folders, setFolders] = useState([])
    const [plans, setPlans] = useState([])
    const [containerFolder, setContainerFolder] = useState('')
    const [currentPlan, setCurrentPlan] = useState('Assigned to me')
    const [filteredFolders, setFilteredFolders] = useState([])
    const [filteredTaskList, setFilteredTaskList] = useState([])
    const [selectedFolder, setSelectedFolder] = useState('')
    const [activeIndex, setActiveIndex] = useState([])
    const [isExpanded, setIsExpanded] = useState(false)
    const [titleContent, setTitleContent] = useState('')
    const [planId, setPlanId] = useState('')
    const [flat, setFlat] = useState(false)
    const [isHidden, setIsHidden] = useState(false)
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const { selectedPlan, selectedBucket } = useSelector(getPlanner)
    const sharePointSite = useSelector(selectSharePointSite)
    const updateFilteredTaskList = (tasks) => {
        setFilteredTaskList(tasks.sort((a, b) => a.completed - b.completed))
    }

    const handlePlanSelect = (e) => {
        setCurrentPlan(e?.value?.title)
        setContainerFolder(e?.value?.content)
        let folderList = folders
            .filter((folder) => folder?.parentId === e.value?.id || folder?.alwaysShow)
            .map((folder) => ({ ...folder, key: folder.id, content: folder.name }))
        folderList = [{ ...AllTaskObj, parentId: folderList?.at(0)?.parentId }, ...folderList]
        setFilteredFolders([...folderList])
        const { name: folderName, id: folderId } = folderList.at(0)
        setSelectedFolder(folderName)
        // NOTE: can also filter on the 'group' by using 'topParentId'
        // ^^ might end up using this at some point
        let foundTasks
        if (folderId === 'all_buckets') {
            foundTasks = taskList.filter((task) => task.topParentId === e.value?.id)
        } else {
            foundTasks = taskList.filter((task) => task.immediateParentId === folderId)
        }
        updateFilteredTaskList(foundTasks)

        const propertiesPayload = {
            selectedPlan: e?.value,
            selectedBucket: selectedBucket || null
        }
        dispatch(
            callUpdatePlannerStatusProperties({ selectedFilter: propertiesPayload, sharePointSite })
        )
        dispatch(updatePlannerFilter({ selectedPlan: e?.value }))
    }

    const handleFolderSelect = (e) => {
        setSelectedFolder(e?.value?.name)
        const propertiesPayload = {
            selectedPlan: selectedPlan || null,
            selectedBucket: e?.value
        }
        let foundFolder = folders.find((folder) => folder.id === e.value.id)
        // if the current bucket/folder is 'all_buckets'
        // then we still have to get it from 'filteredFolders
        if (!foundFolder) {
            foundFolder = filteredFolders.find((folder) => folder.id === e.value.id)
        }
        if (!foundFolder) return
        const { id: folderId } = e.value
        const { id: topParentId } = plans?.find((plan) => plan.title === currentPlan) || {}

        // NOTE: can also filter on the 'group' by using 'topParentId'
        // ^^ might end up using this at some point
        let foundTasks
        if (folderId === 'all_buckets') {
            foundTasks = taskList.filter((task) => task.topParentId === topParentId)
        } else {
            foundTasks = taskList.filter((task) => task.immediateParentId === folderId)
        }
        dispatch(
            callUpdatePlannerStatusProperties({ selectedFilter: propertiesPayload, sharePointSite })
        )
        dispatch(updateBucketFilter({ selectedBucket: e?.value }))
        updateFilteredTaskList(foundTasks)
    }

    const addNewTask = () => {
        const haveNewTask = filteredTaskList.filter((t) => !t.id)
        if (haveNewTask?.length) return
        setFilteredTaskList([{ ...DEFAULT_TASK }, ...filteredTaskList])
        setActiveIndex([0])
        setIsExpanded(true)
    }

    const cancelHandler = () => {
        setActiveIndex([])
        setIsExpanded(false)
        const haveNewTask = filteredTaskList.filter((t) => !t.id)
        if (!haveNewTask?.length) return
        updateFilteredTaskList([...filteredTaskList.slice(1)])
    }

    const submitHandler = (dataObj) => {
        /* eslint no-console: "off" */
        if (dataObj && dataObj.message) {
            setMessage(dataObj.message)
            setIsHidden(true)
        }
        if (dataObj && dataObj.type === 'done') {
            setTitleContent('')
        }

        setFlat(!flat)
    }

    setTimeout(() => {
        setIsHidden(false)
    }, 3000)

    const loadState = async () => {
        try {
            let plansList = await taskService.getPlans()
            if (plansList.length) {
                plansList = plansList.map((p) => {
                    let tempContainerFolder = ''
                    if (p?.contexts) {
                        const keys = Object.keys(p.contexts)
                        const lastKey = keys?.at(-1)
                        if (lastKey) {
                            tempContainerFolder = p.contexts[lastKey].displayNameSegments?.join('/')
                        }
                    }
                    return {
                        ...p,
                        key: p.id,
                        header: p.title,
                        content: tempContainerFolder
                    }
                })
            }

            const bucketList = (
                await Promise.all(plansList.map((plan) => taskService.getTaskBuckets(plan.id)))
            ).reduce((cur, ret) => [...cur, ...ret], [])

            const tasks = (
                await Promise.all(
                    /* eslint implicit-arrow-linebreak: "off" */
                    bucketList.map((plan) =>
                        /* eslint function-paren-newline: "off" */
                        taskService.getTasksForTaskFolder(plan.id)
                    )
                )
            ).reduce((cur, ret) => [...cur, ...ret], [])

            setTaskList([...tasks])
            setFolders([...bucketList])
            setPlans([...plansList])

            const plannerFilter = await pagePropertiesService.getProperties(
                PlannerPropertiesKey,
                sharePointSite
            )

            if (
                plannerFilter?.selectedPlan &&
                Object.keys(plannerFilter?.selectedPlan).length > 0
            ) {
                dispatch(updatePlannerFilter({ selectedPlan: plannerFilter?.selectedPlan }))
            } else {
                dispatch(updatePlannerFilter({ selectedPlan: plansList.at(0) }))
            }
            if (
                plannerFilter?.selectedBucket &&
                Object.keys(plannerFilter?.selectedBucket).length > 0
            ) {
                dispatch(updateBucketFilter({ selectedBucket: plannerFilter?.selectedBucket }))
            } else {
                dispatch(updateBucketFilter({ selectedBucket: bucketList.at(0) }))
            }
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setFilteredTaskList(filteredTaskList.sort((a, b) => a.completed - b.completed))
    }, [filteredTaskList])

    useEffect(() => {
        // setIsLoading(true)
        loadState().catch((e) => console.error(e))
    }, [flat])

    useEffect(() => {
        // only do a thing if we have at least the
        // groups, folders and taskList - should only run once
        if (selectedPlan && Object.keys(selectedPlan).length > 0) {
            // let's update it with the one from the list
            handlePlanSelect({ value: selectedPlan })
        }
    }, [selectedPlan])

    useEffect(() => {
        // only do a thing if we have at least the
        // groups, folders and taskList - should only run once
        if (selectedBucket && Object.keys(selectedBucket).length > 0) {
            handleFolderSelect({ value: selectedBucket })
        }
    }, [selectedBucket])

    useEffect(() => {
        const checkPlanId = plans.find((plan) => plan.content === currentPlan)
        if (checkPlanId && checkPlanId.id) {
            setContainerFolder(checkPlanId.content)
            setPlanId(checkPlanId.id)
        }
    }, [currentPlan])

    // TODO: this is just a place holder for now, will be replaced with the
    // list of Plans
    const titleChildren = (
        <div className="flex items-center">
            <div className="flex-col">
                <div className="text-xs lg:text-sm xl:text-xs text-grey-2 ml-2">
                    {containerFolder}
                </div>
                <Dropdown
                    className="w-150 widgetDropdown invertedDropdown"
                    inverted
                    items={plans}
                    placeholder="Select Plan"
                    checkable
                    getA11ySelectionMessage={{
                        onAdd: (item) => console.log('itemSelect:', item)
                    }}
                    onChange={(_, evt) => handlePlanSelect(evt)}
                    value={currentPlan}
                />
            </div>
            {/* NOTE: hiding the add Plan button for now, till we figure out how to create one */}
            {/* <Button type="button" primary flat> */}
            {/*     <FiPlus /> */}
            {/*     <span className="ml-1 uppercase font-semibold text-xs">Plan</span> */}
            {/* </Button> */}
        </div>
    )

    const plannerTasks = filteredTaskList?.map((task, idx) => ({
        // ...task,
        title: {
            className: 'planner-task-title',
            key: `planner-title-${idx}`,
            active: idx === activeIndex.at(0),
            onClick: () => setActiveIndex([idx]),
            content: (
                <div className="border-b border-grey-4 py-3 cursor-pointer w-full">
                    <PlannerTaskTitle
                        key={`planner-task-title-${idx}`}
                        index={idx}
                        title={task.name}
                        setTitleContent={setTitleContent}
                        titleContent={titleContent}
                        isCompleted={task.completed}
                        taskObj={task}
                        onSubmit={(data) => submitHandler(data)}
                    />
                </div>
            )
        },
        content: {
            key: `planner-content-${idx}`,
            active: idx === activeIndex.at(0),
            content: (
                <PlannerTaskContent
                    key={`planner-task-content-${idx}`}
                    task={task}
                    buckets={filteredFolders}
                    onCancel={cancelHandler}
                    onSubmit={(data) => submitHandler(data)}
                    titleContent={titleContent}
                    planId={planId}
                    selectedBucket={selectedFolder}
                />
            )
        }
    }))

    return (
        <TileLayout
            title="Planner"
            icon={<RiListCheck2 className="text-primary text-lg" />}
            titleChildren={titleChildren}
        >
            <>
                <div className="flex items-center relative">
                    <Dropdown
                        className="w-150 widgetDropdown invertedDropdown"
                        inverted
                        items={filteredFolders}
                        placeholder={DEFAULT_BUCKET_NAME}
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`
                        }}
                        value={selectedFolder}
                        onChange={(_, evt) => handleFolderSelect(evt)}
                    />

                    <Button
                        type="button"
                        primary
                        flat
                        onClick={() => {
                            // eslint-disable-next-line no-unused-expressions, max-len
                            // selectedFolder !== DEFAULT_BUCKET_NAME ? addNewTask() : handleSuggest()
                            addNewTask()
                        }}
                        className="add-button"
                    >
                        <FiPlus />
                        <span className="ml-1 uppercase font-semibold text-xs">Task</span>
                    </Button>
                    <div
                        className={`absolute announce flex items-center ${
                            isHidden && message ? '' : 'hidden'
                        }`}
                    >
                        <p className="font-semibold ">{message}</p>
                    </div>
                </div>

                <div className="tile-height">
                    {isLoading ? (
                        <div className="h-full flex justify-center items-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <Accordion
                            activeIndex={activeIndex}
                            expanded={isExpanded}
                            panels={plannerTasks}
                        />
                    )}
                </div>
            </>
        </TileLayout>
    )
}

export default Planner
