import React, { forwardRef } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'
import { goToUrl } from '../../common/link-helpers'

export const Photo = forwardRef(
    // eslint-disable-next-line react/prop-types
    ({ url, name, index, withOpacity, isDragging, style, imgSrc, ...props }, ref) => {
        const inlineStyles = {
            opacity: withOpacity ? '0.5' : '1',
            height: '140px',
            width: '140px',
            borderRadius: '10px',
            cursor: isDragging ? 'grabbing' : 'grab',
            backgroundColor: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            boxShadow: isDragging
                ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
                : 'rgb(63 63 68 / 5%) 0px 2px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
            transform: isDragging ? 'scale(1.05)' : 'scale(1)',
            ...style
        }
        // eslint-disable-next-line no-shadow
        const handleClick = (e, url) => {
            e.preventDefault()
            goToUrl(url)
        }
        return (
            <div ref={ref} style={inlineStyles} {...props} key={index}>
                <div className="absolute top-0 right-0 z-10">
                    <a
                        className="flex items-center p-3 rounded"
                        href={url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <FiExternalLink />
                    </a>
                </div>

                <button
                    type="button"
                    onClick={(e) => {
                        handleClick(e, url)
                    }}
                >
                    <img alt={name} src={imgSrc} className="h-8 w-auto" />
                </button>
            </div>
        )
    }
)

export const SortLogo = ({ url, id, index, name, imgSrc }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return (
        <Photo
            ref={setNodeRef}
            withOpacity={isDragging}
            style={style}
            url={url}
            index={index}
            name={name}
            imgSrc={imgSrc}
            {...attributes}
            {...listeners}
        />
    )
}

SortLogo.propTypes = {
    url: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
}
