import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { RiHeartLine } from 'react-icons/ri'
import DOMPurify from 'dompurify'
import { CompanyNewsService } from '../../services/company-news.service'
import noHeroImage from '../../assets/images/no-hero-image.png'
import { autoLinkText } from '../../common/html-helpers'

const companyNewsService = new CompanyNewsService()

const ValueItem = ({ item, sharePointSiteId }) => {
    const [imageUrl, setImageUrl] = useState('')
    const [content, setContent] = useState('')

    const getArticleImage = async () => {
        // let's check if we have the imageUrl on the object already:
        if (item?.imageUrl) {
            setImageUrl(item.imageUrl)
            return
        }

        // TODO: move to RTK - for now, let's do the hacky way
        const { drive: sharePointAssetDrive } = await companyNewsService.getAssetDrive(
            sharePointSiteId
        )
        // otherwise we get it from the graph
        if (!item.title || !sharePointSiteId || !sharePointAssetDrive.id) return
        if (!item?.titleArea?.imageWebUrl) return
        const splitImageUrl = item.titleArea.imageWebUrl.split('/')
        const actualDirectory = splitImageUrl.at(-2)
        const fileName = splitImageUrl.at(-1)
        const { thumbnail } = await companyNewsService.getThumbnails(
            sharePointSiteId,
            sharePointAssetDrive?.id,
            actualDirectory,
            fileName
        )
        const imageToSet = thumbnail?.['@microsoft.graph.downloadUrl'] || noHeroImage

        // TODO: need to do something about the error message
        setImageUrl(imageToSet)
    }

    useEffect(() => {
        // now we look at the 'firstWebPart' or allWebParts instead
        const contentToSet = item?.firstWebPart?.innerHtml || ''

        setContent(contentToSet || '')
        getArticleImage().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [item])

    return (
        <div className="w-full h-full flex">
            <div className="relative">
                <div
                    title={item.name}
                    className="valuesImage block rounded-l relative"
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center'
                    }}
                >
                    <div className="absolute left-0 right-0 bottom-3 text-center valuesTitle">
                        <div className="font-bold text-white text-base leading-none">
                            {item.title}
                        </div>
                    </div>
                    <div className="absolute left-0 right-0 top-2 text-center">
                        <div className="mobileHide flex items-center justify-center">
                            <RiHeartLine className="text-white" />
                            <div className="text-white ml-1 text-xs font-bold uppercase">
                                Values
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white rounded-r py-2 px-3 valuesText">
                <div
                    className="text-xs lg:text-sm xl:text-xs"
                    dangerouslySetInnerHTML={{
                        /* eslint react/no-danger: "off" */
                        __html: DOMPurify.sanitize(autoLinkText(content))
                    }}
                />
            </div>
        </div>
    )
}

ValueItem.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    sharePointSiteId: PropTypes.string.isRequired
}

export default ValueItem
