import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { app } from '@microsoft/teams-js'
import { updateSharePointSite } from '../features/business-partners/businessPartnersSlice'

const OurCompanySharePoint = () => {
    const [currentPageUrl, setCurrentPageUrl] = useState('')

    const iFrameWidth = '100%'
    const iFrameHeight = '100%'
    const dispatch = useDispatch()

    const getSharepointSite = async () => {
        const context = await app.getContext()
        const teamSiteDomain = context?.sharePointSite?.teamSiteDomain || ''
        dispatch(updateSharePointSite(teamSiteDomain))
        setCurrentPageUrl(`https://${teamSiteDomain}`)
    }

    useEffect(() => {
        app.initialize()
        getSharepointSite().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [])

    return (
        <iframe
            src={currentPageUrl}
            title="test webpage"
            width={iFrameWidth}
            height={iFrameHeight}
        />
    )
}

export default OurCompanySharePoint
