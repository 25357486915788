import { useState, useEffect } from 'react'

const useContextMenu = () => {
    const [clicked, setClicked] = useState(false)
    const [points, setPoints] = useState({
        x: 0,
        y: 0
    })
    useEffect(() => {
        const handleClick = () => setClicked(false)
        document.addEventListener('click', handleClick)
        document.addEventListener('keydown', handleClick, false)
        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('keydown', handleClick, false)
        }
    }, [])
    return {
        clicked,
        setClicked,
        points,
        setPoints
    }
}
export default useContextMenu
