import { prepScopes } from '@microsoft/mgt-element'

export async function setPlannerTaskDetails(graph, taskId, details, eTag) {
    return graph
        .api(`/planner/tasks/${taskId}`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.ReadWrite.All'))
        .header('If-Match', eTag)
        .version('beta')
        .patch(JSON.stringify(details))
}

export function addPlannerTask(graph, newTask) {
    return graph
        .api('/planner/tasks')
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.ReadWrite.All'))
        .version('beta')
        .post(newTask)
}

export function addPlannerTaskDetail(graph, taskId, taskDetail, eTag) {
    return graph
        .api(`/planner/tasks/${taskId}/details`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.ReadWrite.All'))
        .header('If-Match', eTag)
        .version('beta')
        .update(taskDetail)
}

export function assignPeopleToPlannerTask(graph, taskId, people, eTag) {
    return setPlannerTaskDetails(
        graph,
        taskId,
        {
            assignments: people
        },
        eTag
    )
}

export function removePlannerTask(graph, taskId, eTag) {
    return graph
        .api(`/planner/tasks/${taskId}`)
        .header('Cache-Control', 'no-store')
        .header('If-Match', eTag)
        .middlewareOptions(prepScopes('Group.ReadWrite.All'))
        .version('beta')
        .delete()
}

export function setPlannerTaskComplete(graph, taskId, eTag) {
    return setPlannerTaskDetails(
        graph,
        taskId,
        {
            percentComplete: 100
        },
        eTag
    )
}

export function setPlannerTaskIncomplete(graph, taskId, eTag) {
    return setPlannerTaskDetails(
        graph,
        taskId,
        {
            percentComplete: 0
        },
        eTag
    )
}

export async function getPlansForGroup(graph, groupId) {
    const scopes = 'Group.Read.All'

    const uri = `/groups/${groupId}/planner/plans`
    const plans = await graph
        .api(uri)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes(scopes))
        .version('beta')
        .get()
    return plans ? plans.value : null
}

export async function getSinglePlannerPlan(graph, planId) {
    const plan = await graph
        .api(`/planner/plans/${planId}`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .version('beta')
        .get()

    return plan
}

export async function getBucketsForPlannerPlan(graph, planId) {
    const buckets = await graph
        .api(`/planner/plans/${planId}/buckets`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .version('beta')
        .get()

    return buckets && buckets.value
}

export async function getPlannerPlanParent(graph, planId) {
    const parentBucket = await graph
        .api(`/planner/plans/${planId}/details`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .get()

    return parentBucket
}

export async function getAllMyPlannerPlans(graph) {
    const plans = await graph
        .api('/me/planner/plans')
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .version('beta')
        .get()

    /* eslint no-console: "off" */
    console.log('planner plans that we found', plans.value)
    return plans && plans.value
}

export async function getTasksForPlannerBucket(graph, bucketId) {
    const tasks = await graph
        .api(`/planner/buckets/${bucketId}/tasks`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .version('beta')
        .get()

    return tasks && tasks.value
}

export async function getPlannerTaskDetails(graph, taskId) {
    const taskDetails = await graph
        .api(`/planner/tasks/${taskId}/details`)
        .header('Cache-Control', 'no-store')
        .middlewareOptions(prepScopes('Group.Read.All'))
        .version('beta')
        .get()

    return taskDetails
}
