// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme } from '@fluentui/react-northstar'
// import { useTeams } from 'msteams-react-base-component'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'
// import { ThemeProvider, PartialTheme } from '@fluentui/react';
import '../scss/components/App.scss'
import Privacy from './Privacy'
import Tab from './Tab'
import TabConfig from './TabConfig'
import TermsOfUse from './TermsOfUse'
import OurCompany from './OurCompany'
import CompanyNews from './CompanyNews'
import OurCompanySharePoint from './OurCompanySharePoint'
import Setting from './Setting'

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
// const App = () => {
// const { theme } = useTeams({})[0]
// const appTheme = {
//     palette: {
//         themePrimary: 'red'
//     }
// };

const App = () => (
    <Provider theme={teamsTheme} styles={{ backgroundColor: '#eeeeee' }}>
        <Router>
            <Routes>
                <Route path="/" element={<Tab />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/tab" element={<Tab />} />
                <Route path="/config" element={<TabConfig />} />
                <Route path="/our-company" element={<OurCompany />} />
                <Route path="/company-news" element={<CompanyNews />} />
                <Route path="/our-company-sp" element={<OurCompanySharePoint />} />
                <Route path="/settings" element={<Setting />} />
            </Routes>
        </Router>
    </Provider>
)

export default App
