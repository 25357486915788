import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { selectSubSites } from '../../features/business-partners/businessPartnersSlice'
import TileLayout from '../basic/TileLayout'
import '../../scss/components/SlickSlide.scss'
import SliderWrapper from '../shared/SliderWrapper'
import ClientSuccessItem from './ClientSuccessItem'
import { useSharepointSitePages } from '../../hooks/useSharepointSitePages'

const ClientSuccesses = () => {
    const subSites = useSelector(selectSubSites)
    const [subSiteId, setSubSiteId] = useState('')
    const { companyNews, loading, setLoading, getLatestNews } = useSharepointSitePages()

    useEffect(() => {
        if (!subSites.length) return
        const subSite = subSites.find(
            (site) => site.name?.toLowerCase().indexOf('clientsuccesses') > -1
        )
        if (!subSite) return
        setSubSiteId(subSite.id)
        setLoading(true)
        getLatestNews(subSite.id, subSite.webUrl)
    }, [subSites])

    return (
        <TileLayout>
            <div className="noSlickDots celebSlider">
                {loading ? (
                    <div className="flex flex-row justify-center pt-8">
                        <CircularProgress />
                    </div>
                ) : (
                    <SliderWrapper
                        items={companyNews}
                        Child={ClientSuccessItem}
                        sharePointSiteId={subSiteId}
                        fade
                        infinite
                    />
                )}
            </div>
        </TileLayout>
    )
}

export default ClientSuccesses
