import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { RiNewspaperLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Button } from '@fluentui/react-northstar'
import DOMPurify from 'dompurify'
import {
    selectAssetDrive,
    selectSharePointSiteId,
    selectSharePointSiteUrl
} from '../../features/business-partners/businessPartnersSlice'
import { CompanyNewsService } from '../../services/company-news.service'
import noHeroImage from '../../assets/images/no-hero-image.png'
import { autoLinkText } from '../../common/html-helpers'
import { goToUrl } from '../../common/link-helpers'

const Article = ({ item }) => {
    const companyNewsService = new CompanyNewsService()
    const sharePointSiteId = useSelector(selectSharePointSiteId)
    const sharePointAssetDrive = useSelector(selectAssetDrive)
    const sharePointSiteUrl = useSelector(selectSharePointSiteUrl)
    const [imageUrl, setImageUrl] = useState('')
    const [content, setContent] = useState('')

    const goToArticle = goToUrl

    const getArticleImage = async () => {
        // let's check if we have the imageUrl on the object already:
        if (item?.imageUrl) {
            setImageUrl(item.imageUrl)
            return
        }

        // otherwise we get it from the graph
        if (!item.title || !sharePointSiteId || !sharePointAssetDrive.id) return
        // so we need to ge tth path from titleArea/imageWebUrl
        if (!item?.titleArea?.imageWebUrl) return
        // if the newsLink is renamed, this path will have
        // the original place where the image is uploaded to
        const splitImageUrl = item.titleArea.imageWebUrl.split('/')
        const actualDirectory = splitImageUrl.at(-2)
        // for now we are assuming the filename doesn't have any periods in it
        const fileName = splitImageUrl.at(-1)
        const { thumbnail } = await companyNewsService.getThumbnails(
            sharePointSiteId,
            sharePointAssetDrive?.id,
            actualDirectory,
            fileName
        )
        const imageToSet = thumbnail?.['@microsoft.graph.downloadUrl'] || noHeroImage

        // TODO: need to do something about the error message
        await setImageUrl(imageToSet)
    }

    useEffect(() => {
        // now we look at the 'firstWebPart' or allWebParts instead
        const contentToSet = item?.firstWebPart?.innerHtml || ''

        setContent(contentToSet || '')
        getArticleImage().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [item])

    return (
        <div className="grid grid-cols-12 w-full h-full relative newsHeight">
            <div className="w-full col-span-12 md:col-span-7 lg:col-span-6 xl:col-span-8 cursor-pointer hover:opacity-75">
                <a href={`${sharePointSiteUrl}/${item.webUrl}`} target="_blank" rel="noreferrer">
                    <div
                        title={item.name}
                        className="w-full newsArticleImage block rounded-l"
                        style={{
                            backgroundImage: `url(${imageUrl})`
                        }}
                    />
                </a>
            </div>
            <div className="w-full md:col-span-5 lg:col-span-6 xl:col-span-4 absolute top-0 bottom-0 md:relative md:bg-white rounded-r py-2 px-3 newsArticleText">
                <div className="flex items-center mt-2 mb-4">
                    <RiNewspaperLine className="text-primary text-lg" />
                    <div className="ml-2 text-xs font-bold text-primary uppercase">
                        Company news
                    </div>
                </div>
                <div className="newsTitle text-primary font-bold text-lg lg:text-xl mb-4 leading-none lg:leading-6">
                    {item.title}
                </div>
                <div className="text-xs uppercase font-semibold text-grey-3 mb-1">{item.day}</div>
                <div
                    className="blurb text-xs xl:text-sm"
                    dangerouslySetInnerHTML={{
                        /* eslint react/no-danger: "off" */
                        __html: DOMPurify.sanitize(autoLinkText(content))
                    }}
                />

                <Button
                    flat
                    primary
                    className="mt-4 uppercase text-xs secondaryButton"
                    content="Read More"
                    onClick={() => goToArticle(`${sharePointSiteUrl}/${item.webUrl}`)}
                />
            </div>
        </div>
    )
}

Article.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired
}

export default Article
