import React from 'react'
import PropTypes from 'prop-types'
import { Agenda } from '@microsoft/mgt-react'
import { Navigate } from 'react-big-calendar'
import { format } from 'date-fns'
import Meeting from './Meeting'

const CustomAgendaView = ({ date, days, groupByDay, showMax }) => (
    <div className="h-full overflow-y-auto">
        <Agenda groupByDay={groupByDay} date={date} days={days} showMax={showMax}>
            <Meeting />
        </Agenda>
    </div>
)

CustomAgendaView.propTypes = {
    date: PropTypes.instanceOf(Date),
    days: PropTypes.number,
    groupByDay: PropTypes.bool,
    showMax: PropTypes.number
}

CustomAgendaView.defaultProps = {
    date: format(new Date(), 'yyyy-MM-dd'),
    days: 1,
    groupByDay: true,
    showMax: 20
}

CustomAgendaView.title = (date) => `My plan for the day: ${date.toLocaleDateString()}`

CustomAgendaView.navigate = (date, action, { localizer }) => {
    /* eslint indent: "off" */
    switch (action) {
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, 'day')

        case Navigate.NEXT:
            return localizer.add(date, 1, 'day')

        default:
            return date
    }
}

export default CustomAgendaView
