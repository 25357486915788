import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { LogoBannerPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()
const initialState = {
    logoList: []
}

export const callUpdateLogoProperties = createAsyncThunk(
    'logoBanner/callUpdateLogoProperties',
    /* eslint no-unused-vars: "off" */
    async ({ logoList, sharePointSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            LogoBannerPropertiesKey,
            logoList,
            sharePointSite
        )
        return response
    }
)

export const logoBannerSlice = createSlice({
    name: 'logoBanner',
    initialState,
    reducers: {
        updateLogoBannerList: (state, action) => {
            if (!action.payload?.length) return
            state.logoList = [...action.payload]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateLogoProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log('============= finished updating logo Properties ', state, action)
        })
    }
})

export const { updateLogoBannerList } = logoBannerSlice.actions

export const getLogoList = (state) => state.logoBanner.logoList

export default logoBannerSlice.reducer
