import React from 'react'
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'
import { RiNewspaperLine } from 'react-icons/ri'
import { goToUrl } from '../../common/link-helpers'

const NewsList = ({ item, idx }) => {
    const handleNewsClick = goToUrl

    return (
        <ListItem key={`search-item-${idx}`} onClick={() => handleNewsClick(item?.webUrl)}>
            <ListItemButton>
                <div className="flex items-start w-full">
                    <ListItemIcon
                        className="w-6 h-6 flex items-center"
                        style={{ minWidth: '40px' }}
                    >
                        <RiNewspaperLine className="text-secondary text-lg" />
                    </ListItemIcon>

                    <div className="grid grid-cols-12 gap-2 w-full">
                        <div className="text-xs col-span-3 font-semibold break-words">
                            {item?.title}
                        </div>
                        <div className="text-grey-2 text-xs col-span-6">{item?.content}</div>
                        <div className="flex justify-end col-span-3">
                            <div className="text-xxs text-grey-3 font-semibold text-right">
                                {item?.createdBy.user.displayName}
                            </div>
                        </div>
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    )
}

NewsList.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    idx: PropTypes.number.isRequired
}

export default NewsList
