import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Input, Table } from '@fluentui/react-northstar'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { RiCamera2Line } from 'react-icons/ri'
import { SiteService } from '../../services/site.service'
import {
    callUpdateSubLogoProperties,
    updatebase64Img
    // getBase64Img
} from '../../features/sub-logo/subLogo.slice'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'
import '../../scss/components/setting-form.scss'
import { SubLogoPropertiesKey } from '../../common/constants'
import { PagePropertiesService } from '../../services/page-properties.service'

const FormSetting = () => {
    const siteService = new SiteService()
    const pagePropertiesService = new PagePropertiesService()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const sharePointSite = useSelector(selectSharePointSite)
    const hiddenFileInput = useRef(null)
    const [rootSiteId, setRootSiteId] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [previewImage, setPreviewImage] = useState(undefined)
    // const currentSubLogo = useSelector(getBase64Img)
    const [initForm, setInitForm] = useState(false)

    const imageType = ['image/png', 'image/jpeg', 'image/jpg']
    const header = {
        items: ['Name', 'Link', 'Picture']
    }
    const rows = [
        {
            key: 1,
            items: ['Litmos', 'URL input here prefilled with the URL', 'Image file input here']
        }
    ]

    const triggerFileUpload = () => {
        hiddenFileInput.current.click()
    }

    const schema = yup.object({
        // fileUpload: yup.array().required()
    })
    const getBase64 = (file) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const data = String(reader.result)
                resolve(data || null)
            }
            // eslint-disable-next-line prefer-promise-reject-errors, no-unused-vars
            reader.onerror = (error) => reject(null)
        })
    const initialForm = async () => {
        setInitForm(true)
        try {
            const rootSite = await siteService.getSite()
            const subLogo = await pagePropertiesService.getProperties(
                SubLogoPropertiesKey,
                sharePointSite,
                rootSite.id
            )
            if (subLogo) {
                setPreviewImage(subLogo)
            }
            setRootSiteId(rootSite.id || '')
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('initialForm:', error)
        } finally {
            setInitForm(false)
        }
    }

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        clearErrors,
        register,
        setError,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            fileUpload: [
                {
                    name: ''
                }
            ]
        }
    })
    const { ref: fileUploadRef, ...fileUploadFields } = register('fileUpload')
    const watchFileUpload = watch('fileUpload')
    const handleClearError = (name) => clearErrors(name)
    // eslint-disable-next-line no-unused-vars
    const onSubmitForm = async (data) => {
        try {
            setIsSaving(true)
            const base64File = await getBase64(data.fileUpload[0])
            if (base64File) {
                await dispatch(
                    callUpdateSubLogoProperties({
                        subLogo: base64File,
                        sharePointSite,
                        rootSiteId
                    })
                )
                dispatch(updatebase64Img(base64File))
            }
            navigate('/')
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('error:', error)
        } finally {
            setIsSaving(false)
        }
    }

    const cancelClickHandler = () => {
        navigate('/tab')
    }

    useEffect(() => {
        if (
            watchFileUpload &&
            watchFileUpload[0]?.type &&
            imageType.includes(watchFileUpload[0]?.type)
        ) {
            handleClearError('fileUpload')
            setPreviewImage(URL.createObjectURL(getValues('fileUpload')[0]))
        }
        if (watchFileUpload[0]?.name !== '' && !imageType.includes(watchFileUpload[0]?.type)) {
            setError('fileUpload', {
                type: 'custom',
                message: 'Invalid Image Type. Please choose the PNG/ JPG Image'
            })
        }
    }, [watchFileUpload])

    useEffect(() => {
        reset()
        clearErrors()
        initialForm()
    }, [])

    return (
        <div className="bg-white rounded p-4">
            <form className="w-full mb-0" onSubmit={handleSubmit(onSubmitForm)}>
                <h1 className="mt-0 text-lg font-bold text-primary mb-6">The Hub Settings</h1>
                <div className="mb-6">
                    <div className="text-xs font-bold text-black uppercase">Company Logo</div>
                    <div className="text-black text-xs mb-4">
                        The company logo displays on the left of the search bar.
                        <div>
                            Select an image file (PNG/JPG only) of the logo and ensure all text is
                            clearly visible on a white background.
                        </div>
                    </div>
                    <div className="flex items-center mt-1">
                        <Input
                            className="force-hidden"
                            placeholder="Select file"
                            type="file"
                            clearable
                            accept="image/png, image/gif, image/jpeg"
                            {...fileUploadFields}
                            // ref="hiddenFileInput"
                            ref={(instance) => {
                                fileUploadRef(instance)
                                hiddenFileInput.current = instance
                            }}
                        />
                        <Input
                            placeholder="Select file"
                            value={watchFileUpload[0].name}
                            onClick={triggerFileUpload}
                        />
                        <Button
                            primary
                            flat
                            content="Upload"
                            className="text-grey-3 text-xl"
                            onClick={triggerFileUpload}
                        />
                    </div>
                    {errors.fileUpload && errors.fileUpload.message ? (
                        <p className="text-rose-600">{errors.fileUpload.message}</p>
                    ) : null}
                </div>
                <div className="preview-image mb-6">
                    {previewImage ? (
                        <img src={previewImage} alt="preview_image" className="preview-image" />
                    ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center text-2xl text-slate-500">
                            <RiCamera2Line />
                            <div className="mt-2 flex items-center gap-2-px">
                                {!initForm ? (
                                    <span className="text-base">Upload Image</span>
                                ) : (
                                    <>
                                        <CircularProgress size="15" color="inherit" />
                                        <span className="text-xs">Loading Logo ....</span>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="mb-6 hide">
                    <div className="text-xs font-bold text-black uppercase">Quick Links</div>
                    <div className="text-black text-xs mb-4">
                        Edit links and image files for the quick links section. Image file must be
                        PNG or JPG and in square ratio.
                    </div>
                    <div>
                        <Table header={header} rows={rows} aria-label="Static table" />
                    </div>
                    <div className="my-2 text-black text-xs">
                        To add a new quick link, fill the form below and click the &apos;Add New
                        Row&apos; button.
                    </div>
                    <div className="flex items-center mt-2 justify-between w-full">
                        <div className="mr-4">
                            <Input fluid placeholder="Name" />
                        </div>
                        <div className="mr-4">
                            <Input fluid placeholder="https://.." />
                        </div>
                        <div className="mr-4">
                            <Input placeholder="Select image file" />
                        </div>
                        <Button type="button" primary flat>
                            Add New Row
                        </Button>
                    </div>
                </div>
                <div className="mt-10 flex items-center">
                    <Button className="mr-4" type="button" flat onClick={cancelClickHandler}>
                        Cancel
                    </Button>
                    <Button
                        className=""
                        type="button"
                        primary
                        flat
                        disabled={isSaving}
                        onClick={
                            errors && Object.keys(errors).length ? null : handleSubmit(onSubmitForm)
                        }
                    >
                        {isSaving && (
                            <div className="flex flex-row justify-between align-middle">
                                <CircularProgress size="15" color="secondary" />
                            </div>
                        )}
                        Save Changes
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default FormSetting
