import { createSlice } from '@reduxjs/toolkit'

export const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState: {
        value: 0
    },
    reducers: {
        increment: (state) => {
            /* eslint-disable no-param-reassign */
            state.value += 1
        },
        decrement: (state) => {
            /* eslint-disable no-param-reassign */
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            /* eslint-disable no-param-reassign */
            state.value += action.payload
        }
    }
})

export const { increment, decrement, incrementByAmount } = globalSearchSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
    setTimeout(() => {
        dispatch(incrementByAmount(amount))
    }, 1000)
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.globalSearch.value

export default globalSearchSlice.reducer
