/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { FiTrash2, FiMove } from 'react-icons/fi'
import PropTypes from 'prop-types'
import '../../scss/components/ThreeDot.scss'

const LayoutActions = ({ onTileHide, setMovingTab, role }) => {
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)

    setTimeout(() => {
        setShow(false)
    }, 4 * 1000)

    const moveTab = () => {
        setMovingTab()
    }

    const removeHandle = async () => {
        onTileHide()
    }
    return (
        <div className="flex flex-direction absolute top-0 right-0 z-10 layoutActions">
            {show && (
                <div className="bg-black text-white p-1 flex items-center rounded px-2 py-2 notice">
                    Click & drag this button to move the widget!
                </div>
            )}
            <div className="flex flex-direction absolute top-0 right-0 z-10 translate-y-[-60%] translate-x-[30%] layoutActions">
                {role?.canMove ? (
                    <div
                        className="p-2 rounded cursor-pointer text-white mr-1 bg-ms-blue drop-shadow-lg"
                        onMouseDown={moveTab}
                        onMouseUp={moveTab}
                        onPointerMove={moveTab}
                        onMouseEnter={moveTab}
                        onClick={handleShow}
                    >
                        <FiMove className="" />
                    </div>
                ) : null}
                {role?.canRemove ? (
                    <div className="p-2 rounded cursor-pointer text-white bg-red drop-shadow-lg">
                        <FiTrash2 className="" onClick={removeHandle} />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

LayoutActions.propTypes = {
    onTileHide: PropTypes.func.isRequired,
    setMovingTab: PropTypes.func.isRequired,
    role: PropTypes.instanceOf(Object).isRequired
}

export default LayoutActions
