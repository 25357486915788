import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { PlannerPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()

const initialState = {
    selectedGroup: {},
    selectedPlan: {},
    selectedBucket: {}
}

export const callUpdatePlannerStatusProperties = createAsyncThunk(
    'PlannerStatus/callUpdatePlannerStatusProperties',
    /* eslint no-unused-vars: "off" */
    async ({ selectedFilter, sharePointSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            PlannerPropertiesKey,
            selectedFilter,
            sharePointSite
        )
        return response
    }
)

export const plannerSlice = createSlice({
    name: 'planner',
    initialState,
    reducers: {
        updateGroupFilter: (state, action) => {
            state.selectedGroup = action.payload.selectedGroup
        },
        updatePlannerFilter: (state, action) => {
            /* eslint no-console: "off" */
            console.debug('======== setting the Plan we want to see first', action.payload)
            state.selectedPlan = action.payload.selectedPlan
        },
        updateBucketFilter: (state, action) => {
            state.selectedBucket = action.payload.selectedBucket
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdatePlannerStatusProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log('============= finished updating Planner Properties ', state, action)
        })
    }
})

export const { updateGroupFilter, updatePlannerFilter, updateBucketFilter } = plannerSlice.actions

export const getPlanner = (state) => state.planner

export default plannerSlice.reducer
