import React, { useEffect, useState } from 'react'
import { Dropdown } from '@fluentui/react-northstar'
import { RiCalendar2Line, RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import PropTypes from 'prop-types'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import { useDispatch } from 'react-redux'
import TileLayout from './basic/TileLayout'
import { updateCalendarType } from '../features/calendar/calendarSlice'

const CustomCalendarToolBar = ({ date, view, onView, onNavigate }) => {
    const dispatch = useDispatch()
    const calendars = [
        {
            /* eslint quotes: "off" */
            header: `What's On`,
            content: 'What your day looks like',
            key: 'ALL_EVENTS_AND_MEETINGS'
        },
        {
            header: 'Events',
            content: 'All company events',
            key: 'EVENTS'
        },
        {
            header: 'Meetings',
            content: 'Your meetings for the time period',
            key: 'MEETINGS'
        }
    ]
    const event = [
        {
            header: 'All Calendars',
            // content: 'All Calendars',
            key: 'ALL_CALENDARS'
        },
        {
            header: 'My Calendars',
            // content: 'All Calendars',
            key: 'MY_CALENDARS'
        },
        {
            header: 'Events',
            content: 'All company events',
            key: 'EVENTS'
        }
    ]

    const calendarTypeOptions = ['Day', 'Week', 'Month', 'Agenda']
    const [selectedCalendar, setSelectedCalendar] = useState('ALL_EVENTS_AND_MEETINGS')
    const [label, setLabel] = useState('')
    const [currentDate, setCurrentDate] = useState(null)
    const defaultView = calendarTypeOptions?.find((e) => e.toLowerCase().includes(view))

    const setLabelValue = () => {
        let dateString = ''
        if (view === 'day') dateString = format(date, 'EEEE - dd/MM')
        if (view === 'agenda') dateString = format(date, 'dd/MM/yyyy')
        if (view === 'month') dateString = format(date, 'MMMM yyyy')
        if (view === 'week') {
            const firstDate = startOfWeek(date)
            const lastDate = endOfWeek(date)
            dateString = `${format(firstDate, 'dd/MM/yyyy')} - ${format(lastDate, 'dd/MM/yyyy')}`
        }

        setLabel(dateString)
    }

    const handleDayChangeOfDropdown = (_, evt) => {
        setSelectedCalendar(evt.value?.key)
    }
    const handleChangeEvent = (_, evt) => {
        const selected = evt.value.key
        dispatch(updateCalendarType(selected))
    }

    const handleDayChange = (value, viewFn) => {
        const calendarType = value.toLowerCase()
        viewFn(calendarType)
    }

    const goBack = () => {
        if (view === 'agenda' || view === 'day') date.setDate(date.getDate() - 1)
        else if (view === 'month') date.setMonth(date.getMonth() - 1)
        else date.setDate(date.getDate() - 7)
        setCurrentDate(new Date(date))
        onNavigate('prev')
    }

    const goNext = () => {
        if (view === 'agenda' || view === 'day') date.setDate(date.getDate() + 1)
        else if (view === 'month') date.setMonth(date.getMonth() + 1)
        else date.setDate(date.getDate() + 7)
        setCurrentDate(new Date(date))
        onNavigate('next')
    }

    useEffect(() => {
        if (!selectedCalendar) return
        // todo: change the events based on the selection
        /* eslint no-console: "off" */
        console.log('the set value from selectedCalendar', selectedCalendar)
    }, [selectedCalendar])

    useEffect(() => {
        setLabelValue()
    }, [view, currentDate])

    useEffect(() => {
        setCurrentDate(new Date(date))
    }, [])

    return (
        <TileLayout title="Calendar" icon={<RiCalendar2Line className="text-primary text-lg" />}>
            <>
                <div className="flex items-center justify-between">
                    <button type="button" onClick={goBack}>
                        <RiArrowLeftSLine className="text-grey-3 text-xl" />
                    </button>
                    <div className="font-bold text-primary">{label}</div>
                    <button type="button" onClick={goNext}>
                        <RiArrowRightSLine className="text-grey-3 text-xl" />
                    </button>
                </div>
                <div className="absolute top-0 right-0 flex items-center">
                    <Dropdown
                        className="hidden w-150 widgetDropdown invertedDropdown"
                        inverted
                        placeholder="What's on"
                        checkable
                        items={calendars}
                        onChange={handleDayChangeOfDropdown}
                    />
                    <Dropdown
                        className="w-150 widgetDropdown invertedDropdown"
                        inverted
                        placeholder="Calendars"
                        checkable
                        items={event}
                        defaultValue="All Calendars"
                        onChange={handleChangeEvent}
                    />
                    <Dropdown
                        className="w-150 widgetDropdown invertedDropdown"
                        inverted
                        placeholder="Month"
                        checkable
                        defaultValue={defaultView}
                        items={calendarTypeOptions}
                        getA11ySelectionMessage={{
                            onAdd: (item) => handleDayChange(item, onView)
                        }}
                    />
                </div>
            </>
        </TileLayout>
    )
}

CustomCalendarToolBar.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    view: PropTypes.string.isRequired,
    onView: PropTypes.instanceOf(Function).isRequired,
    onNavigate: PropTypes.instanceOf(Function).isRequired
}

export default CustomCalendarToolBar
