import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from '@fluentui/react-northstar'
import { FiGift } from 'react-icons/fi'
import DOMPurify from 'dompurify'
import { CompanyNewsService } from '../../services/company-news.service'
import noHeroImage from '../../assets/images/no-hero-image.png'
import { autoLinkText } from '../../common/html-helpers'
import { MAX_PREVIEW_LENGTH } from '../../common/constants'
import FormDialog from '../hr-forms/FormDialog'
import { goToUrl } from '../../common/link-helpers'

const CelebrationItem = ({ item, sharePointSiteId }) => {
    const companyNewsService = new CompanyNewsService()
    const [imageUrl, setImageUrl] = useState('')
    const [content, setContent] = useState('')
    const [tableImage, setTableImage] = useState(null)
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    const getArticleImage = async () => {
        // let's check if we have the imageUrl on the object already:
        if (item?.imageUrl) {
            setImageUrl(item.imageUrl)
            return
        }

        // TODO: move to RTK - for now, let's do the hacky way
        const { drive: sharePointAssetDrive } = await companyNewsService.getAssetDrive(
            sharePointSiteId
        )
        // otherwise we get it from the graph
        if (!item.title || !sharePointSiteId || !sharePointAssetDrive.id) return
        if (!item?.titleArea?.imageWebUrl) return
        const splitImageUrl = item.titleArea.imageWebUrl.split('/')
        const actualDirectory = splitImageUrl.at(-2)
        const fileName = splitImageUrl.at(-1)
        const { thumbnail } = await companyNewsService.getThumbnails(
            sharePointSiteId,
            sharePointAssetDrive?.id,
            actualDirectory,
            fileName
        )
        const imageToSet = thumbnail?.['@microsoft.graph.downloadUrl'] || noHeroImage

        // TODO: need to do something about the error message
        setImageUrl(imageToSet)
    }

    const handleWrapperClick = () => {
        if (item.webUrl) goToUrl(item.webUrl)
    }

    const handleKeyDownEvent = (event) => {
        console.log('keydown event')
        if (event.keycode === 13 && item.webUrl) goToUrl(item.webUrl)
    }

    useEffect(() => {
        let contentToSet = ''
        if (item?.content?.length > MAX_PREVIEW_LENGTH) {
            contentToSet = `${item.content.slice(0, MAX_PREVIEW_LENGTH)}...`
        } else {
            contentToSet = item?.content
        }
        setContent(contentToSet || '')
        let tableImageUrl = null
        if (item?.tableImage) {
            const parsedIableImage = JSON.parse(item.tableImage)
            tableImageUrl = `${parsedIableImage?.serverUrl}${parsedIableImage?.serverRelativeUrl}`
        }
        setTableImage(tableImageUrl)
        getArticleImage().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [item])

    return (
        <div
            className={!tableImage && imageUrl ? 'cursor-pointer hover:opacity-75' : ''}
            onClick={handleWrapperClick}
            onKeyDown={handleKeyDownEvent}
            role="button"
            tabIndex="0"
        >
            <div
                className="square-thumbnail-bg rounded"
                style={{
                    position: 'relative',
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center'
                }}
            >
                <div className="absolute left-4 top-2/4 -translate-y-2/4 right-4 z-10 text-center">
                    {/*
                    <div className="text-xs uppercase font-semibold text-white mb-1">
                        {item.day}
                    </div>
                    */}
                    <div className="mobileHide flex items-center justify-center mb-1">
                        <FiGift className="text-white text-lg" />
                        <div className="ml-2 text-xs font-bold text-white uppercase">
                            Client Success
                        </div>
                    </div>
                    <div className="text-white text-xs font-semibold">{item.title}</div>
                    <div
                        className="text-xs text-white"
                        dangerouslySetInnerHTML={{
                            /* eslint react/no-danger: "off" */
                            __html: DOMPurify.sanitize(autoLinkText(content))
                        }}
                    />
                    {/* logic: If Table Image column has an image, display below button. On click
                    opens popup view of Table Image */}
                    {tableImage !== null && (
                        <>
                            <Button
                                flat
                                primary
                                size="small"
                                className="mt-2 uppercase text-xs primaryButton"
                                content="Read More"
                                onClick={handleOpen}
                            />
                            <FormDialog
                                headerText=""
                                onCancel={handleClose}
                                showCancel={false}
                                open={open}
                                content={
                                    <div>
                                        <img src={tableImage} alt={item.title} />
                                    </div>
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

CelebrationItem.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    sharePointSiteId: PropTypes.string.isRequired
}

export default CelebrationItem
