import { useEffect, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import { Button, Popup } from '@fluentui/react-northstar'
import { useDispatch, useSelector } from 'react-redux'
import {
    callUpdateOrderTabsProperties,
    // callUpdateOrderTabsProperties,
    callUpdateProperties,
    getOrderTabs,
    // getOrderTabs,
    getTabLayout,
    updateOrderTabs,
    // updateOrderTabs,
    updateTabLayoutByKey
} from '../features/tab-layout/tabLayoutSlice'
import { ListOfWidgets, WidgetsToHide } from '../common/constants'
import {
    selectAssetDrive,
    selectSharePointSite
} from '../features/business-partners/businessPartnersSlice'
import { orderTabs } from '../common/orderTabs'

const AddWidget = () => {
    const [widgetList, setWidgetList] = useState([])
    const dispatch = useDispatch()
    const tabLayout = useSelector(getTabLayout)
    // const orderTabs = useSelector(getOrderTabs)
    const savedOrderTabs = useSelector(getOrderTabs)
    const sharePointSite = useSelector(selectSharePointSite)
    const assetDriveSite = useSelector(selectAssetDrive)

    const addWidget = ({ name }) => {
        dispatch(updateTabLayoutByKey({ name, display: true }))
        dispatch(callUpdateProperties({ sharePointSite }))
        const newTab = orderTabs
            .filter((tab) => tab.title === name)
            .map((tab) => ({
                id: tab.id,
                title: tab.title
            }))
            ?.at(0)
        const checkTab = savedOrderTabs.find((tab) => tab.id === newTab.id)
        if (newTab && Object.keys(newTab).length && !checkTab) {
            const newOrderTabs = [...savedOrderTabs, newTab]
            dispatch(updateOrderTabs(newOrderTabs))
            dispatch(callUpdateOrderTabsProperties({ orderTabs: newOrderTabs, assetDriveSite }))
        }
    }

    useEffect(() => {
        // checkWidgetsShown()
        let widgets = ListOfWidgets.filter((widget) => {
            let showWidget = true
            const layoutDisplay = tabLayout?.[widget.name]
            if (layoutDisplay === undefined) showWidget = true
            if (layoutDisplay) showWidget = false
            return showWidget
        })
        // now we filter it by the list of widgets we want to hide (keeping them separate
        // to be more explicit on the steps to get the final list
        widgets = widgets.filter((w) => WidgetsToHide.indexOf(w.name) === -1)
        setWidgetList(widgets)
    }, [tabLayout])

    return (
        <Popup
            trapFocus
            position="below"
            align="end"
            trigger={
                <Button type="button" primary flat>
                    <FiPlus className="mr-1" />
                    <span className="uppercase font-semibold text-xs">Add widget</span>
                </Button>
            }
            content={
                <>
                    {widgetList.length > 0 &&
                        widgetList.map((widget) => (
                            <div
                                role="presentation"
                                className="p-4 cursor-pointer"
                                key={widget.name}
                                onClick={() => addWidget(widget)}
                                onKeyDown={() => addWidget(widget)}
                            >
                                {widget.title}
                            </div>
                        ))}
                    {!widgetList?.length && <div>No more widgets are available</div>}
                </>
            }
        />
    )
}

export default AddWidget
