/* eslint-disable indent */
import getMsGraphService from './base/ms-graph.service'
import { MAIL_FOLDER_ORDER } from '../common/constants'

class EmailService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    async getUserEmails(isFilter, selectedFolder, inferenceClassification) {
        let messages = null
        let moreAvailable = false
        let filter = ''
        // eslint-disable-next-line no-nested-ternary, no-constant-condition
        switch (isFilter) {
            case 'Unread':
                filter = 'isRead eq false'
                break
            case 'Read':
                filter = 'isRead eq true'
                break
            default:
                filter = ''
                break
        }
        if (inferenceClassification) {
            filter = `${filter}${
                filter ? ' and ' : ''
            }inferenceClassification eq '${inferenceClassification}'`
        }
        const folderId = selectedFolder || 'Inbox'
        const options = {
            url: `/me/mailFolders/${folderId}/messages`,
            selectStatement:
                'isRead,sender,subject,receivedDateTime,bodyPreview,webLink,flag,parentFolderId',
            filter,
            topValue: 500
        }
        options.orderBy = 'receivedDateTime DESC'
        if (inferenceClassification) {
            options.orderBy = `inferenceClassification DESC,${options.orderBy}`
        }

        const messagePage = await this.msGraphService.callGetApi(options)
        messages = messagePage?.value.map((item) => ({
            id: item.id,
            subject: item?.subject,
            isRead: item?.isRead,
            name: item?.sender?.emailAddress?.name,
            receiveTime: item?.receivedDateTime,
            bodyPreview: item?.bodyPreview,
            webLink: item?.webLink,
            flag: item?.flag,
            parentFolderId: item?.parentFolderId
        }))
        moreAvailable = messagePage['@odata.nextLink'] !== undefined
        return { messages, moreAvailable }
    }

    async updateMailStatus(data) {
        const url = `/me/mailFolders/Inbox/messages/${data.id}`
        const payload = { isRead: !data.isRead }
        const result = await this.msGraphService.callUpdateApi(url, payload)
        return result
    }

    async getMailFolders() {
        const resp = await this.msGraphService.callGetApi({
            url: '/me/mailFolders?includeHiddenFolders=true'
        })
        const folders = resp?.value || []
        // first we get the folders we want to put at the top of the list and filter out the rest
        const weightedFolders = folders.filter((f) => MAIL_FOLDER_ORDER.indexOf(f.displayName) > -1)
        const otherFolders = folders.filter((f) => MAIL_FOLDER_ORDER.indexOf(f.displayName) === -1)
        weightedFolders.sort((a, b) => {
            const idxA = MAIL_FOLDER_ORDER.indexOf(a.displayName)
            const idxB = MAIL_FOLDER_ORDER.indexOf(b.displayName)
            return idxA < idxB ? -1 : 1
        })

        return [...weightedFolders, ...otherFolders]
    }

    async deleteMail(id) {
        const url = `me/messages/${id}`
        await this.msGraphService.callDeleteApi(url)
    }

    async setMailFlag(id, flagData) {
        const url = `/me/messages/${id}`
        const payload = {
            flag: {
                completedDateTime: flagData?.completedDateTime || null,
                dueDateTime: flagData?.dueDateTime || null,
                flagStatus: flagData?.flagStatus || null,
                startDateTime: flagData?.startDateTimess || null
            }
        }
        await this.msGraphService.callUpdateApi(url, payload)
    }

    async setAchiveMail(id, payload) {
        const url = `/me/messages/${id}/move`
        await this.msGraphService.callPostApi(url, payload)
    }
}

export default EmailService
