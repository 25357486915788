import { File } from '@microsoft/mgt-react'
import PropTypes from 'prop-types'
import '../../scss/mgt/File.scss'

const FileThumbnailList = ({ fileList }) => (
    <div>
        {fileList.map((file, i) => (
            <File fileDetails={file} key={i} />
        ))}
    </div>
)

FileThumbnailList.propTypes = {
    fileList: PropTypes.instanceOf(Array).isRequired
}

export default FileThumbnailList
