import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { Button } from '@fluentui/react-northstar'
import { CallVideoIcon } from '@fluentui/react-icons-northstar'
import FormDialog from '../hr-forms/FormDialog'
import { autoLinkText } from '../../common/html-helpers'
import { goToUrl } from '../../common/link-helpers'

const DetailEvent = ({ event, handleClose, open }) => {
    const capitalize = (inputStr) => {
        if (!inputStr) return ''
        return inputStr.charAt(0).toUpperCase() + inputStr.slice(1)
    }
    let meetingUrl = ''
    if (event?.isOnlineMeeting) {
        meetingUrl = event?.onlineMeeting ? event?.onlineMeeting.joinUrl : event?.onlineMeetingUrl
    }
    const formatString = (inputDate, inputStr) => `${inputDate} - ${inputStr}`

    const joinMeeting = goToUrl

    return (
        <FormDialog
            headerText={event?.title || 'Event'}
            content={
                <div className="grid grid-cols-12 gap-6 mt-4">
                    <div className="col-span-4">
                        <div className="uppercase text-xs font-semibold text-ms-purple">Start</div>
                        <div className="text-black">
                            {formatString(
                                event?.startDateAndTime?.date || '',
                                event?.startDateAndTime?.time || ''
                            )}
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="uppercase text-xs font-semibold text-ms-purple">End</div>
                        <div className="text-black">
                            {formatString(
                                event?.endDateAndTime?.date || '',
                                event?.endDateAndTime?.time || ''
                            )}
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className="uppercase text-xs font-semibold text-ms-purple">
                            All Day
                        </div>
                        <div className="text-black">{event?.allDay ? 'Yes' : 'No'}</div>
                    </div>
                    <div className="col-span-4">
                        <div className="uppercase text-xs font-semibold text-ms-purple">
                            Location
                        </div>
                        <div
                            className="text-black break-words"
                            dangerouslySetInnerHTML={{
                                /* eslint react/no-danger: "off" */
                                __html: DOMPurify.sanitize(autoLinkText(event?.location))
                            }}
                        />
                    </div>
                    <div className="col-span-4">
                        <div className="uppercase text-xs font-semibold text-ms-purple">
                            Importance
                        </div>
                        <div className="text-black">{capitalize(event?.importance)}</div>
                    </div>
                    <div className="col-span-4">
                        {meetingUrl && (
                            <div className="text-black">
                                <Button
                                    icon={<CallVideoIcon />}
                                    content="Join call"
                                    iconPosition="before"
                                    primary
                                    onClick={() => joinMeeting(meetingUrl)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            }
            open={open}
            onCancel={handleClose}
        />
    )
}

DetailEvent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    event: PropTypes.instanceOf(Object),
    open: PropTypes.bool.isRequired
}

DetailEvent.defaultProps = {
    event: null
}

export default DetailEvent
