import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GroupsService } from '../../services/groups.service'

const groupsService = new GroupsService()

export const fetchGroups = createAsyncThunk('groups/fetchGroups', async () => {
    const { groupsList } = await groupsService.getGroups()
    /* eslint no-console: "off" */
    console.debug('========= groups found for my orgs planners ', groupsList)
    return groupsList
})

const initialState = {
    groupsList: []
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        updateGroups: (state, action) => {
            state.groupsList = [...action.payload]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGroups.fulfilled, (state, action) => {
            state.groupsList = [...action.payload]
        })
    }
})

export const { updateGroups } = groupsSlice.actions

export const getGroups = (state) => state.groups.groupsList

export default groupsSlice.reducer
