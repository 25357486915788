/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react'
import { RiCheckboxBlankCircleFill, RiCheckboxBlankCircleLine } from 'react-icons/ri'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Swipeout from 'rc-swipeout'
import InboxActions from './InboxActions'
import EmailService from '../../services/email.service'
import ContextMenu from './ContextMenu'
import { updateSelectedMail, getArchiveFolder } from '../../features/user-inbox/mailSlice'
import 'rc-swipeout/assets/index.css'
import { goToUrl } from '../../common/link-helpers'

const Inbox = ({ data, changeData, clicked, setClicked, points, setPoints }) => {
    const emailService = new EmailService()
    const [loading, setLoading] = useState(false)
    const [animation, setAnimation] = useState(false)
    const mailRef = useRef()
    const dispatch = useDispatch()
    const archiveFolder = useSelector(getArchiveFolder)

    const handleOpenEmail = (url) => {
        if (url) goToUrl(`${url}&ispopout=0`)
    }

    const handleUpdatedMail = (mail) => {
        setLoading(true)
        setTimeout(async () => {
            try {
                const result = await emailService.updateMailStatus(mail)

                if (Object.keys(result || {}).length) changeData(result)
            } catch (error) {
                /* eslint no-console: "off" */
                console.log(error)
            } finally {
                setLoading(false)
            }
        }, 1000)
    }

    const handleContextMenu = async (e) => {
        e.preventDefault()
        if (mailRef.current?.contains(e.target)) {
            setClicked(true)
            setPoints({
                x: e.pageX,
                y: e.pageY
            })
            dispatch(updateSelectedMail({ selectedMail: data }))
        }
    }

    const handleArchiveSwipe = async (e) => {
        e.preventDefault()
        try {
            await emailService.setAchiveMail(data?.id, { destinationId: archiveFolder?.id })
            setAnimation(true)
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Set Archive Mail:', error)
        }
    }

    useEffect(() => {
        window.addEventListener('touchstart', (event) => {
            event.preventDefault()
        })
        window.addEventListener('touchmove', (event) => {
            event.preventDefault()
        })
    }, [])

    return (
        <>
            <Swipeout
                right={[
                    {
                        text: 'Archive',
                        onPress: (e) => handleArchiveSwipe(e),
                        style: { backgroundColor: '#2b87e1', color: 'white' },
                        className: 'custom-class-2'
                    }
                ]}
                onOpen={() => console.log('open')}
                onClose={() => console.log('close')}
                disabled={archiveFolder.id === data.parentFolderId}
            >
                <div
                    key={`wrapper-key-${data.id}`}
                    className={`w-full pl-6 relative ${animation ? 'message' : ''}`}
                    ref={mailRef}
                >
                    <div className="w-4 absolute left-1 top-0">
                        {loading ? (
                            <CircularProgress size={12} />
                        ) : data.isRead ? (
                            <RiCheckboxBlankCircleLine
                                className="text-sm  text-slate-400"
                                onClick={() => {
                                    handleUpdatedMail({ id: data.id, isRead: data.isRead })
                                }}
                            />
                        ) : (
                            <RiCheckboxBlankCircleFill
                                className="text-sm text-ms-blue"
                                onClick={() => {
                                    handleUpdatedMail({ id: data.id, isRead: data.isRead })
                                }}
                            />
                        )}
                    </div>
                    <div className="absolute right-0 top-0 text-grey-3 text-xxs text-base">
                        <InboxActions
                            key={`inbox-actions-key-${data.id}`}
                            id={data.id}
                            reload={changeData}
                            mailData={data}
                            setAnimation={setAnimation}
                        />
                    </div>

                    <div
                        onClick={() => {
                            handleOpenEmail(data.webLink)
                        }}
                        onContextMenu={handleContextMenu}
                    >
                        <div
                            className={`text-xxs font-semibold uppercase pr-10 ${
                                data.isRead ? 'text-grey-3' : 'text-primary'
                            }`}
                        >
                            {data.name}
                        </div>
                        <div className="flex gap-x-3 justify-between items-center">
                            <div
                                className={`text-xs font-semibold ${
                                    data.isRead ? 'text-grey-2' : 'text-ms-blue'
                                }`}
                            >
                                {data.subject}
                            </div>
                            <div className="text-right text-grey-3 text-xxs font-semibold">
                                {data.time}
                            </div>
                        </div>
                        <div
                            className="text-xxs text-grey-3 break-words"
                            dangerouslySetInnerHTML={{
                                /* eslint react/no-danger: "off" */
                                __html: DOMPurify.sanitize(`${data.bodyPreview.slice(0, 80)}...`)
                            }}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        />
                    </div>
                </div>
            </Swipeout>
            {clicked && (
                <ContextMenu
                    top={points.y}
                    left={points.x}
                    mailData={data}
                    reload={changeData}
                    setAnimation={setAnimation}
                />
            )}
        </>
    )
}

Inbox.propTypes = {
    data: PropTypes.instanceOf(Object).isRequired,
    changeData: PropTypes.func.isRequired,
    clicked: PropTypes.bool.isRequired,
    setClicked: PropTypes.func.isRequired,
    points: PropTypes.instanceOf(Object).isRequired,
    setPoints: PropTypes.func.isRequired
}

export { Inbox }
