/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamsFx } from '@microsoft/teamsfx'
import { Button } from '@fluentui/react-northstar'
import { Providers, ProviderState } from '@microsoft/mgt-element'
import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider'
import { CacheService } from '@microsoft/mgt'
import { app } from '@microsoft/teams-js'
import { FiGrid, FiX, FiSettings } from 'react-icons/fi'
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
    PageLayoutPropertiesKey,
    scopes,
    OrderTabsPropertiesKey,
    PARENT_PAGE_TO_IGNORE,
    PARENT_SITE_KEYWORD,
    OUR_COMPANY_SUB_DOMAIN,
    PARENT_SITE_SUB_KEY,
    SubLogoPropertiesKey
} from '../common/constants'
import '../scss/components/Tab.scss'
import { orderTabs } from '../common/orderTabs'
import LogoBanner from './logo-banner/LogoBanner'
import GlobalSearch from './global-search/GlobalSearch'
import {
    updateSharePointSite,
    updateSharePointSiteId,
    updateSiteAssetDrive,
    updateSharePointSiteUrl,
    updateSubSites,
    updateSharePointSiteEventsList,
    updateEventsParentSite
} from '../features/business-partners/businessPartnersSlice'
import { CompanyNewsService } from '../services/company-news.service'
import { PagePropertiesService } from '../services/page-properties.service'
import {
    getEditMode,
    getTabLayout,
    updateEditMode,
    updateTabLayout,
    getMovingTabStatus,
    callUpdateOrderTabsProperties,
    updateOrderTabs,
    getOrderTabs
} from '../features/tab-layout/tabLayoutSlice'
import AddWidget from './AddWidget'
import ResetWidgets from './ResetWidgets'
import CustomTab from './CustomTab'
import Articles from './articles/Articles'
import Values from './values/Values'
import ClientSuccesses from './client-successes/ClientSuccesses'
import { fetchGroups } from '../features/groups/groupsSlice'
import { SiteService } from '../services/site.service'
import { updatebase64Img } from '../features/sub-logo/subLogo.slice'
import { listsService } from '../services/lists.service'

const Tab = () => {
    const teamsFx = new TeamsFx()
    const companyNewsService = new CompanyNewsService()
    const pagePropertiesService = new PagePropertiesService()
    const siteService = new SiteService()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tabLayout = useSelector(getTabLayout)
    const isInEditMode = useSelector(getEditMode)
    const isMovedTab = useSelector(getMovingTabStatus)
    const savedOrderTabs = useSelector(getOrderTabs)
    const [eventsParentSite, setEventsParentSite] = useState({})
    const [tabs, setTabs] = useState(orderTabs)
    const [showLoginPage, updateShowLoginPage] = useState(undefined)
    const [assetDriveSite, setAssetDriveSite] = useState(undefined)
    const [sharepointSiteId, setSharePointSiteId] = useState(undefined)
    const [sharePointSite, setSharePointSite] = useState(undefined)
    const [ourCompanyLink, setOurCompanyLink] = useState('')
    const [subLogo64, setSubLogo64] = useState(undefined)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8
            },
            isDraggableElement: () => false
        })
    )

    const showTile = (tileTitle) => tabLayout?.[tileTitle] !== false

    const getEventsList = async () => {
        // now we get the lists for the company site
        const { siteLists } = await listsService.getSiteLists(eventsParentSite.id)
        const eventsLists = siteLists?.value?.filter(
            (sl) => sl?.list?.template?.toLowerCase() === 'events'
        )
        /* eslint no-console: "off" */
        console.warn('======= events found', eventsLists)
        dispatch(updateSharePointSiteEventsList([...(eventsLists || [])]))
    }
    const getSharepointSite = async () => {
        const context = await app.getContext()
        const teamSiteDomain = context?.sharePointSite?.teamSiteDomain || ''
        setSharePointSite(teamSiteDomain)
        dispatch(updateSharePointSite(teamSiteDomain))
        // now we get the actual communications site
        let { site } = await companyNewsService.getSite(teamSiteDomain)
        if (site?.displayName?.toLowerCase().indexOf(PARENT_PAGE_TO_IGNORE) > -1) {
            const { sites } = await companyNewsService.getSiteByKeyword(PARENT_SITE_KEYWORD)
            const newSites = sites.filter(
                (s) =>
                    /* eslint implicit-arrow-linebreak: "off" */
                    s.displayName.toLowerCase().indexOf(PARENT_PAGE_TO_IGNORE) === -1 &&
                    s.displayName.toLowerCase().indexOf(PARENT_SITE_SUB_KEY) > -1
            )
            // if there is more than one, let's go with the first one for now
            if (newSites?.length) site = { ...newSites.at(0) }
        } else {
            // otherwise we get it based on the subsite key for now, which is 'communications'
            const { sites } = await companyNewsService.getSiteByKeyword(PARENT_SITE_SUB_KEY)
            if (sites?.length) site = { ...sites.at(0) }
        }
        // now let's get the sub-sites
        const { sites: subSites } = await companyNewsService.getSubSites(site.id)

        dispatch(updateSubSites([...subSites]))
        dispatch(updateSharePointSiteId(site.id))
        dispatch(updateSharePointSiteUrl(site.webUrl))
        setSharePointSiteId(site.id)
        // }
        // dispatch(updateSharePointSiteUrl(site.webUrl))
        setAssetDriveSite(teamSiteDomain)
    }

    const getOurCompanySite = async () => {
        const { sites } = await companyNewsService.getSiteByKeyword(OUR_COMPANY_SUB_DOMAIN)
        /* eslint no-console: "off" */
        console.warn('====== company site keyword', OUR_COMPANY_SUB_DOMAIN)
        const ourCompanySite = sites.filter(
            (s) => s.displayName.toLowerCase().indexOf(OUR_COMPANY_SUB_DOMAIN) === -1
        )
        /* eslint no-console: "off" */
        console.warn('====== company site', ourCompanySite)
        if (ourCompanySite?.length) {
            setOurCompanyLink(ourCompanySite?.at(0)?.webUrl)
            // TODO: move this to a setting on the settings page
            dispatch(updateEventsParentSite({ ...ourCompanySite?.at(0) }))
            setEventsParentSite({ ...(ourCompanySite?.at(0) || {}) })
        }
    }

    const getSharepointAssetDrive = async () => {
        if (!sharepointSiteId) return
        const { drive } = await companyNewsService.getAssetDrive(sharepointSiteId)
        dispatch(updateSiteAssetDrive(drive))
    }

    const getProperties = async () => {
        if (!assetDriveSite) return
        const properties = await pagePropertiesService.getProperties(
            PageLayoutPropertiesKey,
            assetDriveSite
        )
        if (properties) dispatch(updateTabLayout(properties))
    }

    const getSavedOrderTabs = async () => {
        if (!assetDriveSite) return
        const orderAllTabs = await pagePropertiesService.getProperties(
            OrderTabsPropertiesKey,
            assetDriveSite
        )
        if (orderAllTabs?.length) {
            // remove duplicated elements in array mapping tab
            const clearOrderTabs = [
                ...new Map(orderAllTabs.map((item) => [item.id, item])).values()
            ]
            dispatch(updateOrderTabs(clearOrderTabs))
        } else {
            const initialOrderTabs = orderTabs.map((tab) => ({
                id: tab.id,
                title: tab.title
            }))
            if (savedOrderTabs.length === 0) {
                dispatch(
                    callUpdateOrderTabsProperties({ orderTabs: initialOrderTabs, assetDriveSite })
                )
                dispatch(updateOrderTabs(initialOrderTabs))
                setTabs(orderTabs)
            }
        }
    }

    const getCommonSetting = async () => {
        try {
            const rootSite = await siteService.getSite()
            const subLogo = await pagePropertiesService.getProperties(
                SubLogoPropertiesKey,
                sharePointSite,
                rootSite.id
            )
            setSubLogo64(subLogo || '')
            dispatch(updatebase64Img(subLogo))
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('error get common', error)
        }
    }

    const configureComponent = useCallback(async () => {
        CacheService.clearCaches()
        /* Initialize TeamsFX provider */
        Providers.globalProvider = new TeamsFxProvider(teamsFx, scopes)
        // const sharePointProvider = new SharePointProvider(this.context)
        // sharePointProvider.updateScopes(this.scopes)
        // Providers.globalProvider = sharePointProvider

        /* Check if consent is needed */
        let consentNeeded = false
        try {
            await teamsFx.getCredential().getToken(scopes)
            // // const anotherTest = await credential.getToken('')
            // const tokenFound = await teamsFx.getCredential().getToken('')
            // console.log('============ we found the accessToken', tokenFound)
            // // await Providers.globalProvider.getAccessToken()
            await getSharepointSite()
            dispatch(fetchGroups())
            await getOurCompanySite()
        } catch (error) {
            consentNeeded = true
        }
        updateShowLoginPage(consentNeeded)
        Providers.globalProvider.setState(
            consentNeeded ? ProviderState.SignedOut : ProviderState.SignedIn
        )
    }, [])

    useEffect(() => {
        if (eventsParentSite?.id) getEventsList().catch(console.error)
    }, [eventsParentSite])

    useEffect(() => {
        configureComponent().catch(console.error)
    }, [configureComponent])

    useEffect(() => {
        getProperties().catch(console.error)
        getSavedOrderTabs().catch(console.error)
    }, [assetDriveSite])

    useEffect(() => {
        getSharepointAssetDrive().catch(console.error)
        getCommonSetting().catch(console.error)
    }, [sharepointSiteId])

    useEffect(() => {
        const mappingTabs = savedOrderTabs
            // eslint-disable-next-line arrow-body-style, array-callback-return
            .map((savedOrderTab) => {
                const element = orderTabs.filter((e) => e.id === savedOrderTab.id)[0]
                if (element && Object.keys(element).length) return element
            })
            .filter((savedOrderTab) => !!savedOrderTab)
        setTabs(mappingTabs)
    }, [savedOrderTabs])

    const tabView = tabs.map(
        (tab, idx) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            showTile(tab?.title) && (
                <CustomTab
                    tab={tab}
                    isInEditMode={isInEditMode}
                    isMovedTab={isMovedTab}
                    key={`tab-order-${idx}`}
                />
            )
    )

    const loginBtnClick = async () => {
        try {
            await teamsFx.login(scopes)
            Providers.globalProvider.setState(ProviderState.SignedIn)
            updateShowLoginPage(false)
            await getSharepointSite()
            dispatch(fetchGroups())
        } catch (err) {
            if (err.message?.includes('CancelledByUser')) {
                const helpLink = 'https://aka.ms/teamsfx-auth-code-flow'
                err.message +=
                    '\nIf you see "AADSTS50011: The reply URL specified in the request does not match the reply URLs configured for the application" ' +
                    'in the popup window, you may be using unmatched version for TeamsFx SDK (version >= 0.5.0) and Teams Toolkit (version < 3.3.0) or ' +
                    `cli (version < 0.11.0). Please refer to the help link for how to fix the issue: ${helpLink}`
            }
            /* eslint no-alert: "off" */
            alert(`Login failed: ${err}`)
        }
    }

    const onEditClick = () => {
        dispatch(updateEditMode({ editMode: !isInEditMode }))
    }

    const settingsClickHandler = () => {
        navigate('/settings')
    }

    const handleDragEnd = (event) => {
        const { active, over } = event
        if (active.id !== over.id && isInEditMode) {
            setTabs((items) => {
                const oldIndex = items.findIndex((e) => e.id === active.id)
                const newIndex = items.findIndex((e) => e.id === over.id)
                const reOrderTabs = arrayMove(items, oldIndex, newIndex)
                const mappingOrderTabs = reOrderTabs?.map((tab) => ({
                    id: tab.id,
                    title: tab.title
                }))
                dispatch(updateOrderTabs(mappingOrderTabs))
                dispatch(
                    callUpdateOrderTabsProperties({ orderTabs: mappingOrderTabs, assetDriveSite })
                )
                return reOrderTabs
            })
        }
    }

    return (
        <div className="block">
            {showLoginPage === false && (
                <div className="block">
                    {tabs.length ? (
                        <div className="w-full p-6 md:p-4 bg-off-white relative fixed-header-height">
                            <div className="w-full flex items-center" id="fixed-header">
                                <div className="w-full mx-auto max-w-full xl:max-w-screen-xl flex flex-row items-center">
                                    <div className="bg-white rounded mr-4">
                                        {subLogo64 && (
                                            <img
                                                alt="Davidson"
                                                src={subLogo64}
                                                className="header-logo py-2"
                                            />
                                        )}
                                    </div>
                                    <div className="bg-white rounded relative flex-grow">
                                        <GlobalSearch />
                                    </div>
                                    {!!ourCompanyLink && (
                                        <div className="bg-white rounded pl-2 pr-1 mx-4 mobileHide">
                                            <a
                                                className="uppercase text-primary text-xs font-bold"
                                                href={ourCompanyLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Intranet
                                            </a>
                                        </div>
                                    )}
                                    <div className="bg-white rounded pl-2 pr-1 mr-4 mobileHide">
                                        <LogoBanner />
                                    </div>
                                    <div className="bg-white rounded px-1 h-8 w-8 flex items-center tabletHide">
                                        <div
                                            className="m-auto cursor-pointer text-grey-3 hover:text-ms-purple"
                                            onClick={settingsClickHandler}
                                        >
                                            <FiSettings />
                                        </div>
                                    </div>
                                    <div className="bg-white rounded px-1 h-8 w-8 flex items-center tabletHide">
                                        <div
                                            role="presentation"
                                            className="m-auto cursor-pointer text-grey-3 hover:text-ms-purple"
                                            onClick={onEditClick}
                                            onKeyDown={onEditClick}
                                        >
                                            {isInEditMode ? <FiX /> : <FiGrid />}
                                        </div>
                                    </div>
                                </div>
                                <div className="mobileShow">
                                    <div className="flex items-center mb-2">
                                        {!!ourCompanyLink && (
                                            <div className="bg-white rounded pl-2 pr-1 mx-4">
                                                <a
                                                    className="uppercase text-primary text-xs font-bold"
                                                    href={ourCompanyLink}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Intranet
                                                </a>
                                            </div>
                                        )}
                                        <div className="bg-white rounded pl-2 pr-1 mr-4">
                                            <LogoBanner />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full m-auto max-w-full xl:max-w-screen-xl">
                                <div
                                    className={`w-full grid grid-cols-12 auto-rows-auto xl:grid-rows-2 gap-4 slidersArea ${
                                        isInEditMode ? 'editMode' : ''
                                    }`}
                                >
                                    {isInEditMode && (
                                        <div className="absolute z-10" id="editModeMsg">
                                            <div className="bg-black text-white text-lg rounded p-6">
                                                <div className="flex items-center mb-4">
                                                    <FiGrid className="mr-2 w-20" />
                                                    <div>
                                                        You are currently in widget edit mode. To
                                                        exit click
                                                        <span className="px-1 text-xs font-semibold text-grey-3">
                                                            X
                                                        </span>
                                                        in the top right.
                                                    </div>
                                                </div>
                                                <div className="flex justify-center items-center">
                                                    <ResetWidgets />
                                                    <AddWidget />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-span-12 lg:col-span-12 xl:col-span-8 xl:row-span-2">
                                        <Articles />
                                    </div>

                                    <div className="col-span-6 lg:col-span-6 xl:col-span-4 xl:row-span-1 mobileHide">
                                        <ClientSuccesses />
                                    </div>

                                    <div className="col-span-6 lg:col-span-6 xl:col-span-4 xl:row-span-1 mobileHide">
                                        <Values />
                                    </div>
                                </div>
                                <div
                                    id="widgetGrid"
                                    className={`w-full grid grid-flow-row auto-cols-max grid-cols-12 gap-4 widgetGrid ${
                                        isInEditMode ? 'editMode' : ''
                                    }`}
                                >
                                    <DndContext
                                        sensors={sensors}
                                        collisionDetection={closestCenter}
                                        onDragEnd={handleDragEnd}
                                        modifiers={[restrictToParentElement]}
                                    >
                                        <SortableContext
                                            strategy={rectSortingStrategy}
                                            items={tabs}
                                        >
                                            {tabView}
                                        </SortableContext>
                                    </DndContext>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="h-full flex justify-center items-center col-span-12">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            )}
            {showLoginPage === true && (
                <div className="auth">
                    <h3>Welcome to The Hub app!</h3>
                    <p>
                        Please click on &quot;Start using The Hub&quot; and consent permissions to
                        use the app.
                    </p>
                    <Button primary onClick={loginBtnClick}>
                        Start using The Hub
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Tab
