import React from 'react'
import SettingForm from './setting-form/SettingForm'

const Setting = () => (
    <div className="grid grid-cols-12 my-4 px-4">
        <div className="col-span-6">
            <SettingForm />
        </div>
    </div>
)

export default Setting
