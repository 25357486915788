import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { OrderTabsPropertiesKey, WidgetsToHide } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()
const initialState = {
    editMode: false,
    layouts: {},
    isMovingTab: false,
    orderTabs: []
}

export const callUpdateOrderTabsProperties = createAsyncThunk(
    'orderTabs/callUpdateOrderTabsProperties',
    /* eslint no-unused-vars: "off" */
    async ({ orderTabs, assetDriveSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            OrderTabsPropertiesKey,
            orderTabs,
            assetDriveSite
        )
        return response
    }
)

export const tabLayoutSlice = createSlice({
    name: 'tabLayout',
    initialState,
    reducers: {
        updateEditMode: (state, action) => {
            state.editMode = action.payload.editMode
        },
        updateTabLayout: (state, action) => {
            const newLayout = { ...state.layouts }
            /* eslint-disable no-param-reassign */
            Object.entries(action.payload).forEach(([key, value]) => {
                newLayout[key] = value
            })
            state.layouts = { ...newLayout }
        },
        updateTabLayoutByKey: (state, action) => {
            /* eslint-disable no-param-reassign */
            const layout = { ...state.layouts }
            layout[action.payload.name] = action.payload.display
            state.layouts = { ...layout }
        },
        callUpdateProperties: (state, action) => {
            const layouts = { ...state.layouts }
            pagePropertiesService.setProperties(
                'pageLayout',
                layouts,
                action.payload.sharePointSite
            )
        },
        replaceTabsOrder: (state, action) => {
            state.isMovingTab = action.payload
        },
        updateOrderTabs: (state, action) => {
            if (!action.payload?.length) return
            state.orderTabs = [...action.payload]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateOrderTabsProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log('============= finished updating order Tabs Properties ', state, action)
        })
    }
})

export const {
    updateTabLayout,
    updateTabLayoutByKey,
    updateEditMode,
    callUpdateProperties,
    replaceTabsOrder,
    updateOrderTabs
} = tabLayoutSlice.actions

export const getTabLayout = (state) => state.tabLayout.layouts
export const getEditMode = (state) => state.tabLayout.editMode
export const getMovingTabStatus = (state) => state.tabLayout.isMovingTab
export const getOrderTabs = (state) => {
    // return state.tabLayout.orderTabs
    // TODO: once we have finished the widgets, we can remove this filter
    // OR you can just remove them from the 'WidgetsToHide' list
    const listOfWidgets = state.tabLayout.orderTabs.filter(
        (ot) => WidgetsToHide.indexOf(ot.title) === -1
    )
    return listOfWidgets
}

export default tabLayoutSlice.reducer
