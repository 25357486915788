import React from 'react'
import { RiEdit2Line } from 'react-icons/ri'
// import ExpenseClaimForm from './ExpenseClaimForm'
// import LeaveForm from './LeaveForm'
import TileLayout from '../basic/TileLayout'
import icon from '../../assets/images/forms-coming-soon.png'

const HrForms = () => (
    <TileLayout title="Forms" icon={<RiEdit2Line className="text-primary text-lg" />}>
        <div className="flex flex-col items-center my-4">
            <img alt="Leave Request Form" src={icon} className="w-2/3 block h-auto mb-4" />
            <div className="px-4 text-center">
                <div className="text-primary text-lg font-bold">Forms widget coming soon</div>
                <div className="text-primary">
                    HR Forms such as annual leave and expense claim forms are coming soon!
                </div>
            </div>
            {/*
            <div className="hover:opacity-50 cursor-pointer mr-3">
                <LeaveForm />
            </div>
            <div className="hover:opacity-50 cursor-pointer">
                <ExpenseClaimForm />
            </div>
            */}
        </div>
    </TileLayout>
)

export default HrForms
