import React, { useEffect, useState } from 'react'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enAU from 'date-fns/locale/en-AU'
import { formatInTimeZone, toDate } from 'date-fns-tz'
import { isValid, setHours, startOfDay } from 'date-fns'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import CustomCalendarToolBar from './CustomCalendarToolbar'
import CustomAgendaView from './calendar/CustomAgendaView'
import CalendarService from '../services/calendar.service'
import DetailEvent from './calendar/DetailEvent'
import '../scss/components/CalendarBox.scss'
import { dateTimeZoneMapping, CalendarPropertiesKey, CALENDAR_GROUPS } from '../common/constants'
import {
    callUpdateCalendarStatusProperties,
    updateCalendarView,
    getCurrentView,
    getCalendarType
} from '../features/calendar/calendarSlice'

import {
    selectSharePointSite,
    sharepointSiteEventsLists,
    selectEventsParentSite
} from '../features/business-partners/businessPartnersSlice'
import { PagePropertiesService } from '../services/page-properties.service'
import { listsService } from '../services/lists.service'

const locales = {
    'en-AU': enAU
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
})

const CalendarBox = () => {
    const calendarService = new CalendarService()
    const pagePropertiesService = new PagePropertiesService()
    const [events, setEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [open, setOpen] = useState(false)
    const [timeFilter, setTimeFilter] = useState(new Date())
    const startOfDayDate = setHours(startOfDay(new Date()), 9)
    const dispatch = useDispatch()
    const sharePointSite = useSelector(selectSharePointSite)
    const selectedView = useSelector(getCurrentView)
    const eventsList = useSelector(sharepointSiteEventsLists)
    const calendarType = useSelector(getCalendarType)
    const eventsParentSite = useSelector(selectEventsParentSite)
    const [isLoading, setIsLoading] = useState(true)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const formatDate = (date, timeZone) => {
        let newTimeZone = timeZone
        if (!isValid(date)) return {}
        if (timeZone in dateTimeZoneMapping) {
            newTimeZone = dateTimeZoneMapping[timeZone]
        } else {
            newTimeZone =
                Intl.DateTimeFormat().resolvedOptions().timeZone || dateTimeZoneMapping.default
        }
        const datePart = formatInTimeZone(date, newTimeZone, 'dd/MM/yyyy')
        const timePart = formatInTimeZone(date, newTimeZone, 'p')
        return { date: datePart, time: timePart }
    }
    const convertEventCalendar = (evts) => {
        // TODO: get the correct start and end date from the calendar and then filter the events

        const timezone =
            Intl.DateTimeFormat().resolvedOptions().timeZone || dateTimeZoneMapping.default
        const data = evts.map((event) => ({
            id: event?.fields.id,
            start: toDate(event?.fields.EventDate, { timeZone: timezone }),
            startTimeZone: timezone,
            end: toDate(event?.fields.EndDate, { timeZone: timezone }),
            endTimeZone: timezone,
            title: event?.fields.Title,
            allDay: event?.fields.fAllDayEvent,
            importance: '',
            geo: event.fields.Geolocation,
            location: event.fields.Geolocation || '',
            isOnlineMeeting: !!event?.fields.Location,
            onlineMeetingUrl: event?.fields.Location,
            description: event.fields.Description,
            type: 'event',
            group: CALENDAR_GROUPS.Events
        }))
        return data
    }

    const fetchData = async () => {
        const { calendar } = await calendarService.getCalendar(timeFilter)
        const eventsContainer = eventsList?.at(0)
        const { listItems } = await listsService.getListItems(
            eventsParentSite?.id,
            eventsContainer.id
        )
        const eventCalendar = convertEventCalendar(listItems)
        if (calendarType === CALENDAR_GROUPS.AllCalendars || calendarType === '') {
            setEvents([...calendar, ...eventCalendar])
        } else if (calendarType === CALENDAR_GROUPS.Events) {
            setEvents([...eventCalendar])
        } else {
            setEvents([...calendar])
        }

        const calendarFilter = await pagePropertiesService.getProperties(
            CalendarPropertiesKey,
            sharePointSite
        )
        if (calendarFilter && Object.keys(calendarFilter).length > 0) {
            dispatch(updateCalendarView(calendarFilter))
        } else {
            dispatch(updateCalendarView('month'))
        }
    }

    useEffect(() => {
        if (!eventsParentSite || !eventsList.length) return
        fetchData()
            // eslint-disable-next-line no-console
            .catch(console.error)
            .finally(() => {
                setIsLoading(false)
            })
    }, [timeFilter, calendarType, eventsParentSite, eventsList])

    const handleSelectEvent = (event) => {
        setSelectedEvent({
            ...event,
            startDateAndTime: formatDate(event.start, event.startTimeZone),
            endDateAndTime: formatDate(event.end, event.endTimeZone)
        })
        handleOpen()
    }

    const handleNavigate = (date) => {
        setTimeFilter(new Date(date))
    }

    const handleView = (view) => {
        dispatch(updateCalendarView(view))
        dispatch(callUpdateCalendarStatusProperties({ calendarFilter: view, sharePointSite }))
    }

    const { views } = React.useMemo(
        () => ({
            views: {
                day: true,
                week: true,
                month: true,
                agenda: CustomAgendaView
            }
        }),
        []
    )

    return (
        <div style={{ height: '100%', minHeight: '400px' }}>
            {!isLoading ? (
                <Calendar
                    className="tile-height-500"
                    views={views}
                    // available views from array
                    defaultView={selectedView}
                    // default week view is selected
                    onSelectEvent={handleSelectEvent}
                    events={events}
                    localizer={localizer}
                    showMultiDayTimes
                    scrollToTime={startOfDayDate}
                    onView={handleView}
                    onNavigate={handleNavigate}
                    eventPropGetter={(d) => {
                        const backgroundColor = d?.type !== 'event' ? '#5b5fc7' : '#e33909'
                        return { style: { backgroundColor } }
                    }}
                    components={{
                        // event: EventComponent({ data, change }),
                        // data as events array and change is custom method passed into
                        // component (for perform any functionality on parent state)
                        toolbar: CustomCalendarToolBar
                    }}
                />
            ) : (
                <div className="h-full flex justify-center items-center">
                    <CircularProgress />
                </div>
            )}
            <DetailEvent
                handleClose={handleClose}
                event={selectedEvent}
                open={open}
                showCancel={false}
            />
        </div>
    )
}

export default CalendarBox
