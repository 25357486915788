import React, { useState, useEffect } from 'react'
import { RiInboxLine } from 'react-icons/ri'
import { Dropdown, Menu } from '@fluentui/react-northstar'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import TileLayout from '../basic/TileLayout'
import EmailList from './EmailList'
import EmailService from '../../services/email.service'
import {
    updateArchiveFolder,
    callUpdateMailStatusProperties,
    updateSelectedFilter
} from '../../features/user-inbox/mailSlice'
import '../../scss/components/mail.scss'
import { FOCUSED_MAIL_KEY, OTHER_MAIL_KEY, MailPropertiesKey } from '../../common/constants'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'
import { PagePropertiesService } from '../../services/page-properties.service'

const UserInbox = () => {
    const [filter, setFilter] = useState('')
    const inputItems = ['All', 'Unread', 'Read']
    const [mailFolders, setMailFolders] = useState([])
    const [folderId, setFolderId] = useState('')
    const [folderName, setFolderName] = useState('')
    const [folderData, setFolderData] = useState([])
    const [focusedOrOther, setFocusedOrOther] = useState(FOCUSED_MAIL_KEY)
    const [loading, setLoading] = useState(true)
    const emailService = new EmailService()
    const pagePropertiesService = new PagePropertiesService()
    const dispatch = useDispatch()
    const sharePointSite = useSelector(selectSharePointSite)
    const menuItems = [
        {
            key: 'focused',
            content: 'Focused',
            onClick: () => setFocusedOrOther(FOCUSED_MAIL_KEY)
        },
        {
            key: 'other',
            content: 'Other',
            onClick: () => setFocusedOrOther(OTHER_MAIL_KEY)
        }
    ]
    const updateStateAndProperties = (payload) => {
        dispatch(
            callUpdateMailStatusProperties({
                selectedFilter: payload,
                sharePointSite
            })
        )
        dispatch(updateSelectedFilter(payload))
    }

    const handleFolderId = (fName) => {
        setFolderName(fName)
        if (fName !== 'Inbox') setFocusedOrOther(null)
        else setFocusedOrOther('focused')
        const selectedFolderId = folderData.find((folder) => folder.displayName === fName)
        setFolderId(selectedFolderId.id)
        updateStateAndProperties({
            folderName: fName,
            status: filter,
            folderId: selectedFolderId.id
        })
    }

    const handleFilterStatus = (status) => {
        setFilter(status)
        updateStateAndProperties({ folderName, status, folderId })
    }
    useEffect(() => {
        setTimeout(async () => {
            try {
                const result = await emailService.getMailFolders()
                setFolderData(result)
                // changed to === because Archive displayName can't be changed
                const archiveFolder = result.find((folder) => folder.displayName === 'Archive')
                dispatch(updateArchiveFolder({ archiveFolder }))
                const folderNameList = result.map((folder) => folder.displayName)
                setMailFolders(folderNameList)
                const folderIdDefault = result.find((folder) => folder.displayName === 'Inbox')
                let mailStatus = await pagePropertiesService.getProperties(
                    MailPropertiesKey,
                    sharePointSite
                )
                if (!mailStatus) mailStatus = {}
                if (!mailStatus && !Object.keys(mailStatus).length && folderIdDefault) {
                    const initialData = {
                        folderName: folderIdDefault.displayName,
                        status: 'All',
                        folderId: folderIdDefault.id
                    }
                    dispatch(
                        callUpdateMailStatusProperties({
                            selectedFilter: initialData,
                            sharePointSite
                        })
                    )
                    dispatch(updateSelectedFilter(initialData))
                    setFolderName(folderIdDefault.displayName)
                    setFilter(initialData.status)
                } else {
                    dispatch(updateSelectedFilter(mailStatus))
                    setFolderName(mailStatus.folderName)
                    setFilter(mailStatus.status)
                    setFolderId(mailStatus.folderId)
                }
            } catch (error) {
                /* eslint no-console: "off" */
                console.log(error)
            } finally {
                setLoading(false)
            }
        }, 1000)
    }, [])

    return (
        <TileLayout title="Inbox" icon={<RiInboxLine className="text-primary text-lg" />}>
            <>
                <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center">
                        <Dropdown
                            className="w-100 widgetDropdown invertedDropdown"
                            inverted
                            items={mailFolders}
                            placeholder="Select mail folder"
                            value={folderName}
                            checkable
                            getA11ySelectionMessage={{
                                onAdd: (item) => handleFolderId(item)
                            }}
                        />
                        <Dropdown
                            className="w-100 widgetDropdown invertedDropdown"
                            inverted
                            items={inputItems}
                            placeholder="All"
                            value={filter}
                            checkable
                            getA11ySelectionMessage={{
                                onAdd: (item) => handleFilterStatus(item)
                            }}
                        />
                    </div>
                    {folderName === 'Inbox' && (
                        <div>
                            <Menu
                                primary
                                className="widgetMenu"
                                defaultActiveIndex={0}
                                items={menuItems}
                            />
                        </div>
                    )}
                </div>
                <div className="tile-height-430">
                    {!loading ? (
                        <EmailList
                            filter={filter}
                            folderId={folderId}
                            inferenceClassification={focusedOrOther}
                        />
                    ) : (
                        <div className="h-full flex justify-center items-center">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </>
        </TileLayout>
    )
}

export default UserInbox
