import { addDays, format } from 'date-fns'
import { store } from '../store'
import getMsGraphService from './base/ms-graph.service'
import { DRIVE_FOLDER_NAME } from '../common/constants'

class FilesService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    async getOrganizationDrives() {
        let message = ''
        let drives = []
        try {
            const drivesResp = await this.msGraphService.callGetApi({
                url: '/sites/root/drives'
            })
            drives = drivesResp?.value || []
        } catch (e) {
            message = e.message
        }

        return { message, drives }
    }

    async getMyRecentFiles() {
        let message = ''
        let recentFiles = []
        try {
            const recentFilesResp = await this.msGraphService.callGetApi({
                url: '/me/insights/used',
                topValue: 5
            })
            // const recentFilesResp = await this.msGraphService.callGetApi(
            //     {url: `/me/drive/recent`, topValue: 5}
            // )
            recentFiles = recentFilesResp?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, recentFiles }
    }

    async getDriveItemPreview(driveItemPath) {
        let message = ''
        let previewUrl = null
        try {
            const { getUrl } = await this.msGraphService.callPostApi(`/${driveItemPath}/preview`)
            previewUrl = getUrl
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, previewUrl }
    }

    async getItemsInMyDrive() {
        let message = ''
        let filesInDrive = []
        try {
            filesInDrive = await this.msGraphService.callGetApi({ url: '/me/drive/root/children' })
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, filesInDrive }
    }

    async getItemByName(itemName) {
        let message = ''
        let file = {}
        try {
            const { value } = await this.msGraphService.callGetApi({
                url: '/me/drive/root/children',
                // orderBy: 'lastModifiedDateTime DESC',
                selectStatement: 'id,name,lastModifiedDateTime'
            })
            const files = value?.filter((f) => f.name === itemName) || []
            // we get the first file we find or a blank one if it's empty
            if (files.length) file = files?.at(0) || {}
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, file }
    }

    async getItemSharedWithMeByItem(itemName) {
        let message = ''
        let file = {}
        try {
            const { value } = await this.msGraphService.callGetApi({
                url: '/me/drive/sharedWithMe',
                // orderBy: 'lastModifiedDateTime DESC',
                selectStatement: 'id,name,lastModifiedDateTime'
            })
            const files = value?.filter((f) => f.name === itemName) || []
            // we get the first file we find or a blank one if it's empty
            if (files.length) file = files?.at(0) || {}
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, file }
    }

    async getDocumentLibrary(siteId) {
        let message = ''
        let documentLibrary = {}
        const state = store.getState()
        /* eslint no-console: "off" */
        console.log('the state of things', state)

        try {
            // this will get the items in the default drive
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drive/root/children`,
                selectStatement: 'id,name,parentReference'
            })
            documentLibrary = value
                ?.filter((v) => v?.name?.toLowerCase() === DRIVE_FOLDER_NAME.toLowerCase())
                ?.at(0)
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, documentLibrary }
    }

    async getLibraryFolders(siteId, driveId, documentLibraryId) {
        let message = ''
        let libraryFolders = []
        const state = store.getState()
        /* eslint no-console: "off" */
        console.log('the state of things', state)

        try {
            // this will get the items in the default drive
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drives/${driveId}/items/${documentLibraryId}/children`,
                selectStatement: 'id,name,folder,@microsoft.graph.downloadUrl'
            })

            libraryFolders = [
                ...(value || []).map((v) => ({
                    ...v,
                    key: v.id,
                    content: v.name,
                    downloadUrl: v['@microsoft.graph.downloadUrl']
                }))
            ]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, libraryFolders }
    }

    async getFoldersByPath(siteId, driveId, folderPath) {
        const fullPath = `${DRIVE_FOLDER_NAME}/${folderPath}`
        let message = ''
        let libraryFolders = []
        const state = store.getState()
        /* eslint no-console: "off" */
        console.log('the state of things', state)

        try {
            // this will get the items in the default drive
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drives/${driveId}/root:/${fullPath}`,
                selectStatement: 'id,name,folder,shared'
            })

            libraryFolders = [...(value || []).map((v) => ({ ...v, key: v.id, content: v.name }))]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, libraryFolders }
    }

    async getSiteItems(siteId, itemId) {
        let message = ''
        let items = []
        const state = store.getState()
        /* eslint no-console: "off" */
        console.log('the state of things', state)

        try {
            const parsedDate = format(addDays(new Date(), -10), 'yyyy-MM-dd')
            const url =
                itemId !== undefined
                    ? `/sites/${siteId}/drive/items/${itemId}/children`
                    : `/sites/${siteId}/drive/items`
            // this will get the items in the default drive
            const { value } = await this.msGraphService.callGetApi({
                url,
                expand: 'analytics($expand=lastSevenDays)',
                filter: `start/dateTime ge '${parsedDate}'`
            })
            items = [...value]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, items }
    }

    async getItemInSiteByName(siteId, itemName) {
        let message = ''
        let file = {}
        try {
            // this will get the items in the default drive
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drive/items`
            })
            const files = value?.filter((f) => f.name === itemName) || []
            // we get the first file we find or a blank one if it's empty
            if (files.length) file = files?.at(0) || {}
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, file }
    }
}

export default FilesService
