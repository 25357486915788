import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { BusinessPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()

const initialState = {
    sharePointSiteId: null,
    sharePointSite: null,
    sharePointSiteUrl: null,
    topLevelFolders: [],
    categories: [], // these are the list of tabs that will show in the component
    assetDrive: {},
    filters: {},
    subSites: [],
    sharePointSiteLists: [],
    sharePointSiteEventsList: [],
    eventsParentSite: {}
}

export const callUpdateBusinessPartnerStatusProperties = createAsyncThunk(
    'BusinessPartnerStatus/callUpdateBusinessPartnerStatusProperties',
    /* eslint no-unused-vars: "off" */
    async ({ selectedFilter, sharePointSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            BusinessPropertiesKey,
            selectedFilter,
            sharePointSite
        )
        return response
    }
)

export const businessPartnersSlice = createSlice({
    name: 'businessPartners',
    initialState,
    reducers: {
        updateSharePointSite: (state, action) => {
            state.sharePointSite = action.payload
        },
        updateSharePointSiteUrl: (state, action) => {
            state.sharePointSiteUrl = action.payload
        },
        updateSharePointSiteId: (state, action) => {
            state.sharePointSiteId = action.payload
        },
        updateTopLevelFolders: (state, action) => {
            state.topLevelFolders = [...action.payload]
        },
        updateCategories: (state, action) => {
            state.categories = [...action.payload]
        },
        updateSiteAssetDrive: (state, action) => {
            state.assetDrive = { ...action.payload }
        },
        updateFilter: (state, action) => {
            state.filters = { ...action.payload }
        },
        updateSubSites: (state, action) => {
            state.subSites = [...(action.payload || [])]
        },
        updateSharePointSiteRootId: (state, action) => {
            state.sharePointSiteRootId = action.payload
        },
        updateSharePointSiteLists: (state, action) => {
            state.sharePointSiteLists = [...(action.payload || [])]
        },
        updateSharePointSiteEventsList: (state, action) => {
            state.sharePointSiteEventsList = [...(action.payload || [])]
        },
        updateEventsParentSite: (state, action) => {
            state.eventsParentSite = { ...(action.payload || {}) }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateBusinessPartnerStatusProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log(
                '============= finished updating Business Partner Status Properties ',
                state,
                action
            )
        })
    }
})

export const {
    updateTopLevelFolders,
    updateCategories,
    updateSharePointSite,
    updateSiteAssetDrive,
    updateSharePointSiteId,
    updateSharePointSiteUrl,
    updateFilter,
    updateSubSites,
    updateSharePointSiteRootId,
    updateSharePointSiteLists,
    updateSharePointSiteEventsList,
    updateEventsParentSite
} = businessPartnersSlice.actions

export const selectSharePointSite = (state) => state.businessPartners.sharePointSite
export const selectSharePointSiteUrl = (state) => state.businessPartners.sharePointSiteUrl
export const selectAssetDrive = (state) => state.businessPartners.assetDrive
export const selectSharePointSiteId = (state) => state.businessPartners.sharePointSiteId
export const selectFilter = (state) => state.businessPartners.filters
export const selectSubSites = (state) => state.businessPartners.subSites
export const selectSharePointSiteRoot = (state) => state.businessPartners.sharePointSiteRootId
export const sharepointSiteLists = (state) => state.businessPartners.sharePointSiteLists
export const sharepointSiteEventsLists = (state) => state.businessPartners.sharePointSiteEventsList
export const selectEventsParentSite = (state) => state.businessPartners.eventsParentSite

export default businessPartnersSlice.reducer
