import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const SliderWrapper = ({ items, Child, fade, infinite, sharePointSiteId }) => {
    const [settings, setSettings] = useState({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 800,
        autoplaySpeed: 4000
        // adaptiveHeight: true
    })

    useEffect(() => {
        const tempSettings = { ...settings }
        setSettings({ ...tempSettings, fade, infinite })
    }, [fade, infinite])

    const children = items
        .slice(0, 5)
        .map((item, index) => <Child key={index} item={item} sharePointSiteId={sharePointSiteId} />)

    return <Slider {...settings}>{children}</Slider>
}

SliderWrapper.propTypes = {
    items: PropTypes.instanceOf(Array).isRequired,
    sharePointSiteId: PropTypes.string,
    Child: PropTypes.elementType,
    fade: PropTypes.bool,
    infinite: PropTypes.bool
}

SliderWrapper.defaultProps = {
    sharePointSiteId: '',
    fade: false,
    infinite: false,
    Child: null
}

export default SliderWrapper
