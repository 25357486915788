/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill, RiMoreFill } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { Input } from '@fluentui/react-northstar'
import { CircularProgress } from '@mui/material'
import { PlannerTaskService } from '../../services/planner-task.service'

const completeTaskClasses = 'text-ms-purple'
const incompleteTaskClasses = 'text-grey-3'
const componentMap = { RiCheckboxBlankCircleLine, RiCheckboxCircleFill }
const taskService = new PlannerTaskService()

const PlannerTaskTitle = ({
    title,
    index,
    isCompleted,
    titleContent,
    setTitleContent,
    taskObj,
    onSubmit
}) => {
    const [checkBoxClassName, setCheckBoxClassName] = useState(incompleteTaskClasses)
    const [titleClassName, setTitleClassName] = useState(incompleteTaskClasses)
    const [iconComponentName, setIconComponentName] = useState('RiCheckboxBlankCircleLine')
    const IconComponent = componentMap[iconComponentName]
    const [loading, setLoading] = useState(false)
    const [taskCompleted, setTaskCompleted] = useState(isCompleted)
    const handleSpace = (e) => {
        if (e.keyCode === 32) {
            const content = `${titleContent} `
            setTitleContent(content)
        }
    }
    const handleUpdateTask = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        let percentComplete = isCompleted ? 0 : 100
        try {
            setLoading(true)
            await taskService.updatePlannerTask(taskObj.id, { percentComplete }, taskObj.eTag)
            onSubmit({ message: 'Update Task Successfully.', type: 'updated' })
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=====Update Process Task Fail', error)
            onSubmit({ message: 'Update Task fail.', type: 'error' })
            // because the call errored, we have to reverse the value
            percentComplete = percentComplete === 0 ? 100 : 0
        } finally {
            // setTaskCompleted(percentComplete === 100)
            setLoading(false)
        }
    }

    useEffect(() => {
        setTaskCompleted(isCompleted)
    }, [isCompleted])

    useEffect(() => {
        if (taskCompleted) {
            setCheckBoxClassName(completeTaskClasses)
            setTitleClassName('opacity-50')
            setIconComponentName('RiCheckboxCircleFill')
        } else {
            setCheckBoxClassName(incompleteTaskClasses)
            setTitleClassName('')
            setIconComponentName('RiCheckboxBlankCircleLine')
        }
    }, [taskCompleted])

    let taskTitle
    if (title?.length) {
        // eslint-disable-next-line no-param-reassign
        taskTitle = <div className={`text-black text-xs ml-2 ${titleClassName}`}>{title}</div>
    } else {
        // eslint-disable-next-line no-param-reassign
        taskTitle = (
            <Input
                key={`planner-task-title-input${index}`}
                type="text"
                value={titleContent}
                onChange={(e) => {
                    setTitleContent(e.target.value)
                }}
                onKeyDown={handleSpace}
                fluid
                placeholder="Enter task title"
            />
        )
    }

    return (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <div key={`planner-task-title-line${index}`} className="flex items-center">
            <span onClick={handleUpdateTask}>
                {loading ? (
                    <CircularProgress size={12} />
                ) : (
                    <IconComponent
                        key={`planner-task-icon${index}`}
                        className={`text-lg
                        ${checkBoxClassName}`}
                    />
                )}
            </span>
            {/* if we don't have a title, then we are in edit mode */}
            {taskTitle}

            <span className="px-1">
                <RiMoreFill className=" text-grey-3" />
            </span>
        </div>
    )
}

PlannerTaskTitle.propTypes = {
    title: PropTypes.string,
    titleContent: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    setTitleContent: PropTypes.instanceOf(Function).isRequired,
    isCompleted: PropTypes.bool,
    taskObj: PropTypes.instanceOf(Object),
    onSubmit: PropTypes.instanceOf(Function)
}

PlannerTaskTitle.defaultProps = {
    title: '',
    isCompleted: false,
    taskObj: null,
    onSubmit: null
}

export default PlannerTaskTitle
