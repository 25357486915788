import React, { useEffect, useState } from 'react'
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import Grid from './Grid'
import { SortLogo } from './SortLogo'
import {
    callUpdateLogoProperties,
    updateLogoBannerList
} from '../../features/logo-banner/logoBannerSlice'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'

const DND = ({ data }) => {
    const [items, setItems] = useState(data)
    const sensors = useSensors(useSensor(PointerSensor))
    const dispatch = useDispatch()
    const sharePointSite = useSelector(selectSharePointSite)
    // const [activeItem, setActiveItem] = useState(null)

    // const handleDragStart = (event) => {
    //     const { active } = event
    //     setActiveItem(active)
    // }

    // const handleDragCancel = () => {
    //     setActiveItem(null)
    // }

    const handleDragEnd = (event) => {
        const { active, over } = event
        if (active.id !== over.id) {
            // eslint-disable-next-line no-shadow
            setItems((items) => {
                const oldIndex = items.findIndex((e) => e.id === active.id)
                const newIndex = items.findIndex((e) => e.id === over.id)
                return arrayMove(items, oldIndex, newIndex)
            })
        }
        // setActiveItem(null)
    }

    const getImageSrc = (name) => (name ? require(`../../assets/images/launcher/${name}.png`) : '')

    useEffect(() => {
        if (!items?.length) return
        dispatch(updateLogoBannerList(items.map((i) => i.id)))
        dispatch(callUpdateLogoProperties({ logoList: items.map((i) => i.id), sharePointSite }))
    }, [items])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            // onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            // onDragCancel={handleDragCancel}
            modifiers={[restrictToParentElement]}
        >
            <SortableContext items={items} strategy={rectSortingStrategy}>
                <Grid columns={5}>
                    {items.map((item, index) => (
                        <SortLogo
                            key={index}
                            url={item.url}
                            index={index}
                            name={item.name}
                            imgSrc={getImageSrc(item.name)}
                            id={item.id}
                        />
                    ))}
                </Grid>
            </SortableContext>
        </DndContext>
    )
}

DND.propTypes = {
    data: PropTypes.instanceOf(Array).isRequired
}

export default DND
