/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import { Button, Dropdown, Flex, Popup, Input } from '@fluentui/react-northstar'
import { GoSettings } from 'react-icons/go'
import { SearchIcon } from '@fluentui/react-icons-northstar'
import format from 'date-fns/format'
import SearchService from '../../services/search.service'
import { CompanyNewsService } from '../../services/company-news.service'
import DropDownSearch from './DropDownSearch'
import '../../scss/components/GlobalSearch.scss'

const GlobalSearch = () => {
    const searchService = new SearchService()
    const [textSearch, setTextSearch] = useState('')
    const [listUser, setListUser] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [filterType, setFilterType] = useState('All')
    const [searchWidth, setSearchWidth] = useState(0)
    const [selectFilter, setSelectFilter] = useState('')
    const [openPopup, setOpenPopup] = useState(false)
    const [open, setOpen] = useState('hidden')
    const handleOpen = () => setOpen('block')
    const handleClose = () => setOpen('hidden')
    const formatDate = (date) => format(new Date(date), 'dd/MM/yy')
    const entityDriveItemType = [
        'driveItem'
        // 'drive',
        // 'site',
        // 'list',
        // 'listItem'
        // 'person', // this one is part of the beta api
    ]
    const entityEmailType = ['message']
    const entitySiteType = ['site']
    // const entitySiteType = ['event']
    // const entityTypesSet4 = [
    //     'chatMessage' // this one is part of the beta api
    // ]
    const inputItems = ['All', 'Documents', 'Policies & Procedures', 'Company News', 'Emails']
    const timer = useRef(null)
    const searchWidthRef = useRef()
    const inputRef = useRef()
    const filterRef = useRef()
    const companyNewsService = new CompanyNewsService()
    let searchResult = []

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target) && !filterRef.current) {
            handleClose()
        }
    }

    const getFilteredEmailItems = (items2) => {
        const filteredEmailItems = items2?.map((item) => ({
            conversationId: item?.conversationId,
            from: item?.from?.emailAddress?.address,
            subject: item?.subject,
            bodyPreview: item?.bodyPreview,
            webLink: item?.webLink,
            type: 'message'
        }))

        return filteredEmailItems
    }

    const getFilteredSitePages = async (items3) => {
        const newsItems = await Promise.all(
            items3.map((item) => companyNewsService.getSitePages(item.id))
        )
        // eslint-disable-next-line prefer-const
        let sitePages = []
        newsItems?.forEach((e) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            sitePages.push(...e?.sitePages)
        })
        return sitePages?.map((item) => ({ ...item, type: 'news' }))
    }
    const getFilteredDriveItems = (items1) => {
        const filteredDriveItems = items1
            ?.map((item) => {
                const parseWebUrl = item?.webUrl.split('/')
                const doc = parseWebUrl[parseWebUrl.length - 1]
                let folderName = ''
                if (doc.split(/[\\/]/g).pop().split('.').length > 1) {
                    folderName = parseWebUrl[parseWebUrl.length - 4].includes(' ')
                        ? parseWebUrl
                              .slice(parseWebUrl.length - 4, parseWebUrl.length - 1)
                              .join('/')
                        : parseWebUrl
                              .slice(parseWebUrl.length - 3, parseWebUrl.length - 1)
                              .join('/')
                    return {
                        id: item?.id,
                        creator: item?.createdBy.user.displayName,
                        createdDate: formatDate(item?.createdDateTime),
                        webUrl: item?.webUrl,
                        file: {
                            fileName: doc.split(/[\\/]/g).pop().split('.')[0],
                            typeFile:
                                doc.split(/[\\/]/g).pop().split('.').length > 1
                                    ? doc.split(/[\\/]/g).pop().split('.')[1]
                                    : null
                        },
                        folderName,
                        type: 'items1'
                    }
                }
                return undefined
            })
            .filter((item) => item)

        return filteredDriveItems
    }

    useEffect(() => {
        timer.current = setTimeout(async () => {
            if (!textSearch) {
                setListUser([])
                return
            }
            try {
                setIsLoading(true)
                let filteredDriveItems = []
                let filterItem2 = []
                let filterItem3 = []
                switch (filterType) {
                    /* eslint no-case-declarations: "off" */
                    case 'All':
                        const { items: items1 } = await searchService.search(
                            textSearch,
                            entityDriveItemType
                        )
                        const { items: items2 } = await searchService.search(
                            textSearch,
                            entityEmailType
                        )
                        const { items: items3 } = await searchService.search(
                            textSearch,
                            entitySiteType
                        )
                        filteredDriveItems = getFilteredDriveItems(items1)
                        filterItem2 = getFilteredEmailItems(items2)
                        filterItem3 = await getFilteredSitePages(items3)
                        searchResult = [
                            ...filteredDriveItems.slice(0, 5),
                            ...filterItem2.slice(0, 5),
                            ...filterItem3.slice(0, 5)
                        ]
                        break
                    case 'Documents':
                    case 'Policies & Procedures':
                        const { items: documents } = await searchService.search(
                            textSearch,
                            entityDriveItemType
                        )
                        filteredDriveItems = getFilteredDriveItems(documents)
                        searchResult = [...filteredDriveItems.slice(0, 10)]
                        break
                    case 'Company News':
                        const { items: companyNews } = await searchService.search(
                            textSearch,
                            entitySiteType
                        )
                        filterItem3 = await getFilteredSitePages(companyNews)
                        searchResult = [...filterItem3.slice(0, 10)]
                        break
                    case 'Emails':
                        const { items: emails } = await searchService.search(
                            textSearch,
                            entityEmailType
                        )
                        filterItem2 = getFilteredEmailItems(emails)
                        searchResult = [...filterItem2.slice(0, 10)]
                        break
                    default:
                        break
                }
                setListUser(searchResult)
            } catch (e) {
                /* eslint no-console: "off" */
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        }, 1000)
        setSearchWidth(Number(searchWidthRef.current.offsetWidth))
        document.addEventListener('click', handleClickOutside)
        return () => {
            clearTimeout(timer.current)
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [textSearch, selectFilter])

    const dropDownChangeHandler = (_, { value: val }) => {
        setFilterType(val)
    }

    const handleSearchFilter = () => {
        setSelectFilter(filterType)
        handleOpen()
        // setOpen('hidden')
        setOpenPopup(false)
    }

    const resetBtnHandler = () => {
        setFilterType('All')
    }

    return (
        <div className="flex items-center" ref={searchWidthRef}>
            <div className="relative w-full flex items-center">
                <Flex className="w-full flex-col">
                    <Input
                        autoComplete="off"
                        icon={<SearchIcon className="text-grey-3" />}
                        placeholder="Search..."
                        iconPosition="start"
                        ref={inputRef}
                        className="search-input"
                        styles={{
                            width: '100%',
                            '& > div > input': {
                                width: '100%'
                            },
                            '& > div': {
                                width: '100%'
                            }
                        }}
                        value={textSearch}
                        onClick={handleOpen}
                        onChange={(evt) => {
                            setTextSearch(evt.target.value)
                        }}
                    />
                    <DropDownSearch
                        open={open}
                        searchData={listUser}
                        searchWidth={searchWidth}
                        textSearch={textSearch}
                        isLoading={isLoading}
                    />
                </Flex>
            </div>
            <Flex gap="gap.smaller">
                <Popup
                    trapFocus
                    position="below"
                    align="end"
                    open={openPopup}
                    onOpenChange={(_, { open: openDialog }) => setOpenPopup(openDialog)}
                    trigger={
                        <Button
                            text
                            className="uppercase textButton text-xs filtersButton"
                            icon={<GoSettings />}
                            content="Filters"
                        />
                    }
                    content={
                        <>
                            <div className="flex items-center">
                                <div className="text-xs uppercase font-semibold text-grey-3 mr-4">
                                    Type
                                </div>
                                <Dropdown
                                    inverted
                                    className="w-200 border border-grey-4 rounded"
                                    items={inputItems}
                                    value={filterType}
                                    placeholder=""
                                    position="below"
                                    align="end"
                                    checkable
                                    onChange={dropDownChangeHandler}
                                    getA11ySelectionMessage={{
                                        onAdd: (item) => `${item} has been selected.`
                                    }}
                                />
                            </div>

                            <div className="flex items-center justify-end mt-4">
                                <Button
                                    flat
                                    text
                                    primary
                                    className="uppercase textButton text-xs"
                                    content="Reset"
                                    loader="Reboot bandwidth"
                                    onClick={() => resetBtnHandler()}
                                />
                                <Button
                                    flat
                                    primary
                                    className="uppercase text-xs secondaryButton"
                                    content="Search"
                                    loader="Reboot bandwidth"
                                    ref={filterRef}
                                    onClick={handleSearchFilter}
                                />
                            </div>
                        </>
                    }
                />
            </Flex>
        </div>
    )
}

export default GlobalSearch
