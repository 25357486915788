import React from 'react'
import { Dialog } from '@fluentui/react-northstar'
import { CloseIcon } from '@fluentui/react-icons-northstar'
import PropTypes from 'prop-types'
import '../../scss/components/FormDialog.scss'

const FormDialog = ({
    headerText,
    trigger: ButtonTrigger,
    content: DialogContent,
    onSubmit,
    onOpen,
    onCancel,
    open,
    showCancel
}) => (
    <Dialog
        header={headerText}
        content={DialogContent}
        open={open}
        onOpen={onOpen}
        onCancel={onCancel}
        onConfirm={onSubmit}
        confirmButton={onSubmit ? 'Submit' : null}
        cancelButton={showCancel ? 'Cancel' : null}
        headerAction={{
            icon: <CloseIcon />,
            title: 'Close',
            onClick: onCancel
        }}
        trigger={ButtonTrigger}
    />
)

FormDialog.defaultProps = {
    showCancel: true,
    trigger: undefined,
    onSubmit: undefined,
    onOpen: undefined
}

FormDialog.propTypes = {
    headerText: PropTypes.node.isRequired,
    trigger: PropTypes.node,
    content: PropTypes.node.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onOpen: PropTypes.func,
    open: PropTypes.bool.isRequired,
    showCancel: PropTypes.bool
}

export default FormDialog
