import getMsGraphService from './base/ms-graph.service'

export default class ListsService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    async getSiteLists(siteId) {
        let message = ''
        let siteLists = {}
        try {
            siteLists = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists`
            })
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, siteLists }
    }

    async getListByTitle(siteId, listTitle) {
        let message = ''
        let list = {}
        try {
            list = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists/${listTitle}`
            })
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, list }
    }

    async getListItems(siteId, listId) {
        let message = ''
        let listItems = []
        try {
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists/${listId}/items`,
                expand: 'fields'
            })
            listItems = [...value]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, listItems }
    }
}

export const listsService = new ListsService()
