import { useState } from 'react'
import { parseISO } from 'date-fns'
import { CompanyNewsService } from '../services/company-news.service'
import { formatInSydneyTimeZone } from '../common/date-helper.fns'

const companyNewsService = new CompanyNewsService()

export const useSharepointSitePages = () => {
    const [companyNews, setCompanyNews] = useState([])
    const [loading, setLoading] = useState(false)
    const getLatestNews = async (sharePointSiteId, parentWebUrl) => {
        try {
            if (!sharePointSiteId) return

            // let's start with the first one
            const { sitePages } = await companyNewsService.getSitePages(sharePointSiteId)
            const mappedSitePages = sitePages.map((item) => {
                let webUrl = `${parentWebUrl || ''}/${item.webUrl || ''}`
                if (webUrl === '/') webUrl = ''
                return {
                    ...item,
                    day: formatInSydneyTimeZone(parseISO(item.lastModifiedDateTime), 'dd MMM yyyy'),
                    content: item.description || item.name,
                    webUrl
                }
            })
            if (mappedSitePages.length) setCompanyNews([...mappedSitePages])
        } catch (error) {
            /* eslint no-console: "off" */
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return { companyNews, loading, setLoading, getLatestNews }
}
