// import { BearerTokenAuthProvider, createApiClient, TeamsFx } from '@microsoft/teamsfx'
import { basePowerBiUrl } from '../../common/constants'

export class PowerBiService {
    powerBiApi = `${basePowerBiUrl}/v1.0/myorg`

    /* eslint class-methods-use-this: "off" */
    async getGroups(accessToken) {
        let groups = []
        let errorMessage = ''
        try {
            const response = await fetch(`${this.powerBiApi}/groups`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to fetch config for Report.
                Status: ${response.status} ${response.statusText}`
                )
            }
            const { value } = await response.json()
            groups = [...value]
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            errorMessage = 'Error generating the token for PowerBi'
        }

        return { groups, errorMessage }
    }

    async generateToken(accessToken, reportId, groupId) {
        let token
        let errorMessage = ''
        try {
            const response = await fetch(
                `${this.powerBiApi}/groups/${groupId}/reports/${reportId}/GenerateToken`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        accessLevel: 'View',
                        allowSaveAs: false,
                        lifetimeInMinutes: 10
                    })
                }
            )
            const responseJson = await response.json()
            if (!response.ok) {
                throw new Error(
                    `Failed to fetch the generated token.
                Status: ${response.status} ${response.statusText}`
                )
            }
            const { token: generatedToken } = responseJson

            token = generatedToken
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            errorMessage = 'Error generating the token for PowerBi'
        }

        return { token, errorMessage }
    }

    async getWorkspaces(accessToken, groupId) {
        let workspaces = []
        let errorMessage = ''
        try {
            const response = await fetch(`${this.powerBiApi}/groups/${groupId}/reports`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to fetch config for Report.
                Status: ${response.status} ${response.statusText}`
                )
            }
            const reportsResponse = await response.json()
            workspaces = reportsResponse?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            errorMessage = 'Error generating the token for PowerBi'
        }

        return { workspaces, errorMessage }
    }

    async getReportInGroup(accessToken, groupId) {
        let reports = []
        let errorMessage = ''
        try {
            const response = await fetch(`${this.powerBiApi}/groups/${groupId}/reports`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to fetch config for Report.
                Status: ${response.status} ${response.statusText}`
                )
            }
            const reportsResponse = await response.json()
            reports = reportsResponse?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            errorMessage = 'Error generating the token for PowerBi'
        }

        return { reports, errorMessage }
    }

    async getReport(accessToken, reportId) {
        let report = {}
        let errorMessage = ''
        try {
            const response = await fetch(`${this.powerBiApi}/reports/${reportId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            if (!response.ok) throw new Error('Could not get the report')
            const reportsResponse = await response.json()
            report = reportsResponse?.value || {}
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            errorMessage = 'Error getting report from PowerBi'
        }

        return { report, errorMessage }
    }
}
