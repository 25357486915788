/* eslint-disable function-paren-newline */
import { Button, Popup } from '@fluentui/react-northstar'
import React, { useState, useRef, useEffect } from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { isMobile } from 'react-device-detect'
import DND from './DND'
import logoBanners from '../../common/json/logo-banners.json'
import { getLogoList, updateLogoBannerList } from '../../features/logo-banner/logoBannerSlice'
import { LogoBannerPropertiesKey } from '../../common/constants'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'
import { PagePropertiesService } from '../../services/page-properties.service'
import '../../scss/components/LogoBanner.scss'

const logoBannerList = [...logoBanners]

const LogoBanner = () => {
    // const [branches, setBranches] = useState(data)
    const [width, setWidth] = useState(null)
    const [loading, setLoading] = useState(true)
    const widthRef = useRef()
    const dispatch = useDispatch()
    const bannerListIds = useSelector(getLogoList)
    const [bannerList, setBannerList] = useState([])
    const sharePointSite = useSelector(selectSharePointSite)
    const pagePropertiesService = new PagePropertiesService()

    const getSavedLogos = async () => {
        if (!sharePointSite) return
        const savedLogos = await pagePropertiesService.getProperties(
            LogoBannerPropertiesKey,
            sharePointSite
        )
        if (savedLogos?.length) {
            // lets first check if we have just ids, we can check just the first one for now
            if (typeof savedLogos[0] === 'object') {
                dispatch(updateLogoBannerList(savedLogos.map((sl) => sl.id)))
            } else {
                dispatch(updateLogoBannerList(savedLogos))
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (logoBannerList?.length) {
            dispatch(updateLogoBannerList(logoBannerList.map((lbl) => lbl.id)))
        }
    }, [logoBannerList])

    useEffect(() => {
        if (bannerListIds?.length) {
            const orderedBannerList = logoBannerList.sort(
                (a, b) => bannerListIds.indexOf(a.id) - bannerListIds.indexOf(b.id)
            )
            setBannerList([...orderedBannerList])
        }
    }, [bannerListIds])

    useEffect(() => {
        getSavedLogos()
    }, [sharePointSite])

    useEffect(() => {
        if (!widthRef.current) return

        // TODO: need to change the width when we are on mobile
        if (isMobile) {
            setWidth(widthRef.current.offsetWidth)
        } else {
            setWidth(widthRef.current.offsetWidth)
        }
    }, [widthRef])

    return (
        <>
            {!loading && (
                <div className="flex items-center justify-between w-full" ref={widthRef}>
                    <Popup
                        trapFocus
                        position="below"
                        align="end"
                        trigger={
                            <Button
                                text
                                className="afterIcon uppercase textButton headingButton text-xs"
                                content="Quicklinks"
                                iconPosition="after"
                                icon={<RiArrowDownSLine />}
                            />
                        }
                        content={
                            <div style={{ width, marginTop: '6px' }}>
                                <DND data={bannerList} style={{ width }} />
                            </div>
                        }
                    />
                </div>
            )}
            {loading && (
                <div className="flex flex-row justify-center pt-8">
                    <CircularProgress />
                </div>
            )}
        </>
    )
}

export default LogoBanner
