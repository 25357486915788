import React from 'react'
import { FiMapPin, FiClock } from 'react-icons/fi'
import { CallVideoIcon } from '@fluentui/react-icons-northstar'
import { Button } from '@fluentui/react-northstar'
import { Person } from '@microsoft/mgt-react'
import { PersonCardInteraction, PersonViewType } from '@microsoft/mgt'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { autoLinkText } from '../../common/html-helpers'
import { goToUrl } from '../../common/link-helpers'
import '../../scss/mgt/Person.scss'

const CustomMeeting = ({
    subject,
    location,
    startTime,
    endTime,
    organizer,
    attendees,
    meetingUrl
}) => {
    const joinMeeting = goToUrl
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <div className="rounded border border-grey-4 mt-3 mb-4 p-3">
            <div className="flex items-start justify-between mb-3">
                {subject && (
                    <div className="newsTitle text-primary font-bold text-lg lg:text-xl leading-none lg:leading-6">
                        {subject}
                    </div>
                )}

                {meetingUrl && (
                    <div>
                        <Button
                            icon={<CallVideoIcon className="videoIcon" />}
                            content="Join call"
                            iconPosition="before"
                            primary
                            className="w-20 text-center text-white text-xs font-semibold px-2 py-2 bg-ms-purple flex items-center rounded uppercase"
                            onClick={() => joinMeeting(meetingUrl)}
                        />
                    </div>
                )}
            </div>

            {startTime && endTime && (
                <div className="flex items-center mb-3">
                    <FiClock className="text-secondary mr-2" />
                    <div className="font-semibold">{`${startTime}-${endTime}`}</div>
                </div>
            )}
            {location && (
                <div className="flex items-center mb-3">
                    <FiMapPin className="text-secondary mr-2" />
                    <div
                        className="break-words"
                        dangerouslySetInnerHTML={{
                            /* eslint react/no-danger: "off" */
                            __html: DOMPurify.sanitize(autoLinkText(location))
                        }}
                    />
                </div>
            )}
            {organizer && (
                <div className="flex items-center mb-3">
                    <p>Organiser:</p>
                    <div>
                        <Person
                            personQuery={organizer}
                            personCardInteraction={PersonCardInteraction.hover}
                            view={PersonViewType.oneline}
                            showPresence
                        />
                    </div>
                </div>
            )}
            {attendees?.length > 0 && (
                <div className="flex items-center mb-3">
                    <p>Attendees:</p>
                    <div className="flex">
                        {attendees?.map((attnd) => (
                            <div>
                                <Person
                                    personQuery={attnd.emailAddress.address}
                                    personCardInteraction={PersonCardInteraction.hover}
                                    view={PersonViewType.avatar}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

CustomMeeting.propTypes = {
    subject: PropTypes.string.isRequired,
    location: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    organizer: PropTypes.string.isRequired,
    attendees: PropTypes.instanceOf(Array),
    meetingUrl: PropTypes.string
}

CustomMeeting.defaultProps = {
    meetingUrl: null,
    attendees: null,
    location: null
}

export default CustomMeeting
