/* eslint-disable no-unreachable */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { List, ListItem, ListItemButton, CircularProgress } from '@mui/material'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import '../../scss/components/DropdownSearch.scss'
import PropTypes from 'prop-types'
import FileList from './FileList'
import MailList from './MailList'
import NewsList from './NewsList'

const DropDownSearch = ({ searchData, searchWidth, open, textSearch, isLoading }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const handleSave = (filterData) => setData([...filterData])
    useEffect(() => {
        setLoading(true)
        handleSave(searchData)
        if (searchData.length && !isLoading) {
            setLoading(false)
        }
    }, [searchData, isLoading])

    return (
        <List sx={{ width: `${searchWidth}px` }} className={`dropdown-postion ${open} `}>
            {!loading && !isLoading ? (
                // eslint-disable-next-line consistent-return, array-callback-return
                data?.map((item, idx) => {
                    switch (item.type) {
                        case 'items1':
                            return <FileList item={item} idx={idx} />
                        case 'news':
                            return <NewsList item={item} idx={idx} />
                        case 'message':
                            return <MailList item={item} idx={idx} />
                        default:
                            return null
                    }
                })
            ) : (
                <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="font-semibold font-bold text-blue-700 text-center">
                        {(searchData.length !== 0 && loading) ||
                        isLoading ||
                        !textSearch ||
                        (textSearch.length === 1 && !isLoading)
                            ? ''
                            : 'No Results Found'}
                    </div>
                </ListItem>
            )}
            {loading && isLoading ? (
                <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </ListItem>
            ) : null}
            <ListItem>
                <ListItemButton>
                    {!textSearch ? (
                        <div className="flex justify-around  text-xs font-bold  text-blue-700">
                            <p className="flex">
                                <IoIosHelpCircleOutline className="font-bold text-lg" />
                                Type in search bar to find what you are looking for
                            </p>
                        </div>
                    ) : null}
                </ListItemButton>
            </ListItem>
        </List>
    )
}

DropDownSearch.propTypes = {
    searchWidth: PropTypes.number.isRequired,
    searchData: PropTypes.instanceOf(Array).isRequired,
    open: PropTypes.string.isRequired,
    textSearch: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default DropDownSearch
