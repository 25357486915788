import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { TeamsFx } from '@microsoft/teamsfx'
import { Dropdown } from '@fluentui/react-northstar'
import { useState, useEffect } from 'react'
import { RiBarChartBoxLine } from 'react-icons/ri'
import '../scss/components/SalesTracker.scss'
import { PowerBiService } from '../services/base/power-bi.service'
import { powerBiScopes } from '../common/constants'
import TileLayout from './basic/TileLayout'
import { PowerBiErrors } from '../common/exception.types'

const SalesTracker = () => {
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState({})
    const [reports, setReports] = useState([])
    const [currentGroup, setCurrentGroup] = useState('')
    const [currentReport, setCurrentReport] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const teamsFx = new TeamsFx()
    const cssClassName = 'report-style-class'
    const powerBiService = new PowerBiService()

    // TODO: need to replace this with the correct report url
    // const [report, setReport] = useState()
    const [displayMessage, setMessage] = useState('')

    const [embedConfig, setEmbedConfig] = useState({
        // const embedConfig = {
        type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
        id: null,
        embedUrl: null,
        accessToken: null,
        tokenType: models.TokenType.Embed,
        settings: {
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                }
            },
            background: models.BackgroundType.Transparent
        }
    })

    const eventHandlers = new Map([
        [
            'loaded',
            () => {
                /* eslint no-console: "off" */
                console.log('Report loaded')
            }
        ],
        [
            'rendered',
            () => {
                /* eslint no-console: "off" */
                console.log('Report rendered')
                // setMessage('The report is rendered')
            }
        ],
        [
            'error',
            (event) => {
                /* eslint no-console: "off" */
                console.log(event.detail)
                if (event?.detail?.message) {
                    switch (event.detail.message) {
                        /* eslint indent: "off" */
                        case PowerBiErrors.PowerBINotLicensedException:
                            setMessage(
                                `You are not licensed to use PowerBI,
                                    please check with your Offce365 administrator`
                            )
                            break
                        default:
                            setMessage(`${event.detail.message}: ${event.detail.detailedMessage}`)
                            break
                    }
                }
                setShowMessage(true)
            }
        ]
    ])

    const getReport = async (token, reportObj) => {
        const { token: embedToken, errorMessage } = await powerBiService.generateToken(
            token,
            reportObj.id,
            group.id
        )
        if (errorMessage) {
            setMessage(errorMessage)
        }
        // setMessage('report has loaded')

        setCurrentReport(reportObj?.name || '')
        setEmbedConfig({
            ...embedConfig,
            id: reportObj.id,
            embedUrl: reportObj.embedUrl,
            accessToken: embedToken
        })
    }

    const getReports = async (token) => {
        // noowww we generate the token
        const { reports: foundReports } = await powerBiService.getReportInGroup(token, group.id)
        setReports([...foundReports.map((m) => ({ ...m, key: m.id, content: m.name }))])
        const tempReport = foundReports?.at(0)
        if (!tempReport?.id) {
            setCurrentReport('')
            setMessage('report has loaded - no report found')
            return
        }
        await getReport(token, tempReport)
    }

    const getToken = async () => {
        const { token } = await teamsFx.getCredential().getToken(powerBiScopes)

        const { groups: foundGroups } = await powerBiService.getGroups(token)
        /* eslint no-console: "off" */
        console.debug('========== groups, ')
        // now let's take the first group
        // const group = groups.at(0)
        const tempGroup = foundGroups?.at(0)
        setGroup({ ...tempGroup })
        setGroups([...foundGroups.map((g) => ({ ...g, key: g.id, content: g.name }))])
        setCurrentGroup(tempGroup.name)
    }

    const handleGroupSelect = async (e) => {
        if (!e?.value?.id) {
            setCurrentReport('')
            return
        }
        setCurrentGroup(e?.value?.name)
        setGroup({ ...e.value })
    }

    const handleReportSelect = async (e) => {
        if (!e?.value?.id) {
            setMessage('Invalid report selected, please choose another one')
            return
        }
        setShowMessage(false)
        setCurrentReport(e?.value?.name || '')
        const { token } = await teamsFx.getCredential().getToken(powerBiScopes)
        await getReport(token, e.value)
    }

    useEffect(() => {
        if (!group?.id) return
        teamsFx
            .getCredential()
            .getToken(powerBiScopes)
            .then(async ({ token }) => {
                await getReports(token)
            })
    }, [group])

    useEffect(() => {
        getToken().catch((error) => {
            /* eslint no-console: "off" */
            console.error('could not update or get the report config', error)
        })
    }, [])

    const titleChildren = (
        <div className="flex items-center">
            <Dropdown
                className="w-150 widgetDropdown invertedDropdown"
                inverted
                items={groups}
                placeholder="Select Group"
                checkable
                getA11ySelectionMessage={{
                    onAdd: (item) => console.log('itemSelect:', item)
                }}
                onChange={(_, evt) => handleGroupSelect(evt)}
                value={currentGroup}
            />
            <Dropdown
                className="w-150 widgetDropdown invertedDropdown"
                inverted
                items={reports}
                placeholder="Select Report"
                checkable
                getA11ySelectionMessage={{
                    onAdd: (item) => console.log('itemSelect:', item)
                }}
                onChange={(_, evt) => handleReportSelect(evt)}
                value={currentReport}
            />
        </div>
    )

    return (
        <TileLayout
            title="Sales Tracker"
            icon={<RiBarChartBoxLine className="text-primary text-lg" />}
            titleChildren={titleChildren}
        >
            <div
                className={
                    !showMessage && currentReport !== ''
                        ? 'relative tile-height-800'
                        : 'relative tile-height-430'
                }
            >
                {!showMessage && currentReport !== '' ? (
                    <PowerBIEmbed
                        embedConfig={embedConfig}
                        eventHandlers={eventHandlers}
                        cssClassName={cssClassName}
                        getEmbeddedComponent={(embedObject) => {
                            /* eslint no-console: "off" */
                            console.log(
                                `Embedded object of type "${embedObject.embedtype}" received`
                            )
                        }}
                    />
                ) : null}
                {showMessage && currentReport !== '' ? (
                    <>
                        <div className="hr" />
                        <div className="displayMessage">{displayMessage}</div>
                    </>
                ) : null}
            </div>
        </TileLayout>
    )
}

export default SalesTracker
