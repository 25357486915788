import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import LayoutActions from './LayoutActions'
import {
    callUpdateProperties,
    getEditMode,
    updateTabLayoutByKey,
    replaceTabsOrder
} from '../../features/tab-layout/tabLayoutSlice'
import tabRoles from '../../common/json/role-for-edit-tabs.json'
import { selectSharePointSite } from '../../features/business-partners/businessPartnersSlice'

const TileLayout = ({ title, icon, children, titleChildren }) => {
    const dispatch = useDispatch()
    const sharePointSite = useSelector(selectSharePointSite)
    const isInEditMode = useSelector(getEditMode)
    const mappingTitle = title.replace(' ', '').toLocaleLowerCase()
    const role = tabRoles.find((tab) => mappingTitle.includes(tab.title.trim().toLocaleLowerCase()))
    const hideTileHandler = () => {
        dispatch(updateTabLayoutByKey({ name: title.replace(/ /g, ''), display: false }))
        dispatch(callUpdateProperties({ sharePointSite }))
    }
    const setMovingTab = () => {
        dispatch(replaceTabsOrder(true))
    }

    return (
        <>
            {title !== '' && icon !== null && (
                <div className="flex items-center justify-between mb-4 relative">
                    <div className="flex items-center">
                        {icon}
                        <div className="ml-2 text-xs font-bold text-primary uppercase">{title}</div>
                    </div>
                    {titleChildren !== null ? (
                        <div className="title-children">{titleChildren}</div>
                    ) : null}
                    {isInEditMode ? (
                        <div className="flex items-center">
                            <LayoutActions
                                onTileHide={() => hideTileHandler()}
                                setMovingTab={() => setMovingTab()}
                                role={role}
                            />
                        </div>
                    ) : null}
                </div>
            )}
            {children}
        </>
    )
}

TileLayout.propTypes = {
    children: PropTypes.element.isRequired,
    titleChildren: PropTypes.element,
    title: PropTypes.string,
    icon: PropTypes.element
}

TileLayout.defaultProps = {
    title: '',
    icon: null,
    titleChildren: null
}

export default TileLayout
