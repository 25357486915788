import noHeroImage from '../assets/images/no-hero-image.png'
import { CompanyNewsService } from '../services/company-news.service'

const companyNewsService = new CompanyNewsService()
export const goToUrl = (url) => {
    if (url) {
        window.open(url, '_blank')
    }
}

export const getImageUrl = async (item, sharePointSiteId, sharePointAssetDrive) => {
    // let's check if we have the imageUrl on the object already:
    if (item?.imageUrl) {
        return item.imageUrl
    }

    // otherwise we get it from the graph
    if (!item.title || !sharePointSiteId || !sharePointAssetDrive.id) return ''
    const { thumbnail } = await companyNewsService.getThumbnails(
        sharePointSiteId,
        sharePointAssetDrive?.id,
        item.name
    )
    return thumbnail?.['@microsoft.graph.downloadUrl'] || noHeroImage
}
