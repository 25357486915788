import React from 'react'
import { List, ListItem, ListItemButton } from '@mui/material'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { RiDeleteBin6Line, RiFlag2Line, RiArchiveLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import EmailService from '../../services/email.service'
import { getArchiveFolder, getSelectedMail } from '../../features/user-inbox/mailSlice'

const ContextMenuContainer = styled.div`
    position: absolute;
    background: white;
    z-index: 9999;
    border: 0;
    border-radius: 4px;
    inset: 0px auto auto 0px;
    border-left-width: 0.0625rem;
    border-bottom-width: 0.0625rem;
    border-right-width: 0.0625rem;
    border-top-width: 0.0625rem;
    box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.01);
    box-sizing: border-box;
    ${({ top, left }) => css`
        top: ${top}px;
        left: ${left}px;
    `}
`
const ContextMenu = ({ top, left, reload, setAnimation }) => {
    const emailService = new EmailService()
    const archiveFolder = useSelector(getArchiveFolder)
    const data = useSelector(getSelectedMail)
    const isArchive = archiveFolder.id === data.parentFolderId
    const handleMoveEmail = async () => {
        try {
            await emailService.deleteMail(data.id)
            setAnimation(true)
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Delete Mail:', error)
        }
    }
    const handleSetFlag = async () => {
        try {
            const status = data?.flag?.flagStatus === 'flagged' ? 'notFlagged' : 'flagged'
            await emailService.setMailFlag(data.id, { flagStatus: status })
            reload()
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Set Flag Mail:', error)
        }
    }
    const hanldeArchive = async () => {
        try {
            await emailService.setAchiveMail(data.id, { destinationId: archiveFolder.id })
            setAnimation(true)
        } catch (error) {
            /* eslint no-console: "off" */
            console.log('=========Set Archive Mail:', error)
        }
    }
    return (
        <ContextMenuContainer top={top} left={left}>
            <List sx={{ padding: 0 }}>
                <div className={`${isArchive ? 'hidden' : ''}`}>
                    <ListItem sx={{ padding: 0 }}>
                        <ListItemButton onClick={hanldeArchive}>
                            <RiArchiveLine className="mr-2" />
                            Archive
                        </ListItemButton>
                    </ListItem>
                </div>
                <ListItem sx={{ padding: 0 }}>
                    <ListItemButton onClick={handleSetFlag}>
                        <RiFlag2Line className="mr-2" />
                        Flag
                    </ListItemButton>
                </ListItem>
                <ListItem sx={{ padding: 0 }}>
                    <ListItemButton onClick={handleMoveEmail}>
                        <RiDeleteBin6Line className="mr-2" />
                        Delete
                    </ListItemButton>
                </ListItem>
            </List>
        </ContextMenuContainer>
    )
}

ContextMenu.propTypes = {
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    reload: PropTypes.func,
    setAnimation: PropTypes.func
}

ContextMenu.defaultProps = {
    reload: null,
    setAnimation: null
}

export default ContextMenu
