import { createMicrosoftGraphClient, TeamsFx } from '@microsoft/teamsfx'
import { scopes } from '../../common/constants'

let msGraphService = null

class MsGraphService {
    graphClient = null

    constructor() {
        const teamsFx = new TeamsFx()
        this.graphClient = createMicrosoftGraphClient(teamsFx, [...scopes])
    }

    getGraphClient() {
        return this.graphClient
    }

    async callGetApi({
        url,
        selectStatement,
        orderBy,
        topValue,
        skip,
        search,
        filter,
        expand,
        query,
        isBeta = false,
        headers = null
    } = {}) {
        let apiCall = this.graphClient.api(url)
        if (query) apiCall = apiCall.query(query)
        if (selectStatement) apiCall = apiCall.select(selectStatement)
        if (orderBy) apiCall = apiCall.orderby(orderBy)
        if (expand) apiCall = apiCall.expand(expand)
        if (search) apiCall = apiCall.search(search)
        if (topValue) apiCall = apiCall.top(topValue)
        if (skip) apiCall = apiCall.skip(skip)
        if (filter) apiCall = apiCall.filter(filter)
        if (isBeta) apiCall = apiCall.version('beta')
        if (headers) apiCall = apiCall.headers(headers)

        // function call is async so need to await the return
        return apiCall.get()
    }

    async callPostApi(url, payload, headers = null, isBeta = false) {
        let apiCall = this.graphClient.api(url)
        // add other required chain functions here if we need to
        if (headers) apiCall.headers(headers)
        if (isBeta) apiCall = apiCall.version('beta')

        // function call is async so need to await the return
        return apiCall.post(payload)
    }

    async callUpdateApi(url, payload, headers = null, isBeta = false) {
        let apiCall = this.graphClient.api(url)
        // add other required chain functions here if we need to
        if (headers) apiCall.headers(headers)
        if (isBeta) apiCall = apiCall.version('beta')

        // function call is async so need to await the return
        return apiCall.update(payload)
    }

    async callDeleteApi(url, headers = null, isBeta = false) {
        let apiCall = this.graphClient.api(url)
        // add other required chain functions here if we need to
        if (headers) apiCall.headers(headers)
        if (isBeta) apiCall = apiCall.version('beta')

        // function call is async so need to await the return
        return apiCall.delete()
    }

    /* eslint-disable spaced-comment */
    //#region getter functions
    async getUserProfile() {
        let graphUser
        try {
            graphUser = await this.callGetApi({
                url: '/me',
                selectStatement: 'id,displayName,mail,userPrincipalName,mobilePhone'
            })
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
        }

        return graphUser
    }

    async getMailFolders() {
        let folders
        try {
            folders = await this.callGetApi({ url: '/me/mailFolders' })
        } catch (e) {
            /* eslint no-console: "off" */
            console.error(e)
            throw e
        }

        return folders
    }
    /* eslint-disable spaced-comment */
    //#endregion
}

export default function getMsGraphService() {
    if (!msGraphService) msGraphService = new MsGraphService()

    return msGraphService
}
