import React from 'react'
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import { RiInboxLine } from 'react-icons/ri'
import PropTypes from 'prop-types'
import { goToUrl } from '../../common/link-helpers'

const MailList = ({ item, idx }) => {
    const handleMailClick = goToUrl

    return (
        <ListItem key={`search-item-${idx}`} onClick={() => handleMailClick(item?.webLink)}>
            <ListItemButton>
                <div className="flex items-start w-full">
                    <ListItemIcon
                        className="w-6 h-6 flex items-center"
                        style={{ minWidth: '40px' }}
                    >
                        <RiInboxLine className="text-secondary text-lg" />
                    </ListItemIcon>

                    <div className="grid grid-cols-12 gap-2 w-full">
                        <div className="text-xs font-semibold col-span-3 break-words">
                            {item?.subject}
                        </div>
                        <div
                            className="col-span-6 text-grey-2 text-xxs"
                            style={{
                                wordBreak: 'break-word'
                            }}
                        >
                            {item.bodyPreview}
                        </div>
                        <div className="col-span-3 text-xxs font-semibold text-grey-3 text-right truncate overflow-hidden whitespace-nowrap">
                            {item?.from}
                        </div>
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    )
}

MailList.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    idx: PropTypes.number.isRequired
}

export default MailList
