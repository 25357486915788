import getMsGraphService from './ms-graph.service'

export class BaseService {
    msGraphService = null

    graph = null

    constructor() {
        this.msGraphService = getMsGraphService()
        // Use an instance of BetaGraph since we know we need to call beta apis.
        // this.graph = BetaGraph.fromGraph(this.msGraphService.getGraphClient())
        this.graph = this.msGraphService.getGraphClient()
    }
}
