import { format, utcToZonedTime } from 'date-fns-tz'

const timeZone = 'Australia/Sydney'
const dateFormat = 'dd/MM/yyyy'

/* eslint-disable implicit-arrow-linebreak */
export const formatInTimeZone = (date, fmt, tz) =>
    format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

export const formatInSydneyTimeZone = (date, fmt) => formatInTimeZone(date, fmt, timeZone)

export const customDateFormatter = (date) => {
    if (!date) return date
    return format(date, dateFormat)
}

export const compareDate = (date1, date2) => {
    const date3 = new Date(date1)
    const date4 = new Date(date2)
    return (
        date3.getDate() === date4.getDate() &&
        date3.getMonth() === date4.getMonth() &&
        date3.getFullYear() === date4.getFullYear()
    )
}

export const isInThisWeek = (date) => {
    const curr = new Date()
    const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()))
    const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6))
    const time = new Date(date)
    return time >= firstday && time <= lastday
}

export const convertUTCDateToLocalDate = (date, typeFormat) => {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    const offset = date.getTimezoneOffset() / 60
    const hours = date.getHours()
    newDate.setHours(hours - offset)
    return format(newDate, typeFormat)
}

export const getFirstDateOfMonth = () => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const getLastDateOfMonth = () => {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}
