export const scopes = [
    'email',
    'openid',
    'profile',
    'offline_access',
    'User.Read',
    'User.ReadBasic.All',
    'Calendars.Read',
    'Files.Read.All',
    'Sites.Read.All',
    'Sites.ReadWrite.All',
    'Sites.Manage.All',
    'Tasks.ReadWrite',
    // 'Tasks.ReadWrite.All',
    'People.Read',
    'Mail.ReadBasic',
    'Mail.Read',
    'Mail.ReadWrite',
    'https://analysis.windows.net/powerbi/api/Content.Create',
    'https://analysis.windows.net/powerbi/api/Capacity.Read.All',
    'https://analysis.windows.net/powerbi/api/Capacity.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dataflow.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataflow.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Gateway.Read.All',
    'https://analysis.windows.net/powerbi/api/Gateway.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Report.Read.All',
    'https://analysis.windows.net/powerbi/api/Report.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/StorageAccount.Read.All',
    'https://analysis.windows.net/powerbi/api/StorageAccount.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/App.Read.All',
    'https://analysis.windows.net/powerbi/api/Tenant.Read.All',
    'https://analysis.windows.net/powerbi/api/Tenant.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
    'https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All'
]

export const powerBiScopes = [
    // // 'Report.ReadWrite.All'
    // 'Report.Read.All',
    // // 'Dataset.ReadWrite.All'
    // 'Dataset.Read.All',
    // 'Content.Create',
    // 'App.Read.All'
    'https://analysis.windows.net/powerbi/api/Content.Create',
    'https://analysis.windows.net/powerbi/api/Capacity.Read.All',
    'https://analysis.windows.net/powerbi/api/Capacity.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dataflow.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataflow.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Gateway.Read.All',
    'https://analysis.windows.net/powerbi/api/Gateway.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Report.Read.All',
    'https://analysis.windows.net/powerbi/api/Report.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/StorageAccount.Read.All',
    'https://analysis.windows.net/powerbi/api/StorageAccount.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataset.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/App.Read.All',
    'https://analysis.windows.net/powerbi/api/Tenant.Read.All',
    'https://analysis.windows.net/powerbi/api/Tenant.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All',
    'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
    'https://analysis.windows.net/powerbi/api/Workspace.ReadWrite.All'
]

export const powerBiScopeBase = ['https://analysis.windows.net/powerbi/api/Report.Read.All']

export const basePowerBiUrl = 'https://api.powerbi.com'

export const DRIVE_FOLDER_NAME = 'DocumentLibrary'
export const DEFAULT_WORKSPACE = 'ReportsWorkspace'
// export const DEFAULT_WORKSPACE = 'codeSource'

// export const SALES_REPORT_NAME = 'Sales test andrew'
// export const SALES_REPORT_NAME = 'sales sample connah'
// export const SALES_REPORT_NAME = 'Test Sales sample'
export const SALES_REPORT_NAME = 'Sales and Marketing Sample'

export const CELEBRATIONS_LIST = 'Celebrations'
export const CLIENT_SUCCESSES_LIST = 'Client Successes'
export const COMPANY_VALUES_LIST = 'Values'

export const OUR_COMPANY_SUB_DOMAIN = 'Our Company'

export const MAX_PREVIEW_LENGTH = 1200

export const PropertiesColumnName = 'THProperties'
export const WebPartUniqueKeyColumnName = 'THKey'
export const MySiteGraphIdStorageKey = 'TheHubId'
export const SettingsListIdStorageKey = 'SettingsListId'
export const SettingsRootListIdStorageKey = 'SettingsRootListId'
export const PropertiesListTitle = 'THProperties'
export const PropertiesRootListTitle = 'THRootProperties'
export const RootSiteGraphIdStorageKey = 'TheRootHubId'

export const PageLayoutPropertiesKey = 'pageLayout'
export const LogoBannerPropertiesKey = 'logoList'
export const OrderTabsPropertiesKey = 'orderTabs'
export const MailPropertiesKey = 'mail'
export const CalendarPropertiesKey = 'calendar'
export const PlannerPropertiesKey = 'planner'
export const BusinessPropertiesKey = 'businessPartners'
export const SubLogoPropertiesKey = 'subLogo'

// if we are on the codeesource test site, we have to ge the sub-site, otherwise stay where we are
export const PARENT_PAGE_TO_IGNORE = 'codesource'
// export const PARENT_SITE_KEYWORD = 'communication'
export const PARENT_SITE_KEYWORD = 'Davidson'
export const PARENT_SITE_SUB_KEY = 'communications'

export const FOCUSED_MAIL_KEY = 'Focused'
export const OTHER_MAIL_KEY = 'Other'

export const MAIL_FOLDER_ORDER = ['Inbox', 'Drafts', 'Archive', 'Sent', 'Deleted Items']

// NOTE::: this is where you can add/remove stuff from the list of widgets to add
// it's easier than what was there before
export const WidgetsToHide = ['FileBrowser', 'Forms']
// export const WidgetsToHide = ['FileBrowser']
export const ListOfWidgets = [
    {
        name: 'Calendar',
        title: 'Calendar'
    },
    {
        name: 'Planner',
        title: 'Planner'
    },
    {
        name: 'Inbox',
        title: 'Inbox'
    },
    {
        name: 'FileBrowser',
        title: 'File Browser'
    },
    {
        name: 'SalesTracker',
        title: 'Sales Tracker'
    },
    {
        name: 'Forms',
        title: 'Forms'
    },
    {
        name: 'MyTodo',
        title: 'My Todo'
    }
]

export const dateTimeZoneMapping = {
    'AUS Eastern Standard Time': 'Australia/Sydney',
    default: 'Australia/Sydney'
}

export const SHARE_POINT_BASE_URL = 'https://codesourcecomau.sharepoint.com/'

export const taskStatusMap = {
    NotStarted: 'notStarted',
    InProgress: 'inProgress',
    Completed: 'completed',
    WaitingOnOthers: 'waitingOnOthers',
    Deferred: 'deferred'
}
export const taskStatusDropdown = [
    // The next 3 are the values from the Microsoft documentation
    { content: 'Not Started', title: 'Not Started', key: 'notStarted', value: 0 },
    { content: 'In Progress', title: 'In Progress', key: 'inProgress', value: 50 },
    { content: 'Completed', title: 'Completed', key: 'completed', value: 100 },
    // The below are random values I have entered
    { content: 'Waiting On Others', title: 'Waiting On Others', key: 'waitingOnOthers', value: 75 },
    { content: 'Deferred', title: 'Deferred', key: 'deferred', value: 10 }
]

// Below are the priorities for the planner task, we are using the default keys
// but I have added hte possible values incase we need something more specific,
// these are from the documentation here:
// https://learn.microsoft.com/en-us/graph/api/resources/plannertask?view=graph-rest-1.0
export const taskPriorityDropdown = [
    { content: 'Urgent', title: 'Urgent', key: 1, possibleValues: [0, 1] },
    { content: 'Low', title: 'Low', key: 9, possibleValues: [8, 9, 10] },
    { content: 'Medium', title: 'Medium', key: 5, possibleValues: [5, 6, 7] },
    { content: 'Important', title: 'Important', key: 3, possibleValues: [2, 3, 4] }
]

export const DEFAULT_BUCKET_NAME = 'All Buckets'

export const AllTaskObj = {
    id: 'all_buckets',
    key: 'all_buckets',
    name: 'All Buckets',
    content: 'All Buckets',
    parentId: null,
    alwaysShow: true
}

export const DEFAULT_TASK = {
    planId: '',
    bucketId: '',
    title: '',
    orderHint: '',
    assigneePriority: '',
    percentComplete: 0,
    startDateTime: null,
    createdDateTime: '',
    dueDateTime: null,
    hasDescription: false,
    previewType: '',
    completedDateTime: null,
    completedBy: null,
    referenceCount: 0,
    checklistItemCount: 0,
    activeChecklistItemCount: 0,
    conversationThreadId: null,
    priority: 5,
    id: '',
    createdBy: {
        user: {
            displayName: null,
            id: ''
        },
        application: {
            displayName: null,
            id: ''
        }
    },
    appliedCategories: {},
    assignments: {}
}

export const CALENDAR_GROUPS = {
    AllCalendars: 'ALL_CALENDARS',
    Events: 'EVENTS',
    MyCalendar: 'MY_CALENDAR'
}
