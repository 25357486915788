import React from 'react'
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import PropTypes from 'prop-types'
import { IoFolderOpen } from 'react-icons/io5'
import xlsx from '../../assets/icons/excel.svg'
import docx from '../../assets/icons/word.svg'
import pptx from '../../assets/icons/ppt.svg'
import pdf from '../../assets/icons/pdf.svg'
import undefinedFile from '../../assets/icons/undefined-file.svg'
import { goToUrl } from '../../common/link-helpers'

const FileList = ({ item, idx }) => {
    const handleFileClick = goToUrl

    const icons = {
        xlsx,
        docx,
        pptx,
        pdf
    }
    return (
        <ListItem key={`search-item-${idx}`} onClick={() => handleFileClick(item?.webUrl)}>
            <ListItemButton>
                <div className="flex items-start w-full">
                    <ListItemIcon className="w-6 h-6" style={{ minWidth: '40px' }}>
                        <img src={icons[item?.file?.typeFile] ?? undefinedFile} alt="file-logo" />
                    </ListItemIcon>
                    <div className="grid grid-cols-12 gap-2 w-full">
                        <div className="font-semibold col-span-3">
                            <div className="text-xs break-words whitespace-normal">
                                {item?.file?.fileName}
                            </div>
                        </div>
                        <div className="flex items-start content-center col-span-6">
                            <IoFolderOpen className="text-sm mr-1 mb-2 text-grey-2" />
                            <div className="text-xs">{item?.folderName}</div>
                        </div>
                        <div className="flex justify-end col-span-3 font-semibold text-grey-3">
                            <div className="text-xxs mr-3">{item?.creator}</div>
                            <div className="text-xxs">{item?.createdDate}</div>
                        </div>
                    </div>
                </div>
            </ListItemButton>
        </ListItem>
    )
}

FileList.propTypes = {
    item: PropTypes.instanceOf(Object).isRequired,
    idx: PropTypes.number.isRequired
}

export default FileList
