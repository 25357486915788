import React from 'react'

// eslint-disable-next-line react/prop-types
const Grid = ({ children, columns }) => (
    <div
        style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
            gridGap: 10,
            maxWidth: '800px',
            margin: '9px  auto'
        }}
    >
        {children}
    </div>
)

export default Grid
