import getMsGraphService from './base/ms-graph.service'

export class CompanyNewsService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    async getSites() {
        let message = ''
        let site = {}
        try {
            site = await this.msGraphService.callGetApi({
                url: '/sites',
                isBeta: true,
                selectStatement: 'id,name,displayName,webUrl'
            })
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, site }
    }

    async getSite(siteId) {
        let message = ''
        let site = {}
        try {
            site = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}`,
                isBeta: true,
                selectStatement: 'id,name,displayName'
            })
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, site }
    }

    async getSubSites(siteId) {
        let message = ''
        let sites = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/sites`,
                isBeta: true,
                selectStatement: 'id,name,displayName,webUrl'
            })
            sites = [...(response.value || [])]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return { message, sites }
    }

    // Using this function for now until we have a config that allows the admin to set the site
    async getSiteByKeyword(keyword) {
        let message = ''
        let sites = []
        try {
            const { value } = await this.msGraphService.callGetApi({
                url: '/sites',
                isBeta: true,
                search: keyword,
                selectStatement: 'id,name,description,displayName,webUrl'
            })
            sites = [...(value || [])]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, sites }
    }

    async getSiteLists(siteId) {
        let message = ''
        let siteList = []
        try {
            const { value } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists`,
                isBeta: true,
                selectStatement: 'id,system,displayName'
            })
            siteList = [...value]
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, siteList }
    }

    async getSiteListForCompanyNews(siteId) {
        let message = ''
        let siteLists = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists`
            })
            siteLists = response?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, siteLists }
    }

    async getSitePages(siteId) {
        let message = ''
        let sitePages = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/pages`,
                orderBy: 'lastModifiedDateTime DESC',
                isBeta: true,
                topValue: 5
            })
            sitePages = response?.value || []
            // filter out the ones that aren't articles
            sitePages = sitePages
                .filter(
                    /* eslint implicit-arrow-linebreak: "off" */
                    (sp) =>
                        sp.pageLayout === 'article' && sp?.publishingState?.level === 'published'
                )
                .map((v) => ({
                    ...v,
                    key: v.id,
                    content: v.title
                }))
            // now we have to get the webparts for the site pages, so let's just do it here
            /* eslint-disable no-restricted-syntax */
            for (const sitePage of sitePages) {
                /* eslint-disable no-await-in-loop */
                const { value: webParts } = await this.msGraphService.callGetApi({
                    url: `/sites/${siteId}/pages/${sitePage.id}/microsoft.graph.sitePage/webparts`,
                    isBeta: true
                })
                sitePage.firstWebPart = webParts?.at(0)
                sitePage.allWebParts = [...(webParts || [])]
            }
            // TODO: check if we want to only show the published articles
            // .filter(sp => sp.pageLayout === 'Article' &&
            // sp.publishingState?.level === 'published')
            // .slice(0, NUM_ARTICLES_DISPLAY)
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, sitePages }
    }

    async getAssetDrive(siteId) {
        let message = ''
        let drive = {}

        try {
            const { value: drives } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drives`,
                selectStatement: 'id,weburl,system',
                isBeta: true
            })
            if (!drives?.length) return drive

            // we assume there is only one drive at the moment
            // let's get the one with 'Assets' in the webUrl
            const filtered = drives.filter((d) => d.webUrl.indexOf('Assets') > -1)
            drive = { ...filtered[0] }
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { drive, message }
    }

    async getSitePage(siteId, sitePageId) {
        let message = ''
        let sitePage = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/pages/${sitePageId}`,
                isBeta: true
            })
            sitePage = response?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, sitePage }
    }

    async getSitePageWebParts(siteId, sitePageId) {
        let message = ''
        let webParts = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/pages/${sitePageId}/webparts`,
                isBeta: true
            })
            webParts = response?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, webParts }
    }

    async getThumbnails(siteId, driveId, sitePagePath, fileName = null, parentPath = null) {
        let thumbnail = null
        let message = null
        try {
            let driveItemList = []
            let uriPath = 'SitePages'
            // leaving parent Path incase we need it,
            // but with the drive item,s we shouldn't need it anymore
            if (parentPath) uriPath = `${parentPath}/${uriPath}`
            const { value: driveItems } = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/drives/${driveId}/root:/${uriPath}/${sitePagePath}:/children`
                // isBeta: true
            })

            driveItemList = [...(driveItems || [])]
            if (!driveItemList?.length) throw new Error('no drives found')

            // now we get the items
            // if we have a fulName, let's search for it, otherwise we get the last one one
            let idx = -1
            // if we don't find the filename then we still take the last image, which is expected
            if (fileName) idx = driveItemList.findIndex((d) => d.name.indexOf(fileName) > -1)
            thumbnail = driveItemList.at(idx)
            // filter out the ones that aren't articles
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, thumbnail }
    }

    async getSiteListItems(siteId, siteListId) {
        let message = ''
        let listItems = []
        try {
            const response = await this.msGraphService.callGetApi({
                url: `/sites/${siteId}/lists/${siteListId}/items?expand=fields`
            })
            listItems = response?.value || []
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }

        return { message, listItems }
    }
}
