import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RiFolderOpenLine } from 'react-icons/ri'
import { Dropdown, Menu, tabListBehavior } from '@fluentui/react-northstar'
import { CircularProgress } from '@mui/material'
import {
    selectSharePointSiteId,
    selectSharePointSite,
    updateFilter,
    callUpdateBusinessPartnerStatusProperties
} from '../../features/business-partners/businessPartnersSlice'
import FilesService from '../../services/files.service'
import FileThumbnailList from './FileThumbnailList'
import TileLayout from '../basic/TileLayout'
import '../../scss/fluent/ui-menu.scss'
import { BusinessPropertiesKey } from '../../common/constants'
import { PagePropertiesService } from '../../services/page-properties.service'

const BusinessPartners = () => {
    // const sharePointSite = useSelector(selectSharePointSite)
    const sharePointSiteId = useSelector(selectSharePointSiteId)
    const filesService = new FilesService()
    const pagePropertiesService = new PagePropertiesService()
    const [topLevelList, setTopLevelList] = useState([])
    const [topLevelListName, setTopLevelListName] = useState('')
    const [parentFolder, setParentFolder] = useState('')
    const [currentSubFolder, setCurrentSubFolder] = useState('')
    // const [currentSubFolderName, setCurrentSubFolderName] = useState('')
    const [tabFolders, setTabFolders] = useState([])
    const [currentTab, setCurrentTab] = useState({})
    const [filesList, setFilesList] = useState([])
    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const sharePointSite = useSelector(selectSharePointSite)
    const dispatch = useDispatch()

    const getChildFolders = async (_, evt) => {
        setLoading(true)
        await setTopLevelListName(evt.value?.name)
        await setCurrentSubFolder(evt.value?.id)
        dispatch(updateFilter({ value: evt?.value }))
        dispatch(
            callUpdateBusinessPartnerStatusProperties({
                selectedFilter: { value: evt?.value },
                sharePointSite
            })
        )
        // const currentFolderName = evt.value?.name
        // setCurrentSubFolderName(evt.value?.name)
        if (!parentFolder) return
        const { libraryFolders: subFolders } = await filesService.getLibraryFolders(
            sharePointSiteId,
            parentFolder,
            evt.value?.id
        )
        /* eslint no-console: "off" */
        console.log('sub folders we found', subFolders, currentSubFolder)
        await setTabFolders(subFolders)
        setLoading(false)
    }

    const menuSelectedHandler = async (test, evt) => {
        /* eslint no-console: "off" */
        console.log('menu option has changed', test, evt)
        const { activeIndex } = evt
        if (activeIndex < 0) return
        setCurrentTabIndex(activeIndex)
        await setCurrentTab(tabFolders[activeIndex])
        if (!parentFolder) return
        const { libraryFolders: files } = await filesService.getLibraryFolders(
            sharePointSiteId,
            parentFolder,
            tabFolders?.at(activeIndex)?.id
        )
        await setFilesList(files)
    }

    const hasFiles = () => currentTab?.folder?.childCount > 0

    const updateCurrentTabs = async () => {
        const activeIndex = 0
        await menuSelectedHandler(null, { activeIndex })
    }
    const setUpByUser = async () => {
        const fileFilter = await pagePropertiesService.getProperties(
            BusinessPropertiesKey,
            sharePointSite
        )
        if (fileFilter && Object.keys(fileFilter).length > 0) {
            await getChildFolders(null, fileFilter)
        } else {
            await getChildFolders(null, { value: topLevelList.at(0) })
        }
    }

    useEffect(() => {
        updateCurrentTabs().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [tabFolders])
    useEffect(() => {
        setUpByUser().catch((err) => {
            /* eslint no-console: "off" */
            console.error(err)
        })
    }, [parentFolder, topLevelList])

    useEffect(() => {
        const getFilesForSite = async () => {
            const { documentLibrary } = await filesService.getDocumentLibrary(sharePointSiteId)
            /* eslint no-console: "off" */
            console.log(
                'drives that we have found for the site that we have found',
                documentLibrary
            )
            const { parentReference, id: documentLibraryId } = documentLibrary
            // setDocumentLibraryFolder(documentLibraryId)
            setParentFolder(parentReference.driveId)

            const { libraryFolders } = await filesService.getLibraryFolders(
                sharePointSiteId,
                parentReference.driveId,
                documentLibraryId
            )
            /* eslint no-console: "off" */
            console.log('folders that we have found', libraryFolders)
            await setTopLevelList(libraryFolders)
            await setTopLevelListName(libraryFolders.at(0)?.name)
        }
        getFilesForSite().catch(console.error)
        setUpByUser().catch(console.error)
    }, [])

    return (
        <TileLayout
            title="File Browser"
            icon={<RiFolderOpenLine className="text-primary text-lg" />}
        >
            <div className="relative">
                <div className="flex items-center justify-between mb-4">
                    <Dropdown
                        className="w-150 widgetDropdown invertedDropdown"
                        inverted
                        items={topLevelList}
                        value={topLevelListName}
                        placeholder="Choose top level folder"
                        checkable
                        getA11ySelectionMessage={{
                            onAdd: (item) => `${item} has been selected.`
                        }}
                        onChange={getChildFolders}
                    />
                </div>
                <div className="flex items-start text-xxs uppercase  absolute top-10 z-10 left-0 right-0 bg-white">
                    <Menu
                        defaultActiveIndex={0}
                        activeIndex={currentTabIndex}
                        items={tabFolders}
                        underlined
                        primary
                        accessibility={tabListBehavior}
                        aria-label="Folders"
                        onActiveIndexChange={menuSelectedHandler}
                    />
                </div>
                {!loading && tabFolders.length && (
                    <div className="tile-height relative pt-12">
                        {hasFiles() === true && (
                            // <div className="p-2 grid grid-cols-12 gap-3">{thumbnailList}</div>
                            <FileThumbnailList fileList={filesList} />
                        )}
                        {hasFiles() === false && (
                            <div className="p-2 grid grid-cols-1 gap-3">No files found</div>
                        )}
                    </div>
                )}
                {!loading && !currentSubFolder && (
                    <div className="tile-height relative pt-10">
                        Select a folder from the dropdown
                    </div>
                )}
                {!loading && currentSubFolder && !tabFolders.length && (
                    <div className="tile-height relative pt-10">No folders found</div>
                )}
                {loading && (
                    <div className="flex flex-row justify-center pt-8">
                        <CircularProgress />
                    </div>
                )}
            </div>
        </TileLayout>
    )
}

export default BusinessPartners
