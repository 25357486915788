import {
    addPlannerTask,
    addPlannerTaskDetail,
    assignPeopleToPlannerTask,
    getAllMyPlannerPlans,
    getBucketsForPlannerPlan,
    getPlannerTaskDetails,
    getPlansForGroup,
    getSinglePlannerPlan,
    getTasksForPlannerBucket,
    removePlannerTask,
    setPlannerTaskComplete,
    setPlannerTaskIncomplete,
    setPlannerTaskDetails,
    getPlannerPlanParent
} from './planner-tasks.helpers'
import getMsGraphService from '../../services/base/ms-graph.service'

export class PlannerTaskSource {
    msGraphService = null

    graph: null

    constructor() {
        this.msGraphService = getMsGraphService()
        // Use an instance of BetaGraph since we know we need to call beta apis.
        // this.graph = BetaGraph.fromGraph(this.msGraphService.getGraphClient())
        this.graph = this.msGraphService.getGraphClient()
    }

    async getPlans() {
        const plans = await getAllMyPlannerPlans(this.graph)

        // return plans.map((plan) => ({ id: plan.id, title: plan.title }))
        return plans
    }

    async getTaskGroupsForGroup(id) {
        const plans = await getPlansForGroup(this.graph, id)

        return plans.map((plan) => ({ id: plan.id, title: plan.title }))
    }

    async getTaskGroup(id) {
        const plan = await getSinglePlannerPlan(this.graph, id)

        return { id: plan.id, title: plan.title, _raw: plan }
    }

    async getPlanParentBucket(planId) {
        const parentBucket = await getPlannerPlanParent(this.graph, planId)
        return {
            _raw: { ...parentBucket },
            id: parentBucket.id,
            name: parentBucket.name,
            parentId: parentBucket.planId
        }
    }

    async getTaskBuckets(id) {
        const buckets = await getBucketsForPlannerPlan(this.graph, id)

        return buckets.map((bucket) => ({
            _raw: bucket,
            id: bucket.id,
            name: bucket.name,
            parentId: bucket.planId
        }))
    }

    async getTasksForTaskFolder(id) {
        const tasks = await getTasksForPlannerBucket(this.graph, id)

        return tasks.map((task) => ({
            _raw: task,
            assignments: task.assignments,
            completed: task.percentComplete === 100,
            dueDate: task.dueDateTime && new Date(task.dueDateTime),
            eTag: task['@odata.etag'],
            id: task.id,
            immediateParentId: task.bucketId,
            name: task.title,
            topParentId: task.planId
        }))
    }

    async setTaskComplete(id, eTag) {
        return setPlannerTaskComplete(this.graph, id, eTag)
    }

    async setTaskIncomplete(id, eTag) {
        return setPlannerTaskIncomplete(this.graph, id, eTag)
    }

    async addTask(newTask) {
        return addPlannerTask(this.graph, {
            assignments: newTask?.assignments,
            bucketId: newTask.bucketId,
            planId: newTask?.planId,
            title: newTask.title
        })
    }

    async addTaskDetail(taskId, newTaskDetail, etag) {
        return addPlannerTaskDetail(this.graph, taskId, { ...newTaskDetail }, etag)
    }

    async assignPeopleToTask(id, eTag: string, people) {
        return assignPeopleToPlannerTask(this.graph, id, eTag, people)
    }

    async removeTask(id, eTag) {
        return removePlannerTask(this.graph, id, eTag)
    }

    /* eslint class-methods-use-this: "off" */
    isAssignedToMe(task, myId) {
        const keys = Object.keys(task.assignments)
        return keys.includes(myId)
    }

    async getTaskDetails(id) {
        return getPlannerTaskDetails(this.graph, id)
    }

    async updatePlannerTask(id, data, etag) {
        return setPlannerTaskDetails(this.graph, id, data, etag)
    }
}
