import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Datepicker, Dropdown, TextArea } from '@fluentui/react-northstar'
import { CircularProgress } from '@mui/material'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addDays, isBefore, startOfDay } from 'date-fns'
import { AllTaskObj, taskPriorityDropdown, taskStatusDropdown } from '../../common/constants'
import { PlannerTaskService } from '../../services/planner-task.service'
// import { customDateFormatter } from '../../common/date-helper.fns'

// const inputItems2 = ['All tasks', 'To do']
const taskService = new PlannerTaskService()

const PlannerTaskContent = ({
    task,
    buckets,
    onCancel,
    onSubmit,
    titleContent,
    planId,
    selectedBucket
}) => {
    const [filteredBuckets, setFilteredBuckets] = useState([])
    const [taskObj, setTaskObj] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    // const [taskObj, setTaskObj] = useState({ ...DEFAULT_TASK })

    const schema = yup.object({
        bucketId: yup.string().required('Bucket is required'),
        percentComplete: yup.string(),
        priority: yup.string(),
        startDate: yup.string(),
        endDate: yup.string(),
        description: yup.string()
    })

    const getTaskDetails = async () => {
        // if we are creating a new task, then we don't need to get the details
        if (!task.id) {
            setTaskObj({ ...task._raw, details: {} })
            return
        }
        const taskDetails = await taskService.getTaskDetails(task.id)
        setTaskObj({ ...task._raw, details: taskDetails })

        /* eslint no-console: "off" */
        console.log('========= task Object to show ', { ...task._raw, details: taskDetails })
    }

    const {
        handleSubmit,
        formState: { errors },
        reset,
        control,
        clearErrors,
        setValue,
        setError,
        watch
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            dueDateTime: addDays(new Date(), 1),
            startDateTime: new Date()
        }
    })
    const dueDate = watch('dueDateTime')
    const startDate = watch('startDateTime')
    const onSubmitForm = async (data) => {
        if (!planId) return
        setIsSaving(true)
        if (!task.id) {
            const { id: bucketId } = buckets.find((b) => b.name === data.bucketId)
            let percentComplete = 0
            if (data.percentComplete) {
                percentComplete = taskStatusDropdown.find(
                    (ts) => ts.title === data.percentComplete
                )?.value
            }
            // eslint-disable-next-line prettier/prettier
            const priority = taskPriorityDropdown.find((t) => t.content === data.priority)
            const updateTaskData = {
                percentComplete,
                dueDateTime: data.dueDateTime ? new Date(data.dueDateTime).toISOString() : null,
                startDateTime: new Date(data.startDateTime).toISOString(),
                priority: priority?.possibleValues[0]
            }
            const newTask = {
                planId,
                bucketId,
                title: String(titleContent).trim()
            }
            try {
                const savedTask = await taskService.addTask(newTask)
                if (savedTask && savedTask?.id) {
                    const taskDetails = {
                        description: data.description
                    }
                    const taskDetail = await taskService.getTaskDetails(savedTask.id)
                    const etag = taskDetail['@odata.etag']
                    await taskService.addTaskDetail(savedTask.id, taskDetails, etag)
                    await taskService.updatePlannerTask(
                        savedTask.id,
                        updateTaskData,
                        savedTask['@odata.etag']
                    )
                }
                onSubmit({ message: 'Create Task Successfully.', type: 'done' })
            } catch (error) {
                onSubmit({ message: 'Create Task fail.', type: 'error' })
                /* eslint no-console: "off" */
                console.log('========= Saving Task Error', error)
            } finally {
                setIsSaving(false)
            }
        } else {
            const { id: bucketId } = buckets.find((b) => b.name === data.bucketId)
            let percentComplete = 0
            if (data.percentComplete) {
                percentComplete = taskStatusDropdown.find(
                    (ts) => ts.title === data.percentComplete
                )?.value
            }
            const priority = taskPriorityDropdown.find((t) => t.content === data.priority)
            const updateData = {
                bucketId,
                percentComplete,
                dueDateTime: new Date(data.dueDateTime).toISOString(),
                startDateTime: new Date(data.startDateTime).toISOString(),
                priority: priority?.possibleValues[0]
            }
            try {
                if (data.description !== taskObj?.details?.description) {
                    const updateDetailData = {
                        description: data.description
                    }
                    const saveTaskDetail = await taskService.addTaskDetail(
                        taskObj.id,
                        updateDetailData,
                        taskObj.details['@odata.etag']
                    )
                    /* eslint no-console: "off" */
                    console.log('saveTaskDetail:', saveTaskDetail)
                }
                await taskService.updatePlannerTask(taskObj.id, updateData, taskObj['@odata.etag'])
                onSubmit({ message: 'Update Task Successfully.', type: 'done' })
            } catch (error) {
                onSubmit({ message: 'Update Task fail.', type: 'error' })
                /* eslint no-console: "off" */
                console.log('========= Saving Update Task Error', error)
            } finally {
                setIsSaving(false)
            }
            onSubmit(data)
        }
    }
    useEffect(
        () => () => {
            reset()
            clearErrors()
        },
        []
    )

    useEffect(() => {
        if (!taskObj) return
        /* eslint no-underscore-dangle: "off" */
        const taskStatus =
            taskStatusDropdown.find((t) => t.value === taskObj.percentComplete)?.title || ''
        const bucketName = buckets.find((bucket) => bucket.id === taskObj.bucketId)?.name || ''
        // eslint-disable-next-line prettier/prettier
        const priorityValue =
            taskPriorityDropdown.find((t) => t.possibleValues.indexOf(taskObj.priority) > -1)
                ?.title || ''
        setValue('percentComplete', taskStatus)
        const endTime = taskObj?.dueDateTime
        if (endTime) {
            setValue('dueDateTime', endTime)
        }
        const startTime = taskObj?.startDateTime
        if (startTime) {
            setValue('startDateTime', startTime)
        }
        if (!task?.id) {
            const bucketValue = selectedBucket === 'All Buckets' ? '' : selectedBucket
            setValue('bucketId', bucketValue)
        } else {
            setValue('bucketId', bucketName)
        }
        setValue('priority', priorityValue)
        setValue('description', taskObj?.details?.description)
    }, [taskObj])

    useEffect(() => {
        getTaskDetails()
    }, [task])

    useEffect(() => {
        const foundBuckets = buckets.filter((bucket) => bucket.id !== AllTaskObj.id)
        setFilteredBuckets([...foundBuckets])
    }, [buckets])

    useEffect(() => {
        const isDueDateBefore = isBefore(
            startOfDay(new Date(dueDate)),
            startOfDay(new Date(startDate))
        )

        if (dueDate && startDate && isDueDateBefore) {
            setError('dueDateTime', {
                message: 'Due date must be the same or greater than the start date.'
            })
        } else {
            clearErrors('dueDateTime')
        }
    }, [dueDate, startDate])

    const buttons = (
        <div className="flex flex-row justify-end mt-2">
            <Button className="mr-2" type="button" flat onClick={onCancel}>
                Cancel
            </Button>
            <Button
                className=""
                type="button"
                primary
                flat
                disabled={isSaving}
                onClick={errors && Object.keys(errors).length ? null : handleSubmit(onSubmitForm)}
            >
                {isSaving && (
                    <div className="flex flex-row justify-between align-middle">
                        <CircularProgress size="15" color="secondary" />
                        <div className="ml-2">Please Wait...</div>
                    </div>
                )}
                {!isSaving && (!task.id ? 'Save' : 'Update')}
            </Button>
        </div>
    )

    return (
        <div className="mt-4">
            <form className="flex flex-col" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="grid grid-cols-3 gap-2">
                    <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Bucket</div>
                        <Controller
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error }
                            }) => (
                                <Dropdown
                                    className={`widgetDropdown ${value ? 'text-color' : ''}`}
                                    placeholder="Select bucket"
                                    checkable
                                    fluid
                                    items={filteredBuckets}
                                    onChange={(_e, option) => {
                                        onChange(option.value.name)
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="bucketId"
                        />
                        {errors.bucketId && <p className="text-rose-600">Bucket is required</p>}
                    </div>
                    <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Progress</div>
                        <Controller
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Dropdown
                                    className={`widgetDropdown ${value ? 'text-color' : ''}`}
                                    placeholder="Select status"
                                    checkable
                                    fluid
                                    items={taskStatusDropdown}
                                    value={value}
                                    onChange={(_, option) => {
                                        onChange(option.value.title)
                                    }}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="percentComplete"
                        />
                    </div>
                    <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Priority</div>
                        <Controller
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error }
                            }) => (
                                <Dropdown
                                    className={`widgetDropdown ${value ? 'text-color' : ''}`}
                                    placeholder="Select priority"
                                    checkable
                                    fluid
                                    items={taskPriorityDropdown}
                                    value={value}
                                    onChange={(_, option) => {
                                        onChange(option.value.title)
                                    }}
                                    onBlur={onBlur}
                                    selectedkey={value}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="priority"
                        />
                    </div>

                    <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Start date</div>
                        <Controller
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error }
                            }) => (
                                <Datepicker
                                    className={`${value ? 'text-date-color' : ''}`}
                                    onDateChange={(_, option) => {
                                        onChange(option.value)
                                    }}
                                    selectedDate={new Date(value)}
                                    onBlur={onBlur}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="startDateTime"
                        />
                    </div>
                    <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Due date</div>
                        <Controller
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error }
                            }) => (
                                <Datepicker
                                    className={`${value ? 'text-date-color' : ''}`}
                                    onDateChange={(_, option) => {
                                        onChange(option.value)
                                    }}
                                    selectedDate={new Date(value)}
                                    onBlur={onBlur}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="dueDateTime"
                        />
                        {errors?.dueDateTime && (
                            <p className="text-rose-600">{errors?.dueDateTime.message}</p>
                        )}
                    </div>
                    {/* <div>
                        <div className="text-xxs uppercase font-bold text-grey-3">Repeat</div>
                        <Dropdown
                            className="widgetDropdown"
                            items={inputItems2}
                            placeholder="No repeat"
                            checkable
                            fluid
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`
                            }}
                        />
                    </div> */}
                    <div className="col-span-3">
                        <div className="text-xxs uppercase font-bold text-grey-3">Notes</div>
                        <Controller
                            rules={{ required: true }}
                            render={({
                                field: { onChange, onBlur, value },
                                fieldState: { error }
                            }) => (
                                <TextArea
                                    className={`${value ? 'text-date-color' : ''}`}
                                    placeholder="Type a description or add notes here"
                                    fluid
                                    value={value}
                                    onChange={(_, option) => {
                                        onChange(option.value)
                                    }}
                                    onBlur={onBlur}
                                    errorMessage={error && error.message}
                                />
                            )}
                            control={control}
                            name="description"
                        />
                    </div>
                </div>
                {buttons}
            </form>
        </div>
    )
}

PlannerTaskContent.propTypes = {
    titleContent: PropTypes.string.isRequired,
    task: PropTypes.instanceOf(Object).isRequired,
    buckets: PropTypes.instanceOf(Array).isRequired,
    onCancel: PropTypes.instanceOf(Function).isRequired,
    onSubmit: PropTypes.instanceOf(Function).isRequired,
    planId: PropTypes.string.isRequired,
    selectedBucket: PropTypes.string.isRequired
}

export default PlannerTaskContent
