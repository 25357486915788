import getMsGraphService from './base/ms-graph.service'

class SearchService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    /**
     * entityTypes query overwrites the default types sent to MS Graph
     * this is so we can call the search api with the correct combinations that are allowed
     * see the known limitations as to why we need to do this: https://docs.microsoft.com/en-us/graph/api/resources/search-api-overview?view=graph-rest-1.0#known-limitations
     *
     * @param queryText
     * @param entityTypes
     * @returns {Promise<*>}
     * @private
     */
    async doSearch(queryText, entityTypes = []) {
        let entityTypesQuery = ['driveItem']
        if (entityTypes?.length) entityTypesQuery = [...entityTypes]

        try {
            // we can add more search terms later if we want
            const payload = {
                requests: [
                    {
                        entityTypes: entityTypesQuery,
                        query: {
                            queryString: queryText
                        }
                    }
                ]
            }
            const resp = await this.msGraphService.callPostApi('/search/query', payload, null, true)
            const { hitsContainers } = resp?.value?.at(0) || {}
            // for now, let's take the first hit - the search returns a list of hits.
            // return the resource, so we can use the 'id'
            return hitsContainers
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            throw e
        }
    }

    /**
     * function to search the graph for an item based on any query text.
     * this searches Calendars, DriveItems, Files, Mail and sites
     *
     * @param queryText
     * @param entityTypes - entities to search for - we default to just the driveItems (as in files)
     * @returns {Promise<{message: string, items: *[]}>}
     */
    async searchForItem(queryText, entityTypes = []) {
        let message = ''
        let item = {}

        try {
            const hitsContainers = await this.doSearch(queryText, entityTypes)
            // for now, let's take the first hit - the search returns a list of hits - and return
            // the resource so we can use the 'id'
            if (hitsContainers?.length) item = hitsContainers[0]?.hits[0]?.resource
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return {
            message,
            item
        }
    }

    /**
     * function to search the graph for an item based on any query text.
     * this searches Calendars, DriveItems, Files, Mail and sites
     *
     * @param queryText
     * @param entityTypes - entities to search for - we default to just the driveItems (as in files)
     * @returns {Promise<{message: string, items: *[]}>}
     */
    async search(queryText, entityTypes = []) {
        let message = ''
        let items = []

        try {
            const hitsContainers = await this.doSearch(queryText, entityTypes)
            // for now, let's take the first hit - the search returns a list of hits.
            // and return the resource, so we can use the 'id'
            if (hitsContainers?.length) {
                items = hitsContainers[0]?.hits?.map((h) => h.resource) || []
            }
        } catch (e) {
            /* eslint no-console: "off" */
            console.log('error ', e)
            message = e.message
        }
        return {
            message,
            items
        }
    }
}

export default SearchService
