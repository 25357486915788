import { toDate } from 'date-fns-tz'
import { addDays, format } from 'date-fns'
import getMsGraphService from './base/ms-graph.service'
import { CALENDAR_GROUPS } from '../common/constants'

class CalendarService {
    msGraphService = null

    constructor() {
        this.msGraphService = getMsGraphService()
    }

    /**
     * entityTypes query overwrites the default types sent to MS Graph
     * this is so we can call the search api with the correct combinations that are allowed
     * see the known limitations as to why we need to do this: https://docs.microsoft.com/en-us/graph/api/resources/search-api-overview?view=graph-rest-1.0#known-limitations
     *
     * @returns {Promise<*>}
     * @private
     */
    async getCalendar(timeFilter) {
        const calendar = []
        let message = ''
        try {
            const date = new Date(timeFilter)
            const start = format(
                addDays(new Date(date.getFullYear(), date.getMonth(), 1), -12),
                'yyyy-MM-dd'
            )
            const end = format(
                addDays(new Date(date.getFullYear(), date.getMonth() + 1), +12),
                'yyyy-MM-dd'
            )
            const resp = await this.msGraphService.callGetApi({
                url: '/me/calendarview',
                query: `StartDateTime=${start}&EndDateTime=${end}`,
                isBeta: true,
                topValue: 1000
            })

            resp?.value.forEach((evt) => {
                const startDateTime = toDate(evt?.start?.dateTime, {
                    timeZone: evt?.start?.timeZone
                })
                const endDateTime = toDate(evt?.end?.dateTime, { timeZone: evt?.end?.timeZone })
                calendar.push({
                    id: evt?.id,
                    start: startDateTime,
                    startTimeZone: evt?.originalStartTimeZone,
                    end: endDateTime,
                    endTimeZone: evt?.originalEndTimeZone,
                    title: evt?.subject,
                    allDay: evt?.isAllDay,
                    importance: evt?.importance,
                    location: evt?.location?.displayName || '',
                    isOnlineMeeting: evt?.isOnlineMeeting,
                    onlineMeeting: evt?.onlineMeeting,
                    onlineMeetingUrl: evt?.onlineMeetingUrl,
                    group: CALENDAR_GROUPS.MyCalendar
                })
            })
        } catch (e) {
            message = e.message
        }
        return {
            calendar,
            message
        }
    }
}

export default CalendarService
