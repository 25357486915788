import { configureStore } from '@reduxjs/toolkit'
import globalSearchReducer from '../features/global-search/globalSearchSlice'
import businessPartnersReducer from '../features/business-partners/businessPartnersSlice'
import tabLayoutReducer from '../features/tab-layout/tabLayoutSlice'
import logoBannerReducer from '../features/logo-banner/logoBannerSlice'
import calendarReducer from '../features/calendar/calendarSlice'
import plannerSliceReducer from '../features/planner/plannerSlice'
import mailSliceReducer from '../features/user-inbox/mailSlice'
import subLogoSliceReducer from '../features/sub-logo/subLogo.slice'
import groupsSliceReducer from '../features/groups/groupsSlice'

export const store = configureStore({
    reducer: {
        counter: globalSearchReducer,
        businessPartners: businessPartnersReducer,
        tabLayout: tabLayoutReducer,
        logoBanner: logoBannerReducer,
        calendar: calendarReducer,
        planner: plannerSliceReducer,
        mail: mailSliceReducer,
        subLogo: subLogoSliceReducer,
        groups: groupsSliceReducer
    }
})
