import { RiInformationLine } from 'react-icons/ri'

const OurCompany = () => (
    <div className="block">
        <div className="block">
            <div className="w-full p-4 bg-primary">
                <div className="w-full grid grid-flow-row auto-cols-max grid-cols-12 gap-4">
                    <div className="bg-white py-2 px-3 rounded col-span-6">
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center">
                                <RiInformationLine className="text-secondary text-lg" />
                                <div className="ml-2 text-xs font-bold uppercase">
                                    Davidson Group
                                </div>
                            </div>
                        </div>
                        <div className="text-secondary text-sm font-bold">Who we are</div>
                        <div className="text-xs">
                            <p className="mb-2">
                                At Davidson every action, big or small, is directed to enhancing
                                workplaces, supporting careers, and making a positive difference to
                                the lives of our clients, candidates and the broader community.
                            </p>
                            <p className="mb-2">
                                Over 30 years, we have evolved to be recognised as a proudly
                                Australian company leading in enhancing workplace performance.
                            </p>
                            <p className="mb-2">
                                With a national presence, we possess the scale and capability to
                                enhance your workplace performance. We combine this with strong
                                local knowledge, and a partnership based approach to building
                                sustainable value.
                            </p>
                            <p className="mb-2">
                                Our team includes a broad range of specialists in business advisory,
                                search and recruitment, and technology consulting.
                            </p>
                            <p className="mb-2">
                                Many of our clients have worked with us for decades. We put that
                                down to living our values which embrace thinking 30 years not 30
                                days; giving the same advice as you would give to a close friend or
                                relative, and going above and beyond to ensure we deliver for our
                                clients.
                            </p>
                            <p className="mb-2">
                                You can download a V23 One Pager of who we are and here is another
                                document explaining Davidson&apos;s values (all are also described
                                below.)
                            </p>
                        </div>
                    </div>
                    <div className="bg-white py-2 px-3 rounded col-span-6">
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center">
                                <RiInformationLine className="text-secondary text-lg" />
                                <div className="ml-2 text-xs font-bold uppercase">
                                    First Nations Friendship
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white py-2 px-3 rounded col-span-6">
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center">
                                <RiInformationLine className="text-secondary text-lg" />
                                <div className="ml-2 text-xs font-bold uppercase">Our Offices</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default OurCompany
