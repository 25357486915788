import React, { useEffect, useState } from 'react'
import { ListItem, CircularProgress } from '@mui/material'
import format from 'date-fns/format'
import { parseISO, addDays } from 'date-fns'
import PropTypes from 'prop-types'
import EmailService from '../../services/email.service'
import { Inbox } from './Inbox'
import useContextMenu from '../../hooks/useContextMenu'
import { isInThisWeek, compareDate } from '../../common/date-helper.fns'

const EmailList = ({ filter, folderId, inferenceClassification }) => {
    const emailService = new EmailService()
    const [value, setValue] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [readMore, setReadMore] = useState(false)
    const [page, setPage] = useState(1)
    const [flat, setFlat] = useState(false)
    const activeFlat = () => setFlat(!flat)
    const { clicked, setClicked, points, setPoints } = useContextMenu()
    let displayingText = ''

    const handleLoadMore = (isReadMore) => {
        setReadMore(isReadMore)
    }

    const changeData = (data) => {
        if (data && Object.keys(data).length) {
            setValue(
                value.map(
                    // eslint-disable-next-line prettier/prettier, no-confusing-arrow
                    (item) =>
                        // eslint-disable-next-line prettier/prettier, implicit-arrow-linebreak
                        item.id === data.id ? { ...item, isRead: data.isRead } : item
                    // eslint-disable-next-line prettier/prettier, function-paren-newline
                )
            )
        }
        activeFlat()
    }

    const handleScroll = (e) => {
        const bottom =
            Math.floor(e.target.scrollHeight - e.target.scrollTop - 3) <= e.target.clientHeight
        if (bottom && page * 10 < value.length) {
            handleLoadMore(true)
            setTimeout(() => {
                setPage((previousValue) => previousValue + 1)
                handleLoadMore(false)
            }, 1000 * 3)
        }
    }

    const typeDisplay = (item) => {
        if (compareDate(item?.receiveTime, Date.now())) return 'Today'
        if (compareDate(item?.receiveTime, addDays(new Date(), -1))) return 'Yesterday'
        if (isInThisWeek(item?.receiveTime)) return 'This Week'
        return 'Older'
    }

    useEffect(() => {
        setTimeout(async () => {
            try {
                setLoading(false)
                const { messages } = await emailService.getUserEmails(
                    filter,
                    folderId,
                    inferenceClassification
                )
                setPage(1)
                if (messages != null) {
                    setValue([...messages])
                }
            } catch (error) {
                /* eslint no-console: "off" */
                console.log(error)
            } finally {
                setLoading(true)
            }
        }, 1000)
    }, [filter, flat, folderId, inferenceClassification])

    useEffect(() => {
        const interval = setInterval(async () => {
            const { messages } = await emailService.getUserEmails(
                filter,
                folderId,
                inferenceClassification
            )
            if (messages != null) {
                setValue([...messages])
            }
        }, 10 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [value])

    // const removeHandler = (idx) => {
    //     // TODO: maybe show an error here
    //     if (idx === undefined || idx === -1) return
    //     // I have separated this one because if this is true, something is very wrong
    //     if (idx >= value.length) return
    //     const mailList = [...value.slice(0, idx), ...value.slice(idx + 1)]
    //     setValue([...mailList])
    // }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {isLoading ? (
                <div onScroll={handleScroll} className="tile-height-430">
                    {value?.slice(0, 10 * page).map((item, index) => {
                        // const convertTime = new Date(item.receiveTime)
                        const time = compareDate(item?.receiveTime, Date.now())
                            ? format(parseISO(item.receiveTime), 'HH:mm')
                            : format(parseISO(item.receiveTime), 'dd/MM/yyyy')
                        let show = false
                        const text = typeDisplay(item)
                        if (text !== displayingText) {
                            show = true
                            displayingText = text
                        }
                        const commonProps = { ...item, time }
                        return (
                            <>
                                {show && (
                                    <div className="border-b border-grey-4 font-bold text-left text-primary min-h-20 pb-2 mb-3 w-full">
                                        {text}
                                    </div>
                                )}
                                <div
                                    className="border-b border-grey-4 pb-3 mb-3 cursor-pointer w-full"
                                    key={`d-${index}`}
                                >
                                    <Inbox
                                        key={`inbox-${index}`}
                                        data={commonProps}
                                        setValue={setValue}
                                        value={value}
                                        changeData={changeData}
                                        clicked={clicked}
                                        setClicked={setClicked}
                                        points={points}
                                        setPoints={setPoints}
                                    />
                                </div>
                            </>
                        )
                    })}
                    {readMore && (
                        <div className="w-full flex justify-center ">
                            <CircularProgress size={12} />
                        </div>
                    )}
                </div>
            ) : (
                <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </ListItem>
            )}
        </>
    )
}

EmailList.propTypes = {
    filter: PropTypes.string.isRequired,
    folderId: PropTypes.string.isRequired,
    inferenceClassification: PropTypes.string
}

EmailList.defaultProps = {
    inferenceClassification: null
}

export default EmailList
