/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import PropTypes from 'prop-types'
import { CSS } from '@dnd-kit/utilities'
import tabRoles from '../common/json/role-for-edit-tabs.json'

const CustomTab = ({ tab, isInEditMode, isMovedTab }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: tab?.id
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined
    }
    const role = tabRoles.find(
        (e) => e.title === tab?.title || String(tab?.title).includes(e?.title)
    )
    return (
        <>
            {isInEditMode && isMovedTab && role?.canMove
                ? tab.children(setNodeRef, attributes, listeners, style)
                : tab.children()}
        </>
    )
}

CustomTab.propTypes = {
    tab: PropTypes.instanceOf(Object),
    isInEditMode: PropTypes.bool.isRequired,
    isMovedTab: PropTypes.bool.isRequired
}
CustomTab.defaultProps = {
    tab: null
}

export default CustomTab
