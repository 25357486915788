import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PagePropertiesService } from '../../services/page-properties.service'
import { MailPropertiesKey } from '../../common/constants'

const pagePropertiesService = new PagePropertiesService()
const initialState = {
    archiveFolder: {},
    selectedMail: {},
    selectedFilter: {
        folderName: '',
        status: '',
        folderId: ''
    }
}

export const callUpdateMailStatusProperties = createAsyncThunk(
    'MailStatus/callUpdateMailStatusProperties',
    /* eslint no-unused-vars: "off" */
    async ({ selectedFilter, sharePointSite }, thunkAPI) => {
        const response = await pagePropertiesService.setProperties(
            MailPropertiesKey,
            selectedFilter,
            sharePointSite
        )
        return response
    }
)

export const MailSlice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        updateArchiveFolder: (state, action) => {
            state.archiveFolder = action.payload.archiveFolder
        },
        updateSelectedMail: (state, action) => {
            state.selectedMail = action.payload.selectedMail
        },
        updateSelectedFilter: (state, action) => {
            state.selectedFilter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(callUpdateMailStatusProperties.fulfilled, (state, action) => {
            /* eslint no-console: "off" */
            console.log('============= finished updating Mail Status Properties ', state, action)
        })
    }
})

export const { updateArchiveFolder, updateSelectedMail, updateSelectedFilter } = MailSlice.actions

export const getArchiveFolder = (state) => state.mail.archiveFolder
export const getSelectedMail = (state) => state.mail.selectedMail
export const getSelectedMailStatus = (state) => state.mail.selectedFilter

export default MailSlice.reducer
