import 'react-app-polyfill/ie11' // For IE compatibility
import 'react-app-polyfill/stable' // For IE compatibility
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { Provider, teamsTheme } from '@fluentui/react-northstar'
// import { Provider as ReduxProvider } from 'react-redux'
import { Provider as ReduxProvider } from 'react-redux'
import App from './components/App'
import './index.css'
import './scss/main.scss'
import reportWebVitals from './reportWebVitals'
import { store } from './store'
// import { store } from './app/store'

Sentry.init({
    dsn: 'https://06cdc5869df94243b1a0815ba98ffc26@o289574.ingest.sentry.io/4504965511970816',
    integrations: [
        new CaptureConsoleIntegration({
            // array of methods that should be captured
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ['log', 'error', 'debug']
        }),
        new Sentry.BrowserTracing()
        // new Sentry.Replay({
        //     // Additional SDK configuration goes in here, for example:
        //     maskAllText: true,
        //     blockAllMedia: true
        // })
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV || 'production'
})

ReactDOM.render(
    <React.StrictMode>
        <Provider theme={teamsTheme}>
            <ReduxProvider store={store}>
                {/* <ReduxProvider store={store}> */}
                <App />
                {/* </ReduxProvider> */}
            </ReduxProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
