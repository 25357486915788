import getMsGraphService from './base/ms-graph.service'
import { PlannerTaskSource } from '../plugins/microsoft/planner-task.source'

export class PlannerTaskService extends PlannerTaskSource {
    msGraphService = null

    constructor() {
        super(getMsGraphService()?.getGraphClient())
    }
}
