import { useEffect, useState } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { Button } from '@fluentui/react-northstar'
import { useDispatch, useSelector } from 'react-redux'
import {
    callUpdateProperties,
    getTabLayout,
    getOrderTabs,
    updateOrderTabs,
    callUpdateOrderTabsProperties,
    updateTabLayout
} from '../features/tab-layout/tabLayoutSlice'
import { ListOfWidgets } from '../common/constants'
import {
    selectSharePointSite,
    selectAssetDrive
} from '../features/business-partners/businessPartnersSlice'
import { orderTabs } from '../common/orderTabs'
import '../scss/components/ResetWidgets.scss'

const AddWidget = () => {
    const [widgetList, setWidgetList] = useState([])
    const dispatch = useDispatch()
    const tabLayout = useSelector(getTabLayout)
    const tabs = useSelector(getOrderTabs)
    const sharePointSite = useSelector(selectSharePointSite)
    const assetDriveSite = useSelector(selectAssetDrive)
    const [hidden, setHidden] = useState('hidden')
    const defaultTabs = orderTabs.filter((tab) => tab.showOnDefault)

    const updateAllLayouts = (initialOrderTabs) => {
        const tempTabLayout = { ...(tabLayout || {}) }
        const keys = Object.keys(tempTabLayout)
        if (!keys.length) return
        keys.forEach((key) => {
            const foundTab = initialOrderTabs.find((io) => io.title === key)
            if (foundTab) tempTabLayout[key] = true
            else tempTabLayout[key] = false
        })
        dispatch(updateTabLayout(tempTabLayout))
        dispatch(callUpdateProperties({ sharePointSite }))
    }

    const resetClickHandler = () => {
        const initialOrderTabs = defaultTabs.map((tab) => ({
            id: tab.id,
            title: tab.title
        }))
        updateAllLayouts(initialOrderTabs)

        dispatch(callUpdateOrderTabsProperties({ orderTabs: initialOrderTabs, assetDriveSite }))
        dispatch(updateOrderTabs(initialOrderTabs))
    }

    setTimeout(() => {
        setHidden('hidden')
    }, 3000)

    useEffect(() => {
        const widgets = ListOfWidgets.filter((widget) => {
            let showWidget = true
            const layoutDisplay = tabLayout?.[widget.name]
            if (layoutDisplay === true) showWidget = false
            return showWidget
        })
        setWidgetList(widgets)
    }, [tabLayout, tabs])

    return (
        <div>
            <Button type="button" primary flat className="mr-2" onClick={resetClickHandler}>
                <FiRefreshCcw className="mr-1" />
                <span className="uppercase font-semibold text-xs">Reset widgets</span>
            </Button>
            <div className={`popup ${hidden}`}>
                {!widgetList?.length && <div>All widgets have been added...</div>}
            </div>
        </div>
    )
}

export default AddWidget
