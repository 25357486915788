import React from 'react'
import PropTypes from 'prop-types'
import CustomMeeting from './CustomMeeting'
import { convertUTCDateToLocalDate } from '../../common/date-helper.fns'

const Meeting = ({ dataContext }) => {
    // eslint-disable-next-line react/prop-types
    const events = dataContext?.events
    const formatDate = (date) => {
        const timePart = convertUTCDateToLocalDate(new Date(date), 'HH:mm')
        return timePart
    }
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {events?.map((event) => {
                const meetingUrl = event?.onlineMeetingUrl || event?.onlineMeeting?.joinUrl || null
                return (
                    <CustomMeeting
                        subject={event.subject}
                        location={event.location.displayName}
                        startTime={
                            event?.start?.dateTime ? formatDate(event?.start?.dateTime) : null
                        }
                        endTime={event?.end?.dateTime ? formatDate(event?.end?.dateTime) : null}
                        organizer={event?.organizer?.emailAddress?.address}
                        attendees={event?.attendees}
                        meetingUrl={meetingUrl}
                    />
                )
            })}
        </>
    )
}

Meeting.propTypes = {
    dataContext: PropTypes.instanceOf(Object)
}
Meeting.defaultProps = {
    dataContext: null
}

export default Meeting
