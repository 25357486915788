import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { selectSubSites } from '../../features/business-partners/businessPartnersSlice'
import TileLayout from '../basic/TileLayout'
import '../../scss/components/SlickSlide.scss'
import SliderWrapper from '../shared/SliderWrapper'
import ValueItem from './ValueItem'
import { useSharepointSitePages } from '../../hooks/useSharepointSitePages'

const Values = () => {
    // const sharePointSite = useSelector(selectSharePointSite)
    const subSites = useSelector(selectSubSites)
    const [subSiteId, setSubSiteId] = useState('')
    const { companyNews, loading, setLoading, getLatestNews } = useSharepointSitePages()

    useEffect(() => {
        if (!subSites.length) return
        const subSite = subSites.find((site) => site.name?.toLowerCase().indexOf('values') > -1)
        if (!subSite) return
        setSubSiteId(subSite.id)
        setLoading(true)
        getLatestNews(subSite.id)
    }, [subSites])

    return (
        <TileLayout>
            <div className="noSlickDots valuesSlider">
                {loading ? (
                    <div className="flex flex-row justify-center pt-8">
                        <CircularProgress />
                    </div>
                ) : (
                    <SliderWrapper
                        items={companyNews}
                        Child={ValueItem}
                        sharePointSiteId={subSiteId}
                        fade
                        infinite
                    />
                )}
            </div>
        </TileLayout>
    )
}

export default Values
